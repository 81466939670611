{
  "accounting.account_balance": "Баланс",
  "accounting.accounting_dashboard": "Дешборд обліку",
  "accounting.dashboard_preview_description": "Це попередній перегляд функціоналу «Дешборд обліку». Ми імпортуємо облікові дані з Catacloud і показуємо вам зведення найважливішої інформації безпосередньо в Hoopit!",
  "accounting.expense": "Витрати",
  "accounting.imported_from_catacloud": "Імпортовано з Catacloud",
  "accounting.income": "Дохід",
  "accounting.open_invoices": "Відкриті рахунки-фактури",
  "accounting.page_description_1": "Чи знаєте ви, що можна інтегрувати Hoopit із системою обліку Catacloud?",
  "accounting.page_description_2": "Разом з ECIT і Catacloud ми забезпечимо автоматичний облік доходів і витрат, а також повний перегляд ваших облікових даних безпосередньо в Hoopit.",
  "accounting.request_more_information": "Взнати більше",
  "accounting.result": "Результат",
  "accounting.results": "Результати",
  "accounting.this_year": "поточний рік",
  "accounting.unpaid_bills": "Неоплаченні рахунки",
  "action.accept": "Прийняти",
  "action.activate": "Активувати",
  "action.add": "Додати",
  "action.add_comment": "Додати коментар",
  "action.add_note": "Додати замітку",
  "action.apply": "Застосувати",
  "action.apply_changes": "Застосувати зміни",
  "action.approve": "Схвалити",
  "action.back": "Назад",
  "action.back_to_login": "Назад до входу",
  "action.cancel": "Скасувати",
  "action.change": "Змінити",
  "action.choose_all": "Вибрати всіх",
  "action.clear": "Очистити",
  "action.click_here": "Натисніть тут",
  "action.click_to_view": "Нажміть, щоб подивитись",
  "action.close": "Закрити",
  "action.complete": "Завершити",
  "action.confirm": "Підтвердити",
  "action.continue": "Продовжити",
  "action.copy": "Копіювати",
  "action.copy_link": "За посиланням",
  "action.create": "Створити",
  "action.delete": "Видалити",
  "action.delete_all": "Видалити все",
  "action.download": "Завантажити",
  "action.edit": "Редагувати",
  "action.edit_comment": "Редагувати коментар",
  "action.embed_with_iframe": "Вбудувати за допомогою iframe",
  "action.enroll": "Зарахувати",
  "action.event_cancel": "Скасувати",
  "action.export": "Експорт",
  "action.finalize": "Доопрацювати",
  "action.go_to_details": "Перейти до деталей",
  "action.ical_ics_link": "iCAL/iCS посилання",
  "action.invite": "Запросити",
  "action.invoice": "Згенерувати рахунок",
  "action.join_waiting_line": "Приєднати до черги очікування",
  "action.load_more": "Завантажити ще",
  "action.login": "Авторизуватися",
  "action.logout": "Вийти",
  "action.mark_as_refunded": "Позначити як відшкодований",
  "action.move_to_group": "Перемістити користувачів до групи",
  "action.next": "Далі",
  "action.no_go_back": "Ні, повернутися",
  "action.ok": "Ок",
  "action.publish": "Опублікувати",
  "action.refund": "Відшкодувати",
  "action.register": "Зареєструвати",
  "action.reject": "Відхилити",
  "action.remind": "Нагадати",
  "action.remove": "Видалити",
  "action.reopen": "Відновити",
  "action.resend_code": "Надіслати код повторно",
  "action.reset_filters": "Скинути фільтри",
  "action.save": "Зберегти",
  "action.save_changes": "Зберегти зміни",
  "action.search": "Пошук",
  "action.see_more": "Побачити більше",
  "action.send": "Надіслати",
  "action.send_invitation_immediately": "Надіслати запрошення негайно",
  "action.send_request": "Надіслати запит",
  "action.share_with_link": "Поділитися посиланням",
  "action.show_users": "Показати користувачів",
  "action.skip": "Пропустити",
  "action.step": "Крок {{ current }} із {{ total }}",
  "action.try_again": "Сбробовати ще раз",
  "action.update": "Оновити",
  "action.verify": "Підтвердити",
  "action.view_details": "Переглянути деталі",
  "action.view_profile": "Переглянути профіль",
  "action.view_voucher": "Переглянути ваучер",
  "action.yes_add": "Так, додати",
  "action.yes_apply": "Так, застосувати ",
  "action.yes_approve": "Так, схвалити",
  "action.yes_cancel": "Так, скасувати",
  "action.yes_decline": "Так, відхилити",
  "action.yes_delete": "Так, видалити",
  "action.yes_invite": "Так, запросити",
  "action.yes_mark_as_paid": "Так, позначити як оплачену",
  "action.yes_move": "Так, перемістити",
  "action.yes_publish": "Так, опублікувати",
  "action.yes_put_on_hold": "Так, призупинити",
  "action.yes_reassign": "Так, переназначити",
  "action.yes_reject": "Так, відхилити",
  "action.yes_remove": "Так, видалити",
  "action.yes_republish": "Так, опублікувати",
  "activate.administrate_team_info": "На інформаційній панелі ви можете керувати своєю командою як лідер.",
  "activate.download_app_info": "Якщо ви хочете лише стежити за командою, ви можете завантажити додаток або скористатися нашим веб-додатком.",
  "activate.login_info": "Якщо ви менеджер і керуватимете своєю командою, ви повинні увійти за допомогою комп’ютера або iPad.",
  "activate.new_username": "Це буде ваше ім'я користувача.",
  "activate.register_info": "Щоб використовувати Hoopit, ви повинні зареєструватися за допомогою номера мобільного телефону та пароля.",
  "activate.register_successful": "Дякуємо за реєстрацію! 🎉",
  "activate.register_user": "Зареєструвати користувача",
  "add_user.add_admin_confirmation": "Ви хочете додати <b>{{ name }}</b> як <b>{{ title }}</b> до <b>{{ group }}</b>?",
  "add_user.add_administrator": "Додати адміністратора",
  "add_user.add_by_email": "Додати електронною поштою",
  "add_user.add_by_name": "За імʼям",
  "add_user.add_by_phone": "Додайте по телефону",
  "add_user.add_user_confirmation": "Бажаєте додати <b>{{ name }}</b> до <b>{{ групи }}</b>?",
  "add_user.back_to_search": "Назад до пошуку",
  "add_user.confirm_admin": "Підтвердити адміністратора",
  "add_user.confirm_player": "Підтвердити гравця",
  "add_user.enter_email": "Введіть адресу електронної пошти",
  "add_user.enter_name": "Введіть ім'я",
  "add_user.invite_manually": "Запросити вручну",
  "add_user.invite_new_admin": "Запросити нового адміністратора",
  "add_user.invite_new_player": "Запросити нового гравця",
  "add_user.new_user_form_description": "Запросіть особу з номером телефону <b>{{ phoneCode }}{{ phoneNumber }}</b> як <b>{{ title }}</b>",
  "add_user.no_players_found": "Нам не вдалося знайти гравців з таким іменем",
  "add_user.no_players_found_description": "Спробуйте інше ім’я або запросіть вручну",
  "add_user.no_user_found_by_name": "Користувача з таким іменем не знайдено",
  "add_user.no_user_found_by_name_description_1": "Будь ласка, спробуйте інше ім'я або",
  "add_user.no_user_found_by_name_description_2": "додати за номером телефону",
  "add_user.no_user_found_by_phone": "Жодного користувача з цим телефоном не знайдено",
  "add_user.no_user_found_by_phone_description": "Будь ласка, спробуйте інший номер або натисніть Далі та запросіть людину за цим номером",
  "add_user.register_admin": "Зареєструвати адміністратора",
  "add_user.register_player": "Зареєструвати гравця",
  "add_user.registration_link": "Реєстраційне посилання",
  "add_user.search_admin_by_name_description": "Додайте адміністратора по імені або номеру телефону. Виберіть «Додати по телефону», якщо їх ще немає в системі.",
  "add_user.search_by_name": "Пошук за ім’ям",
  "add_user.search_in_organization": "Пошук в організації",
  "add_user.send_invitation": "Надіслати запрошення",
  "add_user.send_registration_link_to_guardians": "Надішліть посилання для реєстрації батькам, яких ви хочете додати до <b>{{ group }}</b>.",
  "add_user.send_registration_link_to_players": "Надішліть реєстраційне посилання гравцям, яких ви хочете додати до <b>{{ group }}</b>, або запросіть їх вручну.",
  "app_badges.google_play": "Доступно в Google Play",
  "app_badges.go_to_webapp": "Перейдіть до веб-додатку",
  "attendance_statistics.activities": "активності",
  "attendance_statistics.end": "Кінець",
  "attendance_statistics.export_statistics": "Експорт статистики відвідуваності",
  "attendance_statistics.no_activities": "Немає активностей за вибраний період",
  "attendance_statistics.no_activities_selected": "Не вибрано жодної активності",
  "attendance_statistics.specify_period": "Вкажіть період",
  "attendance_statistics.start": "Початок",
  "bank_account.account_name": "Ім'я облікового запису",
  "bank_account.account_number": "Номер рахунку",
  "bank_account.account_number_validation_message": "Номер рахунку має складатися з 11 цифр",
  "bank_account.add_new_account": "Додати новий обліковий запис",
  "chat.add_members": "Додати учасників",
  "chat.add_moderator_role": "Додати роль модератора",
  "chat.already_in_the_chat": "Вже у чаті",
  "chat.chat_members": "Учасники чату",
  "chat.create_new_chat_with": "Створити новий чат із",
  "chat.divider_today": "",
  "chat.divider_yesterday": "",
  "chat.image": "Зображення",
  "chat.member": "Учасник",
  "chat.member_added_snackbar": "{{name}} додано до чату",
  "chat.member_removed_snackbar": "{{name}} видалений/-а з чату",
  "chat.members": "Учасники",
  "chat.members_added_snackbar": "{{names}} додано до чату",
  "chat.moderator": "Модератор",
  "chat.new_chat": "Новий чат",
  "chat.no_chats": "Немає чатів",
  "chat.not_available_for_chat": "Чат недоступний",
  "chat.no_users_found": "Користувача з таким іменем не знайдено",
  "chat.no_users_found_hint": "Будь ласка, спробуйте інше ім’я",
  "chat.online": "онлайн",
  "chat.owner": "Власник",
  "chat.remove_member": "Видалити учасника",
  "chat.remove_member_description": "Ви впевнені, що хочете видалити <b>{{ name }}</b> з чату?",
  "chat.remove_moderator_role": "Видалити роль модератора",
  "chat.search_by_member_name": "Шукати по імені",
  "chat.selected_users": "Вибрані учасники",
  "chat.select_people_you_want_in_your_chat": "Виберіть людей для створення чату",
  "chat.start_chat": "Розпочати чат",
  "chat.start_conversations": "Починайте розмови через додаток Hoopit.",
  "chat.unpin": "Відкріпити",
  "chat.who_do_you_want_to_add": "Кого хочете додати?",
  "chat.who_do_you_want_to_chat_with": "З ким хочете створити чат?",
  "confirmation.add_child_to_group": "Ви хочете додати {{ childName }} до {{ groupName }}?",
  "confirmation.add_child_to_waiting_list": "Група повна, бажаєте, щоб {{ childName }} став у чергу?",
  "confirmation.checking_requirements": "Перевірка інформації",
  "confirmation.delete_user_payment": "Ви впевнені, що хочете видалити цей платіж?",
  "confirmation.done": "Готово",
  "confirmation.finished": "Готово",
  "confirmation.no": "Ні",
  "confirmation.save": "Зберегти",
  "confirmation.save_and_notify": "Зберегти та повідомити",
  "confirmation.sent": "Надіслано",
  "confirmation.skip_adding_child": "Не додавати дітей?",
  "confirmation.yes": "Так",
  "dashboard.accounting_label": "Бухгалтерія",
  "dashboard.bank_accounts": "Банківські рахунки",
  "dashboard.calendar_label": "Календар",
  "dashboard.communication_label": "Спілкування",
  "dashboard.conflicting_events": "Конфліктуючі події",
  "dashboard.contact_support": "Звернутися в підтримку",
  "dashboard.dashboard": "Дешборд",
  "dashboard.dugnads": "Дугнади",
  "dashboard.economy_label": "Економіка",
  "dashboard.events_label": "Події",
  "dashboard.expense_claims": "Відшкодування витрат",
  "dashboard.export_members": "Експорт учасників",
  "dashboard.fotballdata_label": "Footballdata",
  "dashboard.group_settings_label": "Налаштування",
  "dashboard.groups_label": "Групи",
  "dashboard.help_center": "Центр підтримки",
  "dashboard.help_label": "Довідка",
  "dashboard.individual_payments": "Платіжні запити",
  "dashboard.invoices": "Рахунки-фактури",
  "dashboard.language": "Мова",
  "dashboard.locations_label": "Локації",
  "dashboard.logout_label": "Вийти",
  "dashboard.membership_dashboard": "Інформаційна панель учасників",
  "dashboard.membership_label": "Членство",
  "dashboard.my_profile": "Мій профіль",
  "dashboard.new": "Нове",
  "dashboard.nif_connection": "NIF підключення",
  "dashboard.nif_venues": "NIF локації",
  "dashboard.organization": "Організація",
  "dashboard.organization_label": "Організація",
  "dashboard.payment_provider": "Платіжний провайдер",
  "dashboard.payments": "Платежі",
  "dashboard.payouts": "Виплати",
  "dashboard.productboard_label": "Продукт",
  "dashboard.sales_dugnads": "Дугнади товарів",
  "dashboard.sms_configuration": "Конфігурація SMS",
  "dashboard.stripe_not_connected_warning": "Схоже, {{ organization }} не під’єднана до Stripe.",
  "dashboard.switch_to_paid_mf_view": "",
  "dashboard.team_account": "Командні рахунки",
  "dashboard.users_label": "Користувачі",
  "dashboard.waiting_list_label": "Лист очікування",
  "delete_member_requests_list_decline_description": "Користувачі не будуть додані до групи, але ви все ще зможете переглядати відхилені запити в «Запитах на членство».",
  "denied.delete_paid_user_payment": "Ви не можете видалити оплачений платіж",
  "denied.edit_paid_user_payment": "Ви не можете змінити оплачений платіж",
  "dugnad.payment_end_date": "Дата закінчення платежу",
  "dugnad.payment_end_date_field_hint": "Гравці, які приєднаються після цієї дати, не будуть включені в цей дугнад.",
  "dugnad.payment_end_date_min_error": "Дата закінчення платежу не може бути раніше дати публікації",
  "economy.above_age": "Старше {{ age }}",
  "economy.account": "Рахунок",
  "economy.account_deleted": "Банківський рахунок видалено.",
  "economy.account_length_hint": "Номер рахунку має складатися з {{ number }} цифр",
  "economy.add_1_product": "Додати 1 продукт",
  "economy.add_age_group": "Додайте вікову групу",
  "economy.add_age_restriction": "Додайте вікове обмеження",
  "economy.add_at_least_one_manager": "Додайте хоча б одного дугнад менеджера",
  "economy.add_at_least_one_product": "Додайте щонайменше 1 продукт",
  "economy.add_buy_out": "Додати опцію для викупу",
  "economy.add_buy_out_description": "Дозвольте учасникам викупитися з участі в дугнад.",
  "economy.add_buy_out_price": "Додати ціну викупу",
  "economy.add_comment_for_user": "Додати коментар для {{user}}",
  "economy.add_dugnad_manager": "Додати дугнад менеджера",
  "economy.added": "Додано",
  "economy.add_family_discount": "Додайте сімейну знижку",
  "economy.add_family_discount_description": "Встановіть ціну за сім'ю, щоб сім'ї могли отримати знижку. Встановіть мінімальну ціну за учасника, щоб обмежити знижку.",
  "economy.add_family_discount_description_2": "Встановіть знижку на сімейне замовлення залежно від кількості членів сім'ї.",
  "economy.add_group_restriction": "Додайте групове обмеження",
  "economy.add_n_products": "Додати {{number}} продуктів",
  "economy.add_price_category": "Додайте цінову категорію",
  "economy.add_product": "Додати товар",
  "economy.add_role_restriction": "Додайте обмеження ролі",
  "economy.add_sibling_discount": "Додайте знижку за братів і сестер",
  "economy.add_sibling_discount_description": "Установіть знижку залежно від кількості братів і сестер або встановіть максимальну ціну на сім’ю, встановивши знижку на 100% після кількості братів і сестер.",
  "economy.age_coverage_modal_text_1": "Здається, ви не вказали плату за вік:",
  "economy.age_coverage_modal_text_2": "Переконайтеся, що цінова категорія охоплює всіх користувачів.",
  "economy.age_coverage_modal_text_3": "Користувачі, які не охоплюються, не отримуватимуть членський внесок.",
  "economy.age_coverage_modal_text_4": "Ви хочете повернутися і виправити це?",
  "economy.age_group": "Вікова група",
  "economy.age_groups": "Вікові групи",
  "economy.age_restriction": "Вікове обмеження",
  "economy.age_restriction_description": "Гравці, яким виповнилося {{ age }} або старше, не отримають знижки та не враховуватимуться в кількості братів і сестер у їхній родині.",
  "economy.age_restriction_description_2": "Гравці, які досягли цього віку, не отримають знижки та не будуть зараховані до кількості братів і сестер у їхній родині.",
  "economy.age_restriction_description_3": "Гравці віком від {{ age }} не отримають знижки та не враховуватимуться до кількості членів родини.",
  "economy.age_restriction_description_4": "Гравці, які досягли цього віку, не отримають знижки та не будуть зараховані до кількості членів сім’ї.",
  "economy.all_ages": "Всі віки",
  "economy.all_fees": "Всі збори",
  "economy.allow_users_to_order_more_items": "Дозволити замовити більше",
  "economy.allow_users_to_order_more_items_description": "Учасники зможуть збільшити кількість предметів, які вони бажають замовити.",
  "economy.apply_changes_to_sub_groups": "Застосувати зміни до підгруп",
  "economy.apply_changes_to_sub_groups_description": "Коли ця опція увімкнена, ціна для для всіх підгруп цієї групи буде оновлена.",
  "economy.at_least_n_items_in_total": "Щонайменше {{ number }} продуктів",
  "economy.auto_buy_out_description": "Виберіть, що робити гравцям, якщо вони пропустили термін підтвердження замовлення: залишатися “не підтвердженими” (це означає, що вони повинні забрати предмети) або автоматично бути зобовʼязаним “відкупитись”.",
  "economy.auto_buy_out_summary_1": "Гравці, які пропустили термін підтвердження, залишаються “непідтвердженими”.",
  "economy.auto_buy_out_summary_2": "Гравці, які пропустили термін підтвердження, автоматично зобовʼязані “відкупитись”.",
  "economy.available_for_refund_hint": "NOK {{x}} доступно для відшкодування",
  "economy.bank_account": "Банківський рахунок",
  "economy.bank_account_account_number": "Номер рахунку",
  "economy.bank_account_account_status": "Статус аккаунта",
  "economy.bank_account_account_status_description": "Коли аккаунт уввімкнено, ви можете обрати його, коли створюєте нові платежі для сбору коштів, або відшкодуванню витрат",
  "economy.bank_account_add": "",
  "economy.bank_account_additional_info_required_short": "Потрібна додаткова інформація",
  "economy.bank_account_bank": "Банк",
  "economy.bank_account_bank_account_number": "Номер банківського рахунку",
  "economy.bank_account_cannot_be_deleted": "Рахунок не можна видалити",
  "economy.bank_account_cannot_create_tooltip": "Ви не можете створювати банківські рахунки в {{ groupName }}. Бідь ласка оберіть іншу групу",
  "economy.bank_account_cannot_deactivate": "Банківський рахунок не може бути деактивований, тому що це останній рахунок, який можна використовувати для відшкодування позовів на витрати, та група, до якої прив’язаний цей аккаунт, має подані заявки на відшкодування витрат.",
  "economy.bank_account_cannot_delete_description": "Банківський рахунок не може бути видалений через наступні причини:",
  "economy.bank_account_cannot_delete_reason_1": "цей рахунок був використаний при створенні платежу;",
  "economy.bank_account_cannot_delete_reason_2": "цей рахунок використовується для підтвердженої заявки на відшкодування витрат;",
  "economy.bank_account_cannot_delete_reason_3": "це останній рахунок, який можна використовувати для відшкодування позовів на витрати, та група, до якої прив’язаний цей аккаунт, має подані заявки на відшкодування витрат;",
  "economy.bank_account_complete_setup": "Завершити установку",
  "economy.bank_account_complete_setup_description_1": "Введіть повний номер банківського рахунку {{ accountName }}, 4 останні цифри якого ’{{ accountLast4 }}’.",
  "economy.bank_account_complete_setup_description_1.5": "Введіть повний номер банківського рахунку {{ accountName }}, 4 останні цифри якого \"{{ accountLast4 }}\".",
  "economy.bank_account_complete_setup_description_2": "У разі, якщо є інші банківські рахунки з таким самим номером, ми завершемо установку для них автоматично",
  "economy.bank_account_complete_setup_validation_error": "",
  "economy.bank_account_delete": "Видалити банківський рахунок?",
  "economy.bank_account_delete_description": "Банківський рахунок можна видалити тільки якщо на нього не біло зроблено жодної оплати",
  "economy.bank_account_description": "Платежі, сплачені після зміни банківського рахунку, будуть перераховані на новий банківський рахунок. Всі вже сплачені платежі будуть перераховані на поточний рахунок.",
  "economy.bank_account_edit_name": "Змінити назву рахунка",
  "economy.bank_account_edit_name_description": "Назва цього рахунку буде змінено лише для {{ groupName }}.",
  "economy.bank_account_empty_state": "Ще не додано жодного банківського рахунку",
  "economy.bank_account_feedback_success": "Ваш запит зареєстровано. Ми з вами скоро зв’яжемося.",
  "economy.bank_account_full_number_required": "Закінчіть налаштування банківського рахунку",
  "economy.bank_account_full_number_required_description_1": "Наразі, ми підготовлюємо систему для обробки витрат та відшкодувань заявок від користувачів.",
  "economy.bank_account_full_number_required_description_2": "Будь ласка, надайте повний номер банківського рахунку, який поєднано з {{ groupName }}",
  "economy.bank_account_group": "Група",
  "economy.bank_account_group_type": "Тип групи",
  "economy.bank_account_information": "Інформація про рахунок",
  "economy.bank_account_name": "Назва рахунку",
  "economy.bank_account_name_was_updated": "Назву рахунка успішно змінено.",
  "economy.bank_account_new": "Новий рахунок",
  "economy.bank_account_no_accounts_found": "Не знайдено жодного рахунку",
  "economy.bank_account_no_accounts_found_description": "",
  "economy.bank_account_number": "Номер банківського рахунку",
  "economy.bank_account_number_validation_message": "Має бути 11 цифр",
  "economy.bank_account_search_by_account_name": "Шукати за номером рахунку",
  "economy.bank_account_status_hint": "Якщо рахунок вимкнено та з ним пов’язані поточні платежі, гроші продовжуватимуть надходити на цей рахунок, однак ви не зможете вибрати його для нових платежів",
  "economy.bank_account_try_searching_by_another_name": "Спробуйте пошук з іншою назвою",
  "economy.bank_account_was_added_to_group": "Новий банківський рахунок було додано до {{ groupName }}.",
  "economy.bank_account_was_added_to_group_and_updated": "Новий банківський рахунок було додано до {{ groupName }} та оновлено для {{ amountOfGroups }} інших груп.",
  "economy.bank_account_was_deleted": "Рахунок було видалено",
  "economy.bank_account_was_set_up_to_active": "Статус цього рахунка змінено на активний.",
  "economy.bank_account_was_set_up_to_inactive": "Статус цього рахунка змінено на неактивний.",
  "economy.bank_account_was_updated_for_group": "Банківський рахунок було оновлено для {{ groupName }}.",
  "economy.bank_account_was_updated_for_multiple_groups": "Банківський рахунок було оновлено для {{ groupName }} та {{ amountOfGroups }} інших груп.",
  "economy.based_on_the_number_of_items_per_player": "на основі кількості предметів на гравця",
  "economy.basic_price": "Базова ціна",
  "economy.bought_out": "Викуплено",
  "economy.bought_out_items": "Викуплені речі",
  "economy.buy_out": "Викуп",
  "economy.buy_out_net_amount_per_item": "Чиста сума викупу за одиницю",
  "economy.buy_out_option": "Можливість відкупу",
  "economy.buy_out_option_description": "Калькуляція суми викупа основана на кількості предметів для одного гравця. Якщо гравець підпадає під сімейну знижку, він зберігає можливість викупити себе, зберігши знижку.",
  "economy.buy_out_price": "Ціна викупу",
  "economy.buy_out_price_per_item": "Ціна викупу за шт",
  "economy.by_account": "За рахунком",
  "economy.by_confirmation_due_date": "По терміну підтвердження",
  "economy.by_confirmation_status": "По статусу підтвердження",
  "economy.by_due_date": "За дедлайном",
  "economy.by_generation_date": "За датою створення",
  "economy.by_group": "За групою",
  "economy.by_last_reminder_date": "За датою останнього нагадування",
  "economy.by_net_amount": "За сумою нетто",
  "economy.by_optional_status": "За опціональним статусом",
  "economy.by_order_status": "За статусом замовлення",
  "economy.by_payment_date": "За датою оплати",
  "economy.by_payment_due_date": "По терміну платежа",
  "economy.by_payment_status": "За статусом оплати",
  "economy.by_payment_type": "За типом оплати",
  "economy.by_publish_date": "За датою публікації",
  "economy.cannot_be_earlier_than_publish_date_error": "Не може бути раніше дати публікації",
  "economy.cannot_be_later_than_period_end": "Не може бути пізніше кінця періоду",
  "economy.cannot_delete_bank_account": "Банківський рахунок не можна видалити, можна лише видалити банківський рахунок, на який не було здійснено жодних платежів",
  "economy.cannot_refund_manually_paid_payments_tooltip": "Відшкодування на карту не є можливим для платежів, які було позначено як оплачені вручну. ",
  "economy.cannot_refund_multi_user_mf_tooltip": "Платежі, сплачені за кількох учасників, не можуть бути повернуті на картку.\n\nВідшкодування на карту не є можливим для платежів, які було сплачено за кількох учасників за раз. ",
  "economy.change": "Змінити",
  "economy.cheapest_fee": "Найдешевша плата",
  "economy.coming_soon": "Незабаром!",
  "economy.completed": "Завершено",
  "economy.confirmation_due_date": "Термін підтвердження",
  "economy.confirmation_reminder": "Нагадування про підтвердження",
  "economy.contact_email": "Контактна електронна адреса",
  "economy.contact_email_hint": "Відповіді користувачів на нагадування про оплату будуть надіслані на цю електронну адресу",
  "economy.cost": "Вартість",
  "economy.create_age_group": "Створити вікову групу",
  "economy.created_for": "Створений для",
  "economy.created_for_1_group": "Створено для 1 групи",
  "economy.created_for_n_groups": "Створено для {{ number }} груп",
  "economy.create_dugnad": "Створити дугнад",
  "economy.create_dugnad_for": "Створити дугнад для",
  "economy.create_membership_fee_description": "Створіть оплату членських внесків. Цей платіж буде обов’язковим для гравців, але надсилається всім, для заохочення підтримки членства.",
  "economy.create_new_product": "Створити новий продукт",
  "economy.create_order_for_1_group": "Створити замовлення для 1 групи?",
  "economy.create_order_for_n_groups": "Створити порядок для {{ number }} груп?",
  "economy.create_payment": "Створити платіж",
  "economy.create_payment_for_1_group": "Створити оплату для 1 групи",
  "economy.create_payment_for_n_groups": "Створити оплату для {{ number }} груп?",
  "economy.create_price_category": "Створити цінову категорію",
  "economy.create_product": "Створити товар",
  "economy.create_survey_for_1_group": "Створити опитування для 1 групи?",
  "economy.create_survey_for_n_groups": "Створити опитування для {{ number }} груп?",
  "economy.create_training_fee": "Створіть плату за тренування",
  "economy.create_training_fee_description": "Створіть платіж щорічної плати за тренування для спорту. Тут ви можете встановити ціну для кожної команди окремо та встановити знижки для братів і сестер для сімей з кількома дітьми в клубі.",
  "economy.custom_pick_up_location": "Інше місце",
  "economy.declined": "Відхилено",
  "economy.delay_payment": "Відстрочити надсилання новим членам",
  "economy.delay_payment_description": "Оберіть коли цей платіж має бути надіслано користувачам, які приєднались після його публікації.",
  "economy.delete_bank_account": "Видалити {{ account_name }}",
  "economy.delete_bank_account_description": "Банківський рахунок можна видалити, лише якщо на нього не було здійснено жодних платежів.",
  "economy.department_add_bank_account": "Додайте банківський рахунок у налаштуваннях дівізії перед створенням комісії.",
  "economy.department_add_team": "Додайте принаймні одну команду до відділу, перш ніж створити плату за тренування.",
  "economy.description": "Опис",
  "economy.details_of_this_dugnad": "Деталі цього дугнаду",
  "economy.different_account_selected_hint": "Обраний рахунок відрізняється від того, на який спочатку був відправлений платіж. Дійте лише в тому випадку, якщо повернення коштів слід здійснити за допомогою цього рахунку.",
  "economy.different_delivery_warning": "Терміни доставки можуть відрізнятися",
  "economy.different_delivery_warning_description": "Ви вибрали продукти від різних постачальників. Це може призвести до окремих поставок із різним часом прибуття.",
  "economy.discount": "Знижка",
  "economy.discount_example_description": "Приклад оснований на {{ items }} речей на одного користувача за ціною {{ price }} NOK за одну річ",
  "economy.discount_example_family": "Сім'я Аскеладден Фелген",
  "economy.discount_for_other_siblings": "Знижка для інших братів і сестер",
  "economy.discount_for_other_siblings_description": "Ця знижка надається всім братам і сестрам після #{{ sibling }}. Вставте нуль, якщо ви хочете, щоб вони платили повну ціну.",
  "economy.discount_for_sibling_#": "Знижка для брата чи сестри #{{ sibling }}",
  "economy.discount_level": "Етапи знижки: {{ level }}",
  "economy.discount_levels": "Знижки",
  "economy.discount_value_100_warning_message": "Користувачі зі 100% знижкою отримають платіж у розмірі 0 норвезьких крон.",
  "economy.discount_value_range_error_message": "Знижка має становити від 0% до 100%.",
  "economy.division_permission_denied": "Вам не дозволяється створювати плату за тренування для цієї дівізії",
  "economy.download_invoice": "Завантажити рахунок-фактуру (PDF)",
  "economy.download_orders": "Завантажити замовлення",
  "economy.dugnad": "Дугнад",
  "economy.dugnad_created": "Дугнад створено",
  "economy.dugnad_no_access_success": "Ваш запит зареєстровано. Ми з вами скоро зв’яжемось.",
  "economy.dugnad_no_access_text_1": "Хочете отримати ранній доступ?",
  "economy.dugnad_no_access_text_2": "Зв’яжіться з нами! Пілотні запуски вже почались.",
  "economy.dugnad_no_access_textarea_placeholder": "Напишіть, чому ви зацікавлені",
  "economy.dugnad_no_access_title": "Дугнади вже скоро!",
  "economy.dugnad_order_description": "Усі учасники зможуть підтвердити своє замовлення до терміну підтвердження.",
  "economy.dugnad_participants": "Учасники дугнаду",
  "economy.dugnad_payment": "Оплата дугнада",
  "economy.dugnad_person_in_multiple_groups": "Учасники двох та більше груп отримають лише один дугнад.",
  "economy.dugnad_reminder_description": "Нагадування надсилається на електронну пошту та push-повідомленням в мобільному додатку.   Повідомлення містить необхідну інформацію про дугдад. Нижче ви можете додати особисте повідомлення для <b>{{ name }}</b>.",
  "economy.dugnad_reminder_description_1": "Повідомлення включає в себе наступну інформацію: <i>оберіть тип нагадування щоб побачити більше</i>.",
  "economy.dugnad_reminder_description_2": "Повідомлення включає в себе наступну інформацію: назву дугнаду, опис і термін підтвердження.",
  "economy.dugnad_reminder_description_3": "Повідомлення включає в себе наступну інформацію: назву дугнаду.",
  "economy.dugnad_reminder_description_4": "Повідомлення включає в себе наступну інформацію: назву дугнаду, опис та термін оплати.",
  "economy.dugnad_reminder_description_hint": "Нижче ви можете додати особисте повідомлення для <b>{{ name }}</b>.",
  "economy.dugnad_select_groups_description": "Виберіть групу, яка отримає грошi з дугнаду.",
  "economy.dugnad_select_groups_description_2": "Виберіть групи, чиї гравці братимуть участь у дугнаді.",
  "economy.dugnad_settings_description": "Створіть <b>опитування</b>, якщо хочете зібрати інформацію про кількість товарів, які люди хотіли б у вас замовити. Ви зможете створити платіж пізніше.",
  "economy.dugnad_settings_description_2": "Або відразу створіть <b>платіж</b>.",
  "economy.dugnad_update_success": "Дугнад для {{ name }} оновлено",
  "economy.dugnad_was_updated": "Дугнад було оновлено",
  "economy.dugnad_will_be_created_for": "Дугнад буде створено для",
  "economy.dugnad_without_orders": "Дугнад без замовлень",
  "economy.duplicate_payment": "Дублікат платежу",
  "economy.economy_empty_state_description": "Лише <b>менеджери фінансів</b> мають доступ до цієї сторінки",
  "economy.economy_empty_state_header": "Фінанси організації",
  "economy.edit_comment_for_user": "Редагувати коментар для {{user}}",
  "economy.edit_dugnad_for": "Відредагувати дугнад для",
  "economy.edit_order_for": "Редагувати замовлення для",
  "economy.edit_payment_due_date": "Змінити термін оплати",
  "economy.edit_payment_due_date_description": "Термін оплати буде оновлено для всіх гравців, а дата публікації платежу – лише для гравців, які пропустили дату підтвердження ({{ date }}).",
  "economy.edit_payment_due_date_description_2": "Учасники, які підтвердили свої замовлення, отримують платежі одразу.",
  "economy.edit_pick_up": "Редагувати видачу",
  "economy.edit_tf_description": "Зміни стосуватимуться лише нових учасників",
  "economy.edit_tf_description_2": "Існуючі платежі не будуть змінені",
  "economy.enter_age_from_to": "Введіть вік від {{ ageFrom }} до {{ ageTo }}",
  "economy.estimated_income": "Орієнтовний дохід",
  "economy.estimated_income_description_1": "Розрахунок не включає випадки, коли учасник вибирає викупитися або отримує сімейну знижку.",
  "economy.estimated_income_description_2": "Розрахунок не включає випадки, коли учасник вибирає викупитися.",
  "economy.estimated_income_description_3": "Розрахунок не включає випадки, коли учасник отримує сімейну знижку.",
  "economy.estimated_net_amount": "Приблизна чиста сума",
  "economy.estimated_transaction_fee": "Приблизна комісія",
  "economy.event_payment": "Оплата події",
  "economy.event_with_payment": "Подія з оплатою",
  "economy.example_based_on_price": "Приклад базується на ціні NOK {{ price }}.",
  "economy.example_description": "Приклад базується на мінімальній кількості товарів на користувача - {{ items }} і ціні за одиницю NOK {{ price }}.",
  "economy.example_for_adults": "Приклад: Для дорослих",
  "economy.example_of_what_user_will_see": "Приклад того, що побачить користувач",
  "economy.excel_payment_description": "Створює файл Excel для внутрішніх цілей. Цей файл містить усі платежі учасника, створені за вибраний рік/місяць.",
  "economy.excel_payment_notification": "Доступно лише для клубного та спортивного рівнів.",
  "economy.excel_payment_overview": "(Excel) Огляд платежів",
  "economy.exempted": "Виключено",
  "economy.exempted_items": "Анульовані предмети",
  "economy.exempt_payment": "Оплату анульовано",
  "economy.expected": "Очікується",
  "economy.expected_income": "Очікуваний дохід",
  "economy.failed": "Не вдалося",
  "economy.family_discount": "Сімейна знижка",
  "economy.family_discount_description": "Будь ласка, встановіть мінімальну ціну за учасника та сімейну ціну.",
  "economy.family_discount_description_2": "Мінімальна ціна за учасника не може бути нижчою за 50 норвезьких крон через вимоги NIF.",
  "economy.family_discount_description_3": "Сімейна ціна – це ціна, яку хтось заплатить за кількох членів сім’ї, якщо загальна сума сплати їхніх індивідуальних платежів перевищує встановлену вами тут.",
  "economy.family_discount_description_4": "Якщо індивідуальна загальна сума для всієї родини нижча за «сімейну ціну», сім’я сплачуватиме початкову суму. Приклад: якщо ціна для сім’ї становить 650 норвезьких крон, ціна для дорослого – 150 норвезьких крон, а для дітей – 100 норвезьких крон, і є двоє дорослих і двоє дітей, вони заплатять 500 норвезьких крон.",
  "economy.family_discount_info": "Знижка буде доступна для усіх членів родини, хто являються гравцями в обраних",
  "economy.family_discount_info_2": "Знижка буде примінена коли користувач підтвердить замовлення, або платіж.",
  "economy.family_discount_option_description": "Знижка буде доступна для усіх членів родини, хто являються гравцями в обраниї групах. Знижка буде примінена коли користувач підтвердить замовлення, або платіж.",
  "economy.family_discounts_example": "Приклад сімейної знижки",
  "economy.family_discounts_example_description": "Виберіть людей за яких будете платити:",
  "economy.family_discounts_example_description_2": "Виберіть членів родини зараз, щоб отримати сімейну знижку",
  "economy.family_discounts_example_header": "Приклад того, що побачить користувач",
  "economy.family_discounts_example_member_1": "Ім'я користувача",
  "economy.family_discounts_example_member_2": "Дитина 1",
  "economy.family_discounts_example_member_3": "Дитина 2",
  "economy.family_discounts_example_member_4": "Ім'я партнера користувача",
  "economy.family_discounts_example_optional_payment_hint": "Підтримайте свій клуб, ставши асоційованим членом",
  "economy.family_discounts_example_table_age": "Вік",
  "economy.family_discounts_example_table_discount": "Знижка",
  "economy.family_discounts_example_table_items": "Предмети",
  "economy.family_discounts_example_table_name": "Ім'я",
  "economy.family_discounts_example_table_price": "Ціна",
  "economy.family_member_#": "Член сім'ї #",
  "economy.family_price": "Сімейна ціна",
  "economy.family_price_note": "Сімейна ціна включає комісію за транзакцію",
  "economy.family_price_validation": "Не може бути нижчою за мінімальну ціну для учасника",
  "economy.fee_value_helper_text": "Щоб уникнути помилок, кожне поле має бути заповненим. Встановіть нульову ціну для груп, які не повинні платити за тренування.",
  "economy.fill_at_least_one_age_field": "Заповніть хоча б одне вікове поле",
  "economy.find_goods": "Подивитися продукти",
  "economy.fixed_number": "Фіксоване число",
  "economy.fixed_#_of_items": "Виправлено кількість елементів",
  "economy.for_age": "Для {{ age  }}",
  "economy.forgot_anyone": "Когось забули?",
  "economy.for_group_type": "Для",
  "economy.from_age_including": "Від віку (в т.ч.)",
  "economy.from_age_to_age": "Від {{ ageFrom }} до {{ ageTo }}",
  "economy.full_catalogue_button": "повний каталог",
  "economy.full_overview": "Повний огляд для",
  "economy.full_price": "Повна ціна",
  "economy.full_refund": "Повне відшкодування",
  "economy.fully_refunded_payments": "Повністю відшкодовані платежі",
  "economy.generate_export": "Згенерувати експорт",
  "economy.generate_invoice_for": "Створити рахунок-фактуру для",
  "economy.generate_new_invoice": "Згенерувати рахунок",
  "economy.generation_date": "Дата створення",
  "economy.give_your_dugnad_a_title": "Назвіть ваш дугнад",
  "economy.go_back_and_fix": "Повернутися та виправити",
  "economy.group": "Команда",
  "economy.groups_have_no_accounts": "{{ groupName }} та її сабгрупи не мають банківских рахункiв",
  "economy.include_transaction_fee": "Усі вказані ціни включають комісію за транзакцію",
  "economy.including_transaction_fee": "Включно з комісією за транзакцію <span>{{ fee }}</span>",
  "economy.income": "Дохід",
  "economy.in_progress": "В прогресі",
  "economy.insufficient_funds_hint": "На цьому рахунку недостатньо коштів для відшкодування вказаної суми.",
  "economy.insufficient_funds_tag": "Недостатньо коштів",
  "economy.invalid_range": "Недійсний діапазон",
  "economy.invoice": "рахунок-фактура",
  "economy.invoice_history": "Історія рахунків-фактур",
  "economy.invoice_history_for": "Історія рахунків-фактур для",
  "economy.invoices": "рахунки-фактури",
  "economy.invoices_i_created": "Рахунки-фактури, створені мною",
  "economy.item": "продукт",
  "economy.items": "продукти",
  "economy.items_for_family_member": "Предмети для члена сім'ї # {{number}}",
  "economy.items_for_other_family_members": "Предмети для інших членів сім'ї",
  "economy.items_per_player": "Предметів на гравця",
  "economy.items_per_player_description": "Встановіть кількість предметів для всіх гравців або налаштуйте її для різних вікових груп.",
  "economy.items_per_player_description_2": "Встановлення мінімальної кількості предметів дає змогу збільшити кількість у додатку.",
  "economy.jump_to_member_payments": "Перейдіть до платежів учасників",
  "economy.latest_publish_date_description": "Ми надішлемо платежі всім учасникам в день публікації платежу.",
  "economy.latest_publish_date_description_2": "Учасники не можуть змінити кількість товарів, якщо вони пропустили термін підтвердження.",
  "economy.manager_is_already_on_the_list": "{{ name }} вже в списку",
  "economy.mandatory_for_new_players_joining": "Обов'язковий для приєднання нових гравців до",
  "economy.mandatory_tf_to_join_description": "Коли ця опція увімкнена, новим гравцям буде надано 15 хвилин, щоб сплатити цю плату за тренування, щоб підтвердити своє членство в групі. В іншому випадку вони будуть автоматично видалені.",
  "economy.mandatory_tf_to_join_existing_payment_description_1": "Це не впливає на одну групу з існуючим обов’язковим платежем.",
  "economy.mandatory_tf_to_join_existing_payment_description_2": "Це не впливає на {{ number }} груп з існуючими обов’язковими платежами.",
  "economy.mandatory_tf_to_join_existing_payment_description_3": "Ви можете змінити це, редагуючи поведінку для окремих груп індивідуально.",
  "economy.mandatory_tf_to_join_label": "Нові гравці мусять заплатити цей платіж, щоб приєднатися",
  "economy.mandatory_tf_to_join_label_2": "Стосується <b>{{ group }}</b> та усіх її підгруп.",
  "economy.maximum_age_is": "Максимальний вік становить {{ age }}",
  "economy.member_price": "Ціна для учасників",
  "economy.members": "Учасники",
  "economy.membership_fee": "Членський внесок",
  "economy.membership_fee_no_permission_description": "Лише <b>адміністратори на рівні організації</b> мають доступ до цієї сторінки",
  "economy.membership_fee_no_permission_header": "Фінанси організації",
  "economy.membership_payments": "Членські платежі",
  "economy.members_under_age": "Застосовується до членів сім’ї віком до {{ age }} років.",
  "economy.minimum_age_is": "Мінімальний вік становить {{ age }}",
  "economy.minimum_number_of_items": "Мінімальна кількість предметів",
  "economy.minimum_#_of_items": "Мінімальна кількість елементів",
  "economy.minimum_price_per_member": "Мінімальна ціна за учасника",
  "economy.minimum_price_per_member_validation": "Не може бути нижчим за 50 норвезьких крон",
  "economy.min_number_of_items": "Мінімальна кількість предметів",
  "economy.most_expensive_fee": "Найдорожча плата",
  "economy.multiple_price_categories": "Кілька цінових категорій",
  "economy.multiple_price_categories_description": "Будь ласка, установіть спеціальну ціну, щоб створити рахунок-фактуру",
  "economy.must_publish_for_show_progress": "Опублікувати платіж, щоб побачити його прогрес",
  "economy.net_amount": "Чиста сума",
  "economy.net_amount_for_team": "Чиста сума для {{ team }}",
  "economy.net_amount_per_item": "Сума нетто за одиницю",
  "economy.net_payout": "Чиста виплата",
  "economy.new": "Новий",
  "economy.new_dugnad": "Новий дугнад",
  "economy.new_dugnad_payment": "Нова оплата дугнад",
  "economy.new_invoice_description": "Вам потрібно буде позначити платіж як оплачений вручну після отримання підтвердження оплати в рахунку-фактурі.",
  "economy.new_invoice_description_2": "Ми не будемо надсилати автоматичні нагадування користувачеві після створення першого рахунку-фактури. Але ви все одно зможете надсилати нагадування вручну, які надсилатимуться як електронні листи та push-сповіщення в програмі.",
  "economy.new_membership_fee": "Новий членський внесок",
  "economy.new_payment": "Нова оплата",
  "economy.new_pick_up_reminder_description": "Нагадування надсилається як електронний лист і push-сповіщення в програмі. Автоматизоване повідомлення містить необхідну інформацію про замовлення. Нижче ви можете додати особисте повідомлення для <b>{{ firstName }} {{ lastName }}</b>.",
  "economy.new_pick_up_reminder_for": "Нове нагадування про отримання для",
  "economy.new_reminder_description": "Нагадування надсилається як електронний лист і push-сповіщення в програмі. Автоматизоване повідомлення містить необхідну платіжну інформацію. Нижче ви можете додати особисте повідомлення для <b>{{ firstName }} {{ lastName }}</b>.",
  "economy.new_reminder_for": "Нове нагадування для",
  "economy.new_reminder_success": "Нове нагадування надіслано {{ name }}",
  "economy.new_sales_dugnad": "Новий дугнад товарів",
  "economy.new_training_fee": "Нова плата за навчання",
  "economy.next": "Далі",
  "economy.nif_minimum_price_description": "Мінімальна ціна за учасника не повинна бути нижчою за 50 норвезьких крон через вимоги NIF.",
  "economy.no_bank_accounts": "Банківські рахунки не знайдено",
  "economy.no_bank_accounts_description": "Переконайтеся, що хоча б одна з вибраних груп має активний банківський рахунок",
  "economy.no_divisions": "Ще не створено жодної дівізії",
  "economy.no_divisions_instruction": "Перейдіть до своїх груп і додайте принаймні одну дівізію, щоб створити плату за тренування.",
  "economy.no_order_created": "Не створено жодного замовлення",
  "economy.no_payments": "Без попередніх платежів",
  "economy.no_survey_created": "Опитування не створено",
  "economy.no_teams_in_division": "У цій дівізії немає команд",
  "economy.not_paid": "Не оплачено",
  "economy.not_paid_estimation_description": "Оцінка на основі всіх платежів, крім відхилених, анульованих і оплачених. Сума не включає комісію за транзакцію.",
  "economy.not_paid_in_total": "Не сплачено (усього)",
  "economy.not_paid_payments": "Невиплачені платежі:",
  "economy.not_picked_up": "Не забрано",
  "economy.number": "Кількість",
  "economy.number_of_items": "Кількість предметів",
  "economy.number_of_items_per_person": "Кількість продуктів на людину",
  "economy.number_of_items_per_player": "Кількість предметів на гравця",
  "economy.#_of_items": "# предметів",
  "economy.#_of_items_for_family_member": "Кількість предметів для члена сім’ї №{{ number }}",
  "economy.#_of_items_for_other_family_members": "# предметів для інших членів сім'ї",
  "economy._of_items_for_other_family_members_hint": "Кількість предметів, які всі члени сім’ї після #{{ number }} повинні купити.",
  "economy.#_of_players": "# гравців",
  "economy.one_fee": "Тільки одна плата",
  "economy.ongoing": "Триваючі",
  "economy.only_optional": "Тільки опціональні",
  "economy.only_required": "Тільки обов'язкові",
  "economy.optional": "Необов'язково",
  "economy.order": "Замовлення",
  "economy.order_created": "Замовлення створено",
  "economy.order_date_description": "Замовлення опублікуються о 12:00.",
  "economy.order_publish_date": "Дата публікації замовлень",
  "economy.order_reminder": "Нагадування про замовлення",
  "economy.orders": "Замовлення",
  "economy.order_update_success": "Замовлення для {{ name }} оновлено",
  "economy.other_options": "Інші варіанти",
  "economy.other_options_dugnad_description": "Кожна організація особиста! Ви можете підібрати дугнат під свої потреби.",
  "economy.other_options_mf_description": "Кожен клуб різний! Тут ви можете налаштувати членський внесок відповідно до ваших потреб.",
  "economy.other_options_tf_description": "Кожен клуб різний! Тут ви можете налаштувати плату за тренування відповідно до ваших потреб.",
  "economy.other_payment": "Інша оплата",
  "economy.other_payment_description": "Створіть оплату для організації, виду спорту, команди чи особи.",
  "economy.other_payments": "Інші платежі",
  "economy.other_payment_warning": "Додайте банківський рахунок у налаштуваннях групи перед створенням платежу",
  "economy.outstanding": "Несплачено",
  "economy.overdue": "Прострочено",
  "economy.paid": "Оплачено",
  "economy.paid_in_total": "Сплачено (усього)",
  "economy.paid_payments": "Оплачені платежі",
  "economy.paid_to_account_tag": "Оригінальний рахунок",
  "economy.partially_refunded_payments": "Частково відшкодовані платежі",
  "economy.partial_refund": "Часткове відшкодування",
  "economy.participants": "учасники",
  "economy.past": "Минулі",
  "economy.past_payment": "Минулий платіж",
  "economy.payment_date": "Дата оплати",
  "economy.payment_deadline": "Дедлайн оплати",
  "economy.payment_deadline_validation_message_max": "?",
  "economy.payment_deadline_validation_message_min": "Дедлайн не може бути у минулому.",
  "economy.payment_details": "Деталі платежу",
  "economy.payment_details.details": "Деталі",
  "economy.payment_details_price_categories": "Цінові категорії",
  "economy.payment_details_tab_details": "Деталі",
  "economy.payment_details_tab_payments": "Платежі",
  "economy.payment_export": "Експорт платежів",
  "economy.payment_exports_label": "Експорт платежів для",
  "economy.payment_exports_label_2": " і підгрупи",
  "economy.payment_for": "Платіж за",
  "economy.payment_for_description": "Платіж відправляється 01.01.{{ year }}.",
  "economy.payment_for_description_2": "Гравці, які приєдналися протягом року, отримають платіж із 14-денним терміном.",
  "economy.payment_for_validation_message_max": "Можливо, ви не мали на увазі через {{ years }} років? :)",
  "economy.payment_for_validation_message_min": "Комісія не може бути створена за минулі роки.",
  "economy.payment_information": "Інформація про платіж",
  "economy.payment_is_mandatory_for_joining_the_group": "Цей платіж є обов'язковим для вступу в групу",
  "economy.payment_is_mandatory_for_joining_the_group_2": "Обов’язковий платіж не було вчасно сплачено",
  "economy.payment_is_mandatory_for_joining_the_group_description": "{{ name }} має сплатити цей платіж до дедлайну оплати, щоб підтвердити своє членство в {{ group }}. Інакше вони будуть видалені з групи.",
  "economy.payment_is_mandatory_for_joining_the_group_description_2": "{{ name }} не сплатив цей платіж до дедлайну оплати, тому їх було автоматично вилучено з {{ group }}.",
  "economy.payment_is_mandatory_for_joining_the_group_description_3": "Щоб надати користувачеві більше часу для оплати, ви можете подовжити дедлайн.",
  "economy.payment_is_mandatory_for_joining_the_group_description_4": "Якщо ви бажаєте залишити користувача в групі, ви можете змінити статус платежу на “Анульовано”.",
  "economy.payment_is_mandatory_for_new_players_joining": "\"{{ payment }}\" є обов’язковим для нових гравців, які приєднуються до цих груп",
  "economy.payment_is_mandatory_for_new_players_joining_description": "Нові гравці та ті, хто автоматично додається зі списку очікування груп, повинні будуть заплатити протягом 15 хвилин і 24 годин відповідно. Якщо оплата не буде здійснена протягом зазначеного часу, такі гравці будуть видалені з групи.",
  "economy.payment_note": "Коментар",
  "economy.payment_note_invoice_hint": "Коментар відображатиметься на рахунку-фактурі",
  "economy.payment_paid": "Оплачено",
  "economy.payment_publish_action_description": "Оплата буде опублікована о 12:00",
  "economy.payment_publish_date_tooltip": "Гравці, які пропустили термін підтвердження замовлення, отримують платежі в цей день.",
  "economy.payment_reminder": "Нагадування про оплату",
  "economy.payments": "Платежі",
  "economy.payments_i_created": "Створені мною платежі",
  "economy.payment_will_be_on_hold": "Оплата буде призупинена і може бути повторно опублікована в будь-який час.",
  "economy.payout": "Виплата",
  "economy.payouts_arrival_date_hint": "Для поточних виплат, це приблизно",
  "economy.pdf_payment_description": "Створює офіційний PDF-документ для цілей бухгалтерського обліку. Цей документ містить підтвердження всіх платежів і комісій на вибрану дату виплати.",
  "economy.pdf_payment_report": "(PDF) Звіт про оплату",
  "economy.period": "Період",
  "economy.period_end": "Дійсний до",
  "economy.period_end_hint": "Після цієї дати платежі більше не створюватимуться.",
  "economy.period_invalid": "Недійсний період",
  "economy.period_start": "Дійсний з",
  "economy.picked_up": "Забрано",
  "economy.pick_up": "Видача",
  "economy.pick-up": "Купити",
  "economy.pick_up_description": "Усім {{ number }} учасникам дугнаду зі статусом замовлення “не забрано” ми повідомимо час і місце, куди вони можуть приїхати забрати товар.",
  "economy.pick_up_description_2": "{{ number }} dugnad participants with “not picked up” order status received information about the date and place where they can come to pick up the products.",
  "economy.pick_up_end": "Кінець видачі",
  "economy.pick_up_location": "Місце видачі",
  "economy.pick_up_managers_description_1": "Виберіть людей, які відповідатимуть за видачу продуктів і матимуть право позначати замовлення як “забрані”.",
  "economy.pick_up_managers_description_2": "Щоб зареєструвати видані продукти, їм потрібно буде ввести код в мобільному додатку. Цей код надасть особа, яка забирає продукти.",
  "economy.pick_up_manager_warning": "Предмети самі собою не віддадуться. Будь ласка, призначте принаймні 1 особу, яка відповідатиме за це.",
  "economy.pick_up_reminder": "Нагадування про отримання",
  "economy.pick_up_reminder_history_description": "Нагадування надсилаються як електронні листи та push-сповіщення у додатку. Ви також можете надіслати нове нагадування вручну.",
  "economy.pick_up_start": "Початок видачі",
  "economy.pick_up_time": "Час видачі",
  "economy.pick_up_time_passed": "Pick up time passed",
  "economy.pick_up_time_passed_description": "You can’t edit pick up after pick up time has passed.",
  "economy.pick_up_was_successfully_set": "Видача створена",
  "economy.pick_up_was_successfully_updated": "Видача оновлена",
  "economy.player_fee_description": "Будь ласка, виберіть правило для стягнення плати за тренування для учасників, які беруть участь у кількох групах (усі інші збори за тренування будуть анульовані):",
  "economy.player_fee_label": "Плата для гравців у кількох групах",
  "economy.player_fee_placeholder": "Комісія, яку повинен сплатити гравець",
  "economy.previous_payment": "Останній платіж створено за {{ year }}",
  "economy.price": "Ціна",
  "economy.price_categories_description_1": "Встановити цінові категорії для членського внеску {{ organization }}.",
  "economy.price_categories_description_2": "Переконайтеся, що цінова категорія охоплює всіх користувачів.",
  "economy.price_categories_description_3": "Користувачі, які не охоплюються, не отримуватимуть членський внесок.",
  "economy.price_for_team": "Ціна для {{ team }}",
  "economy.price_option": "Ціновий варіант",
  "economy.price_options": "Цінові варіанти",
  "economy.price_per_item": "Ціна за шт",
  "economy.price_per_item_description": "Будь ласка, встановіть ціну за одиницю товару.",
  "economy.price_per_item_description_2": "Ви також можете встановити ціну викупу, якщо деякі користувачі не захочуть брати участь у дугнаді.",
  "economy.price_per_player": "Ціна на гравця",
  "economy.price_range": "Діапазон цін",
  "economy.prices_for_teams": "Ціни для кожної команди в {{ division_name }}",
  "economy.prices_for_teams_description_1": "Учасники з декількома платами за тренування повинні будуть заплатити найдешевшу.",
  "economy.prices_for_teams_description_2": "Учасники з декількома платами за тренування повинні будуть заплатити найдорожчу.",
  "economy.prices_for_teams_description_3": "Учасники з декількома платами за тренування повинні будуть сплатити всі внески.",
  "economy.prices_for_teams_required_to_join_description": "Новим гравцям, які приєднуються до груп, для яких ця оплата була позначена як обов’язкова, буде надано 15 хвилин для сплати цього платежу, щоб підтвердити своє членство. В іншому випадку вони будуть автоматично видалені.",
  "economy.price_user_will_see_and_pay": "Ціна, яку користувач побачить і заплатить",
  "economy.product_name_label": "Назва",
  "economy.products": "Продукти",
  "economy.products_description": "Додайте назву товару (наприклад, пачка туалетного паперу) і його ціну за одиницю.",
  "economy.products_description_1": "Шукаєте надійного постачальника та безпроблемну доставку? Замовляйте у наших партнерів, а ми подбаємо про все інше!",
  "economy.products_description_2": "Додаючи декілька товарів, ви дозволяєте гравцям вибирати кількість кожного з них, щоб їх сумма відповідала кількості товарів на гравця наведеній вище.",
  "economy.profit": "Прибуток",
  "economy.publish_date_description": "Платіж буде опубліковано о 12:00.",
  "economy.publish_date_mf_description": "О 12:00 буде опубліковано членський внесок.",
  "economy.publish_date_tf_description": "Вартість тренування буде опубліковано о 12:00.",
  "economy.publish_date_validation_message_min": "Дата публікації має бути в майбутньому.",
  "economy.received": "Отримано",
  "economy.refund": "Відшкодування",
  "economy.refund_amount": "Сума відшкодування",
  "economy.refunded_outside_hoopit": "Відшкодовано за межами Hoopit",
  "economy.refund_from_account": "Відшкодувати з рахунку",
  "economy.refund_payment_description_1": "Ви можете повернути повну суму на оригінальну картку, або лише її частину. Або позначити платіж як відшкодований за межами Hoopit, щоб забезпечити точні записи транзакцій.",
  "economy.refund_payment_description_2": "Зверніть увагу, що відшкодовані платежі неможливо опублікувати для повторної оплати.",
  "economy.refund_payment_description_3": "<b>{{user}}</b> та його опікуни отримають квитанцію електронною поштою, і їхнє членство залишатиметься дійсним навіть після повного повернення коштів.",
  "economy.refund_payment_description_4": "<b>{{user}}</b> та його опікуни отримають квитанцію електронною поштою.",
  "economy.refund_payment_description_5": "<b>{{user}}</b> та його опікуни отримають квитанцію електронною поштою і все одно зможуть брати участь у події навіть після повного повернення коштів.",
  "economy.refund_payment_title": "Повернення платежу для {{user}}",
  "economy.refunds": "Відшкодування",
  "economy.refund_to_card": "Відшкодувати на картку",
  "economy.remain_not_confirmed": "Залишаються “непідтвердженими”",
  "economy.reminder_explanatory_message": "Нагадування буде надіслано за день до закінчення терміну оплати, потім нагадування буде надіслано через 3, 5, 10 і 15 днів після закінчення терміну платежу. Нові гравці також отримають плату за тренування протягом 14 днів.",
  "economy.reminder_history_description_1": "Нагадування про підтвердження надсилаються автоматично за 2, 5 і 7 днів до закінчення терміну підтвердження та в саму дату.",
  "economy.reminder_history_description_2": "Нагадування про оплату надсилаються автоматично через 2, 5 і 7 днів після закінчення терміну оплати, а потім раз на тиждень.",
  "economy.reminder_history_description_3": "Ви також можете надіслати нагадування отримати товари.",
  "economy.reminder_history_description_4": "Усі нагадування надсилаються на електронну пошту та push-повідомленням в мобільному додатку.",
  "economy.reminders": "Нагадування",
  "economy.reminder_text": "Текст нагадування",
  "economy.reminder_type": "Тип нагадування",
  "economy.remove_dugnad_manager": "Видалити дугнад менеджера?",
  "economy.remove_dugnad_manager_description": "Після збереження змін, {{ manager }} більше не братиме участі в управлінні видачею  продуктів для “{{ dugnad }}” дугнаду. Ми повідомимо їх електронною поштою.",
  "economy.reports_unavailable": "Звіти поки що недоступні",
  "economy.republish_order_description": "Термін підтвердження ({{ date }}) ще не минув. Змінивши статус підтвердження назад на «не підтверджено», ви дозволите {{ name }} зробити замовлення самостійно.",
  "economy.republish_order_for": "Опублікувати замовлення для {{ name }} ще раз",
  "economy.requested_by_user": "За запитом користувача",
  "economy.required": "Обов'язково",
  "economy.require_players_to_preorder_items": "Дозвольте учасникам заплатити пізніше",
  "economy.require_players_to_preorder_items_description": "Учасники зможуть замовити товари після публікації дугнаду, та пізніше отримати та сплатити платіж.",
  "economy.require_players_to_preorder_items_hint": "Ви можете вказати кінцевий термін можливості зробити замовлення та час публікації платежів. Учасникам, які не встигли зробити замовлення вчасно, буде призначено мінімальну кількість товарів. Гравці, які приєднаються після кінцевого терміну замовлення, не будуть включені в цей дугнад.",
  "economy.role": "Роль",
  "economy.same_price_all_teams": "Чи всі команди повинні платити однакову ціну?",
  "economy.search_by_name_or_payment": "Пошук за назвою або оплатою",
  "economy.search_by_name_payment_invoice": "Пошук за назвою, платежем або номером рахунку",
  "economy.search_by_payment_name": "Пошук за назвою платежу",
  "economy.select_export_type": "Виберіть тип експорту",
  "economy.select_from_catalogue": "Обрати з каталогу",
  "economy.select_from_catalogue_description_1": "Щоб спростити доставку, ми рекомендуємо вибирати продукти лише від одного постачальника для кожної кампанії Dugnad.",
  "economy.select_from_catalogue_description_2": "Пізніше ви можете змінювати як назву продукту, так і ціну.",
  "economy.select_from_catalogue_description_3": "Для отримання детальної інформації про продукт, перегляньте",
  "economy.select_period": "Виберіть період",
  "economy.select_time_period": "Виберіть період часу",
  "economy.select_users": "Виберіть користувачів",
  "economy.select_users_label": "Виберіть, хто отримає платіж, натиснувши «Вибрати користувачів».",
  "economy.select_year": "Виберіть рік",
  "economy.send_after_delay": "Надіслати після затримки",
  "economy.send_immediately_after_joining": "Надсилати одразу після приєднання",
  "economy.set_age_groups": "Встановити вікові групи",
  "economy.set_pick_up": "Створити видачу",
  "economy.set_price": "Встановити ціну",
  "economy.set_price_user_will_receive": "Встановіть ціну, яку отримає користувач",
  "economy.set_to_buy_out": "Зобовʼязані “відкупитись”",
  "economy.sibling_#": "Брат чи сестра №",
  "economy.sibling_discount": "Знижка для братів і сестер",
  "economy.sibling_discounts_example": "Приклад знижки для братів і сестер",
  "economy.specify_month": "Вкажіть місяць",
  "economy.specify_reason": "Вказати причину",
  "economy.status_overview": "Детальний огляд статусів",
  "economy.stop_sending_after": "Припинити надсилати після",
  "economy.stop_sending_payment": "Припинити надсилання новим членам після",
  "economy.stop_sending_payment_description_1": "Ми не будемо надсилати цей платіж новим учасникам, які приєднаються після цієї дати.",
  "economy.stop_sending_payment_description_2": "Зверніть увагу, що ми все одно будемо створювати платежі для таких учасників, але вони будуть позначені як звільнені.",
  "economy.submit_categories": "Надіслати категорії",
  "economy.suggested_price": "Рекомендована ціна",
  "economy.sum": "Сума",
  "economy.sum_does_not_include_transaction_fee": "Сума не включає комісію за транзакцію",
  "economy.summary": "Підсумок",
  "economy.survey": "Опитування",
  "economy.survey_created": "Опитування створено",
  "economy.team": "команда",
  "economy.teams": "команди",
  "economy.tell_players_what_this_dugnad_is_about": "Розкажіть гравцям про що цей дугнад",
  "economy.tf_adjust_for_individual_groups": "Редагувати індивідуально",
  "economy.tf_default_net_amount": "Стандартна чиста сума",
  "economy.tf_default_price": "Стандартна ціна",
  "economy.tf_details_excluded_group_hint": "Члени цієї групи не отримували цю платіжку. Підгрупи все ще можуть бути включені.",
  "economy.tf_exclude": "Виключити",
  "economy.tf_excluded_groups": "Виключені групи",
  "economy.tf_excluded_groups_description": "Ці групи були позначені як виключені, тому їх гравцям не доведеться платити цей платіж.",
  "economy.tf_mandatory": "Обов’язковий",
  "economy.tf_override_mandatory_payment": "Override mandatory payment",
  "economy.tf_override_mandatory_payment_description": "Нові гравці, які приєднуються до цієї групи або її підгрупи, вже повинні сплатити інший обов’язковий платіж <b>{{ payment }}</b>, щоб підтвердити своє членство. Увімкнення цієї опції замінить той платіж на цей.",
  "economy.tf_summary_excluded_group_hint": "Члени цієї групи не отримають цю платіжку. Підгрупи все ще можуть бути включені.",
  "economy.this_payment_is_mandatory_tooltip": "Цей платіж є обов'язковим для вступу в групу.",
  "economy.title": "Назва",
  "economy.to_age_including": "До віку (в т.ч.і)",
  "economy.toilet_paper_pack": "Упаковка туалетного паперу",
  "economy.total": "Всього",
  "economy.total_description_tooltip": "Підсумки фільтруються за поточною вибраною групою та включають усі її підгрупи.",
  "economy.total_number_of_items": "Загальна кількість елементів",
  "economy.total_price_including_fees": "Загальна ціна з урахуванням комісії за транзакцію",
  "economy.total_price_to_buy_out": "Загальна ціна викупу",
  "economy.training_fee": "Плата за тренування",
  "economy.training_fee_no_permission_description": "Лише <b>менеджери фінансів</b> поточної групи, або її батьківських груп мають доступ до цієї сторінки.",
  "economy.training_fee_no_permission_header": "Фінанси дівізії",
  "economy.training_payments": "Оплата за тренування",
  "economy.transaction_fee": "Комісія за транзакцію",
  "economy.transferred": "Перенесено",
  "economy.under_age": "Молодше {{ age }}",
  "economy.upcoming": "Майбутні",
  "economy.update_age_group": "Оновити вікову групу",
  "economy.update_price_category": "Оновити цінову категорію",
  "economy.update_product": "Оновити товар",
  "economy.valid_for": "Дійсно для",
  "economy.who_is_the_price_for": "Для кого ціна?",
  "economy.will_be_published_description": "Платежі публікуються індивідуально після підтвердження замовлення або {{ publishUserPaymentsDate }} для людей, які пропустили термін підтвердження ({{ confirmationDueDate }}).",
  "economy.will_be_published_on": "Буде опубліковано на",
  "economy.you_can_add_any_person_from": "Ви можете додати будь-яку особу з",
  "event_details.account": "Обліковий запис",
  "event_details.activity_for": "діяльність для",
  "event_details.activity_type_description": "Створіть окрему подію для матчу, або для чогось більшого, або повторювану подію для тренувань або інших щотижневих подій.",
  "event_details.activity_type_description_2": " Одна діяльність має більше функцій, як оплата та кінцевий термін відповіді.",
  "event_details.add_meetup": "Додати зустріч",
  "event_details.add_participants_limit": "Додайте ліміт учасників",
  "event_details.add_payment": "Додати платіж",
  "event_details.add_reminder": "Додати нагадування",
  "event_details.add_reminders": "Додайте нагадування",
  "event_details.add_response_deadline": "Додати кінцевий термін відповіді",
  "event_details.all_activities": "Всі повторювані активності",
  "event_details.automatically_register": "Автоматичниа реєстрація",
  "event_details.automatically_register_description": "Усі запрошені автоматично реєструються на участь. Ми нагадаємо їм, щоб вони можуть відмовитися, якщо вони не можуть бути присутніми!",
  "event_details.bi_weekly": "через тиждень",
  "event_details.cancel_activity": "Скасувати активність",
  "event_details.cancel_choice": "Що ти хочеш робити?",
  "event_details.cancel_description": "Скасуйте подію, якщо вона більше не відбувається. Учасники будуть повідомлені.",
  "event_details.cancelled_reason": "Причина скасування",
  "event_details.cancelled_reason_placeholder": "Чому активність скасована?",
  "event_details.cancel_payment_description": "Увага, автоматичне повернення коштів за скасування оплачених активностей не передбачено.",
  "event_details.change": "Змінити",
  "event_details.change_activity_for_group": "Змінити активність для {{ groupName }}",
  "event_details.change_stop_repetition": "Якщо ви хочете завершити активність раніше, ви можете змінити дату <strong>Зупинити повторення</strong>",
  "event_details.choose_activity_type": "2. Виберіть вид активності",
  "event_details.choose_a_group": "1. Виберіть групу",
  "event_details.choose_a_group_description": "Виберіть групу, для якої хочете створити активність.",
  "event_details.clear_for_this_occurrence": "Прибрати для цієї події",
  "event_details.clear_location_or_reset_to_default": "Прибрати локацію або замінити на дефолтну ",
  "event_details.clear_location_or_reset_to_default_description": "Ви бажаєте повністю прибрати локацію для цієї конкретної події {{ eventTitle }}, чи замінити на ту, яка вказана для всієї події {{ location }}?\nПісля завершення редагування не забудьте натиснути на кнопку «Зберегти».",
  "event_details.contact_persons_required": "Подія повинна мати принаймні одну і не більше трьох контактних осіб.",
  "event_details.copy_event_button": "Дуплікувати",
  "event_details.custom_location": "Інша локація",
  "event_details.deadline_subtext_2": "Ми нагадаємо запрошеному користувачеві за 24 години до встановленого терміну відповіді.",
  "event_details.delete_activity": "Видалити активність",
  "event_details.delete_description": "Коли ви видалите подію, вона зникне назавжди. Ніхто не буде повідомлений.",
  "event_details.delete_recurring": "Видалити повторювану подію",
  "event_details.delete_recurring_all": "Минулі та майбутні події буде видалено.",
  "event_details.delete_recurring_description": "Видалення всіх активностей призведе до видалення всіх активностей у минулому та майбутньому.",
  "event_details.delete_recurring_future": "Цю та майбутні події буде видалено.",
  "event_details.delete_recurring_single": "Зміни не будуть застасованы для минулих та майбутніх подій.",
  "event_details.delete_reminders": "Видалити нагадування",
  "event_details.description": "Опис",
  "event_details.end": "Кінець",
  "event_details.event_cannot_be_deleted_tooltip": "Події з платежами неможливо видалити",
  "event_details.event_details": "Деталі події",
  "event_details.event_is_imported_from_excel": "Подію імпортовано з Excel",
  "event_details.event_is_imported_from_fotballdata": "Подію імпортовано з Fotballdata",
  "event_details.event_is_imported_from_nif": "Подію імпортовано з NIF",
  "event_details.footballdata_button": "Побачити більше на NFF",
  "event_details.future_activities": "Цю і майбутні",
  "event_details.invitations": "запрошення",
  "event_details.last_manual_reminder": "Останнє нагадування",
  "event_details.last_manual_reminder_counter": "({{x}} тому)",
  "event_details.limit": "Ліміт",
  "event_details.limit_members_invalid": "Встановіть будь-яке число більше або рівне 0",
  "event_details.location": "Локація",
  "event_details.manual_register": "Ручна реєстрація",
  "event_details.manual_register_description": "Всі запрошені повинні давати свою відповідь на кожну подію. Ми їм нагадаємо!",
  "event_details.match": "Матч",
  "event_details.match_description": "Позначає подію як матч в календарі та статистиці відвідуваності.",
  "event_details.meetup_location": "Місце зустрічі",
  "event_details.meetup_location_subtext": "Якщо ви бажаєте інше місце зустрічі",
  "event_details.meetup_time": "Час зустрічі",
  "event_details.meetup_time_subtext": "Перед початком події",
  "event_details.monthly": "кожного місяця",
  "event_details.new": "Новий",
  "event_details.no_posts_description": "Створюйте публікації, щоб ділитися оновленнями, ключовою інформацією або просто залишатися на зв'язку.",
  "event_details.no_posts_title": "Пости відсутні",
  "event_details.only_this": "Тільки цю",
  "event_details.participants": "учасників",
  "event_details.participants_limit": "Ліміт гравців",
  "event_details.participants_limit_helper_text": "Коли ліміт досягнуто, запрошені не можуть прийняти запрошення.",
  "event_details.participants_limit_hint": "Кількість запрошених, які прийняли запрошення, перевищує ліміт учасників. Ви можете керувати відповідями за допомогою параметра Змінити відповіді.",
  "event_details.payment": "Платіж",
  "event_details.payment_future_warning": "Платіж не можна додати після початку події.",
  "event_details.payment_subtext": "Щоб додати банківський рахунок, зверніться до свого адміністратора Hoopit у вашому клубі",
  "event_details.payment_warning": "Оплату неможливо змінити після початку події.",
  "event_details.price": "Ціна",
  "event_details.recurrence": "Повторюваність",
  "event_details.recurring": "Повторювана",
  "event_details.recurring_event": "Це повторювана подія",
  "event_details.remind_unanswered_description": "Ми надішлемо push-сповіщення вибраним користувачам та їхнім опікунам, нагадуючи їм відповісти на запрошення на подію.",
  "event_details.remind_unanswered_title": "Надішліть нагадування тим, хто ще не відповів",
  "event_details.repeating_activity": "повторення",
  "event_details.reset_location": "Замінити локацію",
  "event_details.save_and_notify_members_about_changes": "Зберегти та повідомити учасників про зміни?",
  "event_details.select_contact_persons": "Виберіть контактну особу",
  "event_details.select_location": "Виберіть розташування",
  "event_details.send_reminders": "Надіслати нагадування",
  "event_details.set_custom_location": "Встановити іншу локацію",
  "event_details.single": "Одноразова",
  "event_details.single_activity": "єдиний",
  "event_details.start": "Початок",
  "event_details.stop_recurrence": "Зупинити повторювання",
  "event_details.this_and_future_occurrences": "Цю та всі майбутні події",
  "event_details.this_is_a_recurring_activity": "Це повторювана активність",
  "event_details.this_is_a_recurring_event": "Це повторювана подія",
  "event_details.this_is_match": "Матч",
  "event_details.title": "Назва",
  "event_details.update_only_this_or_all": "Хочете оновити лише цю конкретну подію, чи цю і всі майбутні події?",
  "event_details.update_stop_repetition_date": "Хочете оновити дату зупинки повторення для {{ eventName }}?",
  "event_details.user_notified": "Учасники будуть повідомлені.",
  "event_details.user_not_notified": "Ніхто не буде повідомлений.",
  "event_details.weekly": "щотижня",
  "event_details.we_will_notify_members_about_cancellation": "Ми повідомимо запрошених учасників про скасування.",
  "event_details.you_can_notify_members_about_changes": "Ви можете повідомити учасників про зміни.",
  "events.10_minutes": "10 хвилин",
  "events.15_minutes": "15 хвилин",
  "events.1_hour": "1 година",
  "events.1_hour_30_minutes": "1 година 30 хвилин",
  "events.1_venue_with_conflicts": "{{ number }} локація має конфлікти",
  "events.2_hours": "2 години",
  "events.30_minutes": "30 хвилин",
  "events.45_minutes": "45 хвилин",
  "events.5_minutes": "5 хвилин",
  "events.activity_cancelled": "Активність скасовано",
  "events.add_attendance_note": "Додати примітку",
  "events.add_bank_account": "Зверніться до адміністратора вашої організації, щоб додати банківський рахунок",
  "events.add_event": "Додати подію",
  "events.all": "Всі",
  "events.all_venues": "Усі локації",
  "events.attendance": "Присутність",
  "events.attendance_locks_responses_description": "Учасники не зможуть міняти свої відповіді після того, як присутність була зареєстрована.",
  "events.attendance_locks_responses_header": "Реєстрація присутності заблокує усі відповіді",
  "events.attendance_registered": "Присутність зареєстровано",
  "events.attendance_updated": "Присутність оновлено",
  "events.attended": "Відвідував",
  "events.attending": "Відвідування",
  "events.automatic_registration": "Автоматична реєстрація",
  "events.auto_reg_on": "Опція недоступна для подій з автоматичною реєстрацією",
  "events.before": "до",
  "events.before_start": "перед стартом",
  "events.booked": "Заброньовано",
  "events.book_venue": "Заброньювати локацію",
  "events.calendar_is_not_public": "Календар не є публічним",
  "events.calendar_sharing_permission_message": "Керівництво клубу повинно дозволити ділитися публічним календарем.",
  "events.cancel_event": "Скасувати або видалити подію",
  "events.cancel_event_button": "Скасувати подію",
  "events.cancel_event_description_1": "Скасуйте <b>{{event}}</b>, якщо подія більше не відбувається. За потреби ви можете відновити її пізніше.",
  "events.cancel_event_description_2": "Ми повідомимо учасників про скасування.",
  "events.cancel_event_title": "Скасувати подію?",
  "events.change_attendance_description": "Поточна присутність {{ userName }} -",
  "events.change_event": "Змінити подію для {{ groupName }}",
  "events.change_occurrence": "Змінити 1 подію для {{ groupName }}",
  "events.change_response": "Змінити відповідь",
  "events.change_response_description": "Поточна відповідь {{ userName }} –",
  "events.change_response_description_2": "Будь ласка, вкажіть правильну відповідь.",
  "events.change_responses": "Змінити відповіді",
  "events.changes_apply_text": "Зміни можуть бути застосовані не відразу",
  "events.change_to": "Змінити на {{ availableStatus }}",
  "events.change_venue": "Змінити локацію",
  "events.checking_for_venue_conflicts": "Перевіряємо локації на конфлікти",
  "events.conflicting_events_empty_state_description": "Щоб перевірити наявність конфліктуючих подій, оберіть локацію події в списку вище.",
  "events.conflicting_events_sub_header": "{{ number }} конфліктуючих подій на {{ venue }}",
  "events.create_event": "Створити подію",
  "events.create_event_description": "Створюйте окремі події для матчів чи інших одноразових заходів і повторювані події для тренувань чи інших регулярних заходів.",
  "events.create_events": "Створіть {{ createCount }} подій",
  "events.create_events_success": "{{ createCount }} подій успішно створено.",
  "events.create_event_without_participants": "Створити подію без учасників",
  "events.create_post_text": "Будь першим! Створіть публікацію за допомогою кнопки «Нова публікація».",
  "events.current_occurrence": "Лише поточна подія",
  "events.days_before": "Днів до",
  "events.days_in_advance": "Днів перед кажною подією",
  "events.deadline": "Дедлайн",
  "events.declined": "Відхилено",
  "events.delete_event_button": "Видалити подію",
  "events.delete_event_confirmation": "Видалити подію?",
  "events.delete_event_confirmation_message": "Видаліть <b>{{ event }}</b>, якщо ви хочете остаточно прибрати подію з календарів групи та користувачів. Зауважте, що учасники <em>не отримають</em> сповіщення.",
  "events.delete_event_confirmation_message_1": "Цю дію не можна скасувати.",
  "events.did_not_attend": "Не відвідував",
  "events.edit_activity": "Змінити активність",
  "events.edit_attendance": "Редагувати присутність",
  "events.event": "Подія",
  "events.event_cancelled": "Подію “{{ eventName }}” скасовано",
  "events.event_cancelled_text": "Подію скасовано",
  "events.event_contact_persons": "Контактна особа",
  "events.event_created": "Подію «{{ eventName }}» успішно створено",
  "events.event_creator": "Творець події",
  "events.event_deleted": "Подію «{{ eventName }}» було видалено",
  "events.event_end": "Кінець події",
  "events.event_information": "Інформація про подію",
  "events.event_moved_from_all_day": "Подію «{{ eventName }}» успішно перенесено та спочатку буде встановлено на 1 годину",
  "events.event_moved_to_all_day": "Подію «{{ eventName }}» успішно перенесено. Спочатку її початок о 00:00 і завершення о 23:59",
  "events.event_move_from_future_to_past_error": "Ви не можете перемістити подію у минуле.",
  "events.event_name": "Назва події",
  "events.event_start": "Початок події",
  "events.event_started": "Подія розпочалась",
  "events.event_started_description": "Ви не можете редагувати відповіді після початку події.",
  "events.event_started_or_ended_error": "Ви не можете редагувати подію, яка вже почалася або закінчилася",
  "events.event_tags_list": "Список тегів, вибраних для цієї події",
  "events.event_updated": "Подію «{{ eventName }}» успішно оновлено",
  "events.event_without_participants": "Подія без учасників",
  "events.every": "Кожен",
  "events.every_month": "Повторювати кожен місяць",
  "events.every_other": "Кожен інший",
  "events.every_other_week": "Повторюйте через тиждень",
  "events.every_week": "Повторювати щотижня",
  "events.excel_example_description": "Тут ви можете побачити приклад аркуша Excel із правильною інформацією про відповідність.",
  "events.excel_example_description_2": "Зверніть увагу, що всі необхідні стовпці, а саме <b>Dato</b>, <b>Tid</b>, <b>Kampnr</b>, <b>Hjemmelag</b> і <b>Bortelag< Для успішного створення подій потрібно заповнити /b>.",
  "events.excel_example_header": "Приклад аркуша Excel",
  "events.excel_import": "Імпорт Excel",
  "events.external_event": "Зовнішня подія",
  "events.external_group": "Зовнішня група",
  "events.for_group": "для {{ group }}?",
  "events.fotballdata": "Fotballdata",
  "events.fotballdata_description": "Підтримуйте календар своєї команди в актуальному стані, імпортуючи та синхронізуючи всі матчі з FIKS.",
  "events.going": "Йде",
  "events.group_activities_description": "Тут ви бачите всі активності групи, членом якої ви є, і всіх підгруп, які до неї належать. Якщо вам цікаво, як створити або змінити дію, зайдіть всередину",
  "events.groups": "Групи",
  "events.groups_in": "Групи в {{ organization_name }}",
  "events.here": "тут",
  "events.hide_calendar": "Приховати календар",
  "events.hours_before": "Годин до",
  "events.illness": "Захворювання",
  "events.import": "Імпорт",
  "events.imported_from": "Імпортовано з",
  "events.import_events": "Імпорт подій",
  "events.import_events_confirmation_description": "Ви зможете запросити учасників пізніше з деталей події. Усі імпортовані події оновлюватимуться залежно від <b>Kampnr</b>.",
  "events.import_events_description": "Імпортуйте свій аркуш Excel і потім перевірте імпортовані дані. Ви зможете редагувати інформацію про події та запрошувати учасників після створення.",
  "events.import_events_description2": "Імпортувати з <a href=\"http://handball.no/\" target=\"_blank\">handball.no</a><br/><a href=\"https://www.handball.no/system/ sok/?search=&reg=all&content=klubb\" target=\"_blank\">Тут</a> ви можете шукати свою команду. Виберіть правильну команду, а потім «Список терміналів», щоб завантажити таблицю Excel із усіма збігами.",
  "events.import_events_is_match": "Імпортовані події будуть позначені як <b>“матчі”</b> на календарі та статистиці відвідуваності.",
  "events.import_excel": "Імпорт файлу Excel",
  "events.import_matches": "Імпорт матчів",
  "events.import_matches_description": "Вручну імпортуйте матчі з Excel таблиці. Усі імпортовані матчі будуть створені як одноразові події.",
  "events.injury": "Травма",
  "events.invitation_schedule": "Розклад запрошень",
  "events.invite_current_occurrence": "Запросити лише на поточну подію чи на цю та всі майбутні події?",
  "events.invited_description_text": "Усі запрошені автоматично налаштовані на участь. Ми нагадаємо їм відмовитися, якщо вони не зможуть бути присутніми!",
  "events.invited_to_occurrence_text": "Всі запрошені повинні реагувати на кожну подію. Ми їм нагадаємо!",
  "events.invite_more": "Запросити ще",
  "events.invite_participants": "Запросити учасників",
  "events.invite_to_recurring": "Запросити лише на цю активність чи на цю й усі повторювані активності?",
  "events.location_section_name": "Cекція {{ sectionName }} ({{ numberOfTakenSections }}/{{ numberOfSiblingSections }} поля)",
  "events.luxsave_sync_up_duration": "Синхронізація LuxSave може тривати до 30 хвилин",
  "events.manage_attendance": "Керувати відвідуваністю",
  "events.manual_registration": "Ручна реєстрація",
  "events.many_days_in_advance": "За {{ count }} днів",
  "events.match": "Матч",
  "events.meet_up": "Зустріч",
  "events.minutes_before": "Хвилин до",
  "events.more_options": "Більше опцій",
  "events.must_be_before_start": "Має бути перед початком",
  "events.must_be_between_days": "Має бути від 1 до 28 днів",
  "events.must_be_between_hours": "Має бути від 1 до 672 годин",
  "events.must_be_between_minutes": "Має бути від 1 до 40 320 хвилин",
  "events.must_be_between_weeks": "Має бути від 1 до 4 тижнів",
  "events.must_be_in_future": "Має бути в майбутньому",
  "events.never_repeat": "Ніколи",
  "events.new_activity": "Нова активність",
  "events.new_event": "Нова подія",
  "events.new_event_description": "Виберіть групу, для якої буде створено цю подію. Ви також можете змінити тип події: використовувати <b>одиночна</b> для матчів або інших одиночних подій і <b>повторювані</b> для тренувань або інших регулярних заходів.",
  "events.new_event_description_2": "Не забудьте заповнити назву та опис, щоб допомогти всім зрозуміти, про що ця подія.",
  "events.no_activity": "Немає активностей за вибраний період",
  "events.no_attendance_status": "Без статусу відвідуваності",
  "events.no_conflicting_events_found": "Чудово! Жодної конфліктуючої події не знайдено",
  "events.no_locations_registered": "У вас немає зареєстрованих локацій.",
  "events.no_meetup_is_added": "Немає доданої зустрічі",
  "events.noone_invited_text": "Ви нікого не запросили на подію “{{ eventName }}”. Ви можете зробити це зараз або запросити учасників пізніше з деталей події.",
  "events.no_payments": "Немає платежів для просмотру",
  "events.no_posts": "Немає публікацій",
  "events.no_response_deadline_is_added": "Немає доданого дедлайну",
  "events.no_results": "Немає результатів",
  "events.not_answered": "Без відповіді",
  "events.not_going": "Не йде",
  "events.nothing_found": "Нічого не знайдено",
  "events.not_registered": "не зареєстровано",
  "events.number_venues_with_conflicts": "{{ number }} локацій мають конфлікти",
  "events.one_day_in_advance": "За {{ count }} день",
  "events.only_this": "Тільки цю",
  "events.organization_venues_description": "Тут ви знайдете огляд усіх локацій, доступних вашій організації. Тут ви зможете побачити, коли вони можуть бути доступні, а коли ні.",
  "events.other_groups": "Інші групи",
  "events.other_reason": "Інша причина",
  "events.other_training": "Інше тренування",
  "events.participant_info": "Інформація про учасника",
  "events.participants": "Учасники",
  "events.payment_cant_be_changed": "Оплату неможливо змінити після початку події.",
  "events.place": "Місце",
  "events.posts_in_activity": "Публікації, пов'язані з активністю",
  "events.press_enter_to_set_custom_location": "Натисніть Enter, щоб встановити «{{ venueSearchValue }}» як іншу локацію",
  "events.reason": "Причина",
  "events.recurrence": "Повторення",
  "events.recurring_activity": "Це повторювана активність",
  "events.register_attendance": "Зареєструвати присутність",
  "events.reminder": "Нагадування",
  "events.reminder_cannot_be_in_past": "Нагадування не може бути у минулому",
  "events.remove_from_event": "Видалити з події",
  "events.remove_from_event_description": "{{ userName }} буде видалено з події. Ви можете повторно запросити їх у будь-який час.",
  "events.remove_from_event_question": "Ви впевнені, що хочете видалити {{ first_name }} {{ last_name }} із події?",
  "events.remove_from_past_event": "Ви впевнені, що хочете видалити {{ userName }} із події?",
  "events.reopen_event": "Відновити подію?",
  "events.reopen_event_description": "Повторне відкриття <b>{{ event }}</b> знову зробить подію доступною для участі. Зауважте, що учасники не отримають сповіщення.",
  "events.response": "Відповідь",
  "events.response_deadline": "Дедлайн відповіді",
  "events.response_deadline_helper_text": "Учасники не можуть змінити свою відповідь після дедлайну.",
  "events.response_updated": "Відповідь оновлено.",
  "events.roles": "Ролі",
  "events.scheduled_invitation": "Додати заплановане запрошення до події",
  "events.schedule_immediately": "Відразу",
  "events.schedule_immediately_description": "Запрошення до події буде надіслано відразу",
  "events.schedule_on_date": "У дату",
  "events.schedule_on_date_description": "Запрошення буде надіслано в указану вами дату, яку ви зможете вказати",
  "events.season_planner": "Планувальник сезону",
  "events.season_planner_description": "Переконайтеся, що календар вашої команди залишається оновленим і впорядкованим за допомогою автоматичного імпорту та синхронізації.",
  "events.section": "Секція",
  "events.section_part": "Секція",
  "events.sections_overlap_error": "Кілька подій не можуть відбуватися в одному розділі одночасно",
  "events.sections_overlap_warning": "Кілька подій відбуваються на одній і тій же секції одночасно",
  "events.see_paid_payments": "Переглянути оплачені платежі",
  "events.see_payments": "Переглянути платежі",
  "events.select_date": "Виберіть дату",
  "events.select_group_description": "Виберіть групу, для якої імпортуватимуться події. Пам’ятайте, що всі імпортовані збіги будуть створені як окремі події.",
  "events.select_time": "Виберіть час",
  "events.select_venue": "Оберіть локацію",
  "events.select_venues": "Оберіть локації",
  "events.select_venue_to_load_events": "Виберіть локацію для завантаження подій",
  "events.share_calendar": "Поділитися календарем",
  "events.share_public_calendar": "Поділитися публічним календарем",
  "events.share_specific_venues": "Поділитися лише конкретними локаціями",
  "events.sharing_calendar_description": "Надавши спільний доступ до календаря, люди, які не входять до спортивної команди, можуть отримати огляд її діяльності.",
  "events.sharing_calendar_description_2": "Ви також можете звузити список локацій у публічному календарі до декількох, вибравши їх нижче.",
  "events.sharing_calendar_embed": "Інтегрувати",
  "events.sharing_calendar_embed_description": "Використовуйте посилання iframe, щоб інтегрувати календар місць проведення подій на веб-сайт вашої організації.",
  "events.sharing_calendar_ical_ics": "iCAL/iCS",
  "events.sharing_calendar_ical_ics_description": "Використовуйте посилання iCAL/iCS щоб отримати доступ до календаря вибраної локації з інших програм. Цей календар оновлюється кожні 8-24 години.",
  "events.sharing_calendar_public_link": "Відкрите посилання",
  "events.sharing_calendar_public_link_description": "Використовуйте відкритим посилання, щоб дозволити людям, які не входять до спортивної команди, отримувати огляд її діяльності.",
  "events.show_calendar": "Показати календар",
  "events.specify_reason": "Вкажіть причину",
  "events.start": "Початок",
  "events.subgroups": "Підгрупи",
  "events.team": "Команда",
  "events.this_future_occurrences": "Це та всі майбутні події",
  "events.th_of_every_month": "число кожного місяця",
  "events.time": "Час",
  "events.toggle_color": "Сортування за кольорами локацій.",
  "events.toggle_group": "Фільтр за групою",
  "events.turnerings_admin": "NIFs TurneringAdmin",
  "events.turnerings_admin_description": "Легко імпортуйте та синхронізуйте свої події NIF з Hoopit, заощаджуючи час і зусилля.",
  "events.update_events": "і оновіть {{ updateCount }} подій",
  "events.update_events_success": "{{ updateCount }} подій успішно оновлено.",
  "events.updating": "Оновлення...",
  "events.user_was_removed": "{{ userName }} видалено з події.",
  "events.venue": "Локація",
  "events.venue_is_booked": "Ця локація вже заброньована",
  "events.venue_is_booked_description": "{{ location }} вже заброньована іншою командою на цей час. Чи хочете ви все одно заброньювати цю локацію?",
  "events.venue_is_booked_description_recurring": "{{ location }} вже заброньована іншою командою на цей час на {{ conflictsNumber }} з {{ totalOccurrences }} подій. Чи хочете ви все одно заброньювати цю локацію?",
  "events.venues": "Локації",
  "events.waiting_list": "Лист очікування",
  "events.weeks_before": "Тижнів до",
  "events.with_football_data": "Ця подія імпортується з Footballdata",
  "events.your_groups": "Ваші групи",
  "expense_claims.account_number": "Номер",
  "expense_claims.all": "Усі",
  "expense_claims.approve_claim_description_1": "Перед схваленням перевірте належність витрат: квитанції, вартість, дати, категорії та описи.",
  "expense_claims.approve_claim_description_2": "Після схвалення заявку неможливо відхилити чи відредагувати. Ми створимо ваучер заявки і надішлемо його в систему бухгалтерського обліку.",
  "expense_claims.approve_claim_description_3": "Виберіть банківський рахунок для відшкодування. В іншому випадку у ваучері не буде вказано джерело коштів для відшкодування.",
  "expense_claims.approve_claim_description_4": "Однак, через відсутність банківського рахунку для витрат у вибраній групі, у ваучері не буде вказано джерело коштів для відшкодування.",
  "expense_claims.approve_claim_description_5": "Після схвалення заявку неможливо відхилити чи відредагувати. Ми створимо ваучер заявки і надішлемо його в систему бухгалтерського обліку.",
  "expense_claims.approve_claim_header": "Схвалити заявку?",
  "expense_claims.approved": "Підтверджено",
  "expense_claims.approved_by": "Схвали(в/ла)",
  "expense_claims.cannot_approve_claims_description": "Ви не можете схвалювати власні заявки. Будь ласка, зв'яжіться з фінансовими менеджерами Вашого клубу.",
  "expense_claims.category": "Категорія",
  "expense_claims.change_group_description_1": "Наразі заявку призначено до <b>{{ group }}</b>.",
  "expense_claims.change_group_description_2": "Якщо ви переназначите її до групи, до якої у вас немає доступу, заявка більше не відображатиметься для вас.",
  "expense_claims.change_group_header": "Переназначити заявку до іншої групи?",
  "expense_claims.change_group_success": "Заявку переназначено до {{ group }}",
  "expense_claims.change_project_header": "Змінити проект?",
  "expense_claims.claim_approved_success": "Заявку схвалено. Ваучер в процесі створення.",
  "expense_claims.claim_details": "Деталі заявки",
  "expense_claims.claim_marked_as_paid_success": "Заявка позначена як оплачена",
  "expense_claims.claim_name": "Назва заявки",
  "expense_claims.claim_overdue_warning": "Заявку було подано понад 2 дні тому",
  "expense_claims.claim_overdue_warning_description": "Будь ласка, перегляньте та схваліть або відхиліть цю заявку.",
  "expense_claims.claim_rejected_success": "Заявку відхилено",
  "expense_claims.details": "Деталі",
  "expense_claims.doesnt_support_expenses": "Не підтримує відшкодування",
  "expense_claims.empty_state_description": "Лише <b>менеджери фінансів</b> поточної групи, або її батьківських груп мають доступ до цієї сторінки.",
  "expense_claims.empty_state_header": "Відшкодування витрат",
  "expense_claims.expense_description": "Опис",
  "expense_claims.expense_description_hint_1": "Необхідно для категорії \"{{ category }}\"",
  "expense_claims.expense_description_hint_2": "Необов'язково",
  "expense_claims.expenses": "Витрати",
  "expense_claims.expense_updated_success": "Витрату оновлено",
  "expense_claims.group_doesnt_support_expenses": "Група не підтримує відшкодування",
  "expense_claims.group_doesnt_support_expenses_description": "Будь ласка, повідомте фінансових менеджерів Вашого клубу, що відшкодування не підтримуються цією групою.",
  "expense_claims.mark_as_paid": "Позначити оплачено",
  "expense_claims.mark_claim_as_paid_description_1": "Цим ви підтверджуєте, що відшкодування було сплачено вказаному отримувачу.",
  "expense_claims.mark_claim_as_paid_description_2": "Створювач заявки отримає сповіщення.",
  "expense_claims.mark_claim_as_paid_header": "Позначити заявку як оплачену?",
  "expense_claims.marked_as_paid": "Позначено як оплачено",
  "expense_claims.marked_as_paid_by": "Позначи(в/ла) як оплачено",
  "expense_claims.no_account_connected": "Немає підключеного аккаунта",
  "expense_claims.no_account_connected_description": "Група не має банківських рахунків, доступних для відшкодування.",
  "expense_claims.note_from_receiver": "Примітка від створювача заявки",
  "expense_claims.no_voucher": "Немає ваучера",
  "expense_claims.pay_from": "Оплатити (звідки)",
  "expense_claims.pay_to": "Оплатити (куди)",
  "expense_claims.previously_rejected_by": "Попередньо відхили(в/ла)",
  "expense_claims.project_is_not_added": "Проект не додано",
  "expense_claims.project_updated_success": "Проект оновлено",
  "expense_claims.reason": "Причина",
  "expense_claims.receiver": "Отримувач",
  "expense_claims.reject_claim_description_1": "Щоб відхилити заявку, вкажіть причину, щоб створювач заявки міг зрозуміти проблеми та вирішити їх.",
  "expense_claims.reject_claim_description_2": "Ви не зможете побачити відхилену заявку, доки її не буде повторно подано.",
  "expense_claims.reject_claim_header": "Відхилити заявку?",
  "expense_claims.rejected": "Відхилено",
  "expense_claims.search_by_description": "Шукайте за отримувачем, або ваучером",
  "expense_claims.should_not_be_reimbursed": "Не потребує відшкодування",
  "expense_claims.submitted": "Надано",
  "expense_claims.submitted_by": "Пода(в/ла)",
  "expense_claims.the_voucher_is_being_generated": "Ваучер в процесі створення",
  "expense_claims.update_expense_description_1": "Виберіть категорію, яка найбільше підходить для цієї витрати.",
  "expense_claims.update_expense_description_2": "Зауважте, що категорії «Соціальне», «Подорож» та «Інше» потребують опису.",
  "expense_claims.update_expense_header": "Оновити витрату?",
  "football_data.add": "Додати",
  "football_data.add_more_fix_id": "Додайте більше fixID сюди",
  "football_data.all": "Всі",
  "football_data.enter_path_to_register": "Введіть шлях, який ви хочете зареєструвати, і скопіюйте FixID з адресного рядка",
  "football_data.find_team": "Знайдіть свою команду за адресою",
  "football_data.fix_id": "fiksID",
  "football_data.fix_id_blank_error": "FixId не може бути пустим",
  "football_data.fix_id_ellipsis": "FiksID ...",
  "football_data.football_data": "Footballdata",
  "football_data.get_fix_id": "і отримайте свій fixID в адресному рядку.",
  "football_data.groups": "Групи",
  "football_data.hired": "(Здано в оренду)",
  "football_data.hoopit": "Hoopit",
  "football_data.import_campaign_data": "Імпорт даних матчів",
  "football_data.managed_by": "(керується {{ group_name }}) *",
  "football_data.matched_data": "Підібрані дані",
  "football_data.no_venues_registered": "У вас немає закладів, зареєстрованих у Hoopit, які можна підключити до FotballData. Перейти до",
  "football_data.oops": "Упс...",
  "football_data.organization": "організація",
  "football_data.search_club": "Знайдіть свій клуб на сайті fotball.no",
  "football_data.share": "Поділітися",
  "football_data.to_add_venues": "додати локацій.",
  "football_data.total_registered": "{{ total }} зареєстровано",
  "football_data.venue": "Локація",
  "football_data.venues": "Локації",
  "football_data.your_venues": "Ваші локації",
  "form.confirmation_due_date_min_error": "Дата має бути у майбутньому та не раніше дати публікації замовлення",
  "form.description": "Опис",
  "form.field_future_date": "Дата платежу має бути в минулому",
  "form.field_invalid_date": "Введіть дійсну дату",
  "form.field_required": "Це поле є обов'язковим",
  "form.from": "Від",
  "form.invalid_email": "Некорректна адреса",
  "form.to": "До",
  "gender.female": "Жінка",
  "gender.male": "Чоловік",
  "gender.unknown": "Невідомо",
  "group.": "Група",
  "group.add_subgroup": "Додати підгрупу",
  "group.associate_members": "Асоційовані учасники",
  "group.guardians": "Батьки",
  "group.include_subgroups": "Включати підгрупи",
  "group.leaders": "Лідери",
  "group.members": "Учасники",
  "group.name": "Ім'я",
  "group.others": "іІнші",
  "group.parents": "Батьки",
  "group.players": "Гравці",
  "group_settings.account_active": "Активний",
  "group_settings.account_change_warning_message": "Лише фінансовий директор має доступ до зміни рахунку.",
  "group_settings.account_inactive": "Неактивний",
  "group_settings.account_information": "Інформація про рахунок",
  "group_settings.accounting_email": "Електронна пошта бухгалтерії",
  "group_settings.accounting_email_description": "Додайте адресу електронної пошти вашої бухгалтерської системи, куди ми будемо надсилати ваучери від оплат та відшкодування, а також документацію клубної кредитної картки.",
  "group_settings.accounting_email_hint": "Як правило, цю поле слід заповнювати лише для групи організації та залишати порожнім для всіх інших груп. Але якщо вам потрібно надіслати платіжні ваучери, ваучери на відшкодування та документацію кредитної картки лише з цієї групи в іншу бухгалтерську систему, ви можете змінити налаштування, вписавши тут іншу електронну адресу. Переконайтеся, що налаштування банківського рахунку цієї групи також є зовнішніми для цієї організації, щоб уникнути помилок із узгодженням банківського рахунку.",
  "group_settings.accounting_email_hint_1": "Якщо для цієї групи не вказано електронну адресу бухгалтерської системи, ми автоматично використовуватимемо електронну адресу, надану в групі організації ({{ default }}).",
  "group_settings.accounting_email_hint_2": "Якщо для цієї групи не вказано електронну адресу бухгалтерської системи, ми автоматично використовуватимемо електронну адресу, надану в групі організації (наразі вона не додана).",
  "group_settings.account_name": "Ім'я рахунку",
  "group_settings.account_number": "Номер рахунку",
  "group_settings.account_number_validation_message": "Номер рахунку має складатися з 11 цифр",
  "group_settings.account_status": "Статус рахунку",
  "group_settings.activate_waiting_list": "Активувати лист очікування",
  "group_settings.activate_waiting_list_description": "Коли ця опція увімкнена, після досягнення ліміту гравців усі гравці, які приєднаються до цієї групи, будуть додані до листа очікування.",
  "group_settings.add_new_account": "Додати новий обліковий запис",
  "group_settings.admins_can_approve_own_claims": "Адміністратори можуть схвалювати власні заявки на відшкодування",
  "group_settings.admins_can_approve_own_claims_description": "Коли ця опція увімкнена, всі менеджери фінансів цієї групи можуть схвалювати власні заявки на відшкодування.",
  "group_settings.allow_requests_when_group_is_full": "Дозволити запити в заповнену групу",
  "group_settings.allow_requests_when_group_is_full_description": "Коли ця опція увімкнена, нові гравці зможуть подавати запити на приєднання навіть після досягнення ліміту гравців.",
  "group_settings.allow_requests_when_group_is_full_hint": "Ця опція доступна, якщо в групі є ліміт гравців.",
  "group_settings.apply_to_subgroups_title": "Застосувати до підгруп",
  "group_settings.cannot_delete_organization": "Організацію не можна видалити",
  "group_settings.club_website": "Сайт організації",
  "group_settings.compatible_with_fotballdata": "Сумісний з Fotballdata",
  "group_settings.contact_details": "Контактні дані",
  "group_settings.contact_details_description": "Зробіть групу легко доступною, надаючи актуальні контактні дані.",
  "group_settings.contact_email": "Контактна електронна адреса",
  "group_settings.contact_phone": "Контактний телефон",
  "group_settings.custom_fields": "Кастомні поля",
  "group_settings.custom_member_fields": "Кастомні поля для членів",
  "group_settings.custom_member_fields_description": "Створюйте кастомні поля (наприклад: школа, муніципалітет чи розмір футболки), щоб впевнитись, що члени групи надають необхідну інформацію.",
  "group_settings.delete_group": "Видалити групу",
  "group_settings.delete_group_description": "Ви впевнені, що хочете видалити цю групу? Щоб повторно активувати її, потрібно буде зв’язатися зі службою підтримки. Усі платежі з видаленої групи все ще доступні у вкладці Економіка.",
  "group_settings.delete_group_success": "Групу видалено.",
  "group_settings.delete_logo": "Видалити лого",
  "group_settings.delete_logo_description": "Ви впевнені, що хочете видалити лого?",
  "group_settings.delete_member_requests_list": "Користувачі очікують на схвалення",
  "group_settings.delete_member_requests_list_approve_description": "Користувачі будуть додані до групи як гравці.",
  "group_settings.delete_member_requests_list_approve_title": "Схвалити",
  "group_settings.delete_member_requests_list_decline_description": "Користувачі не будуть додані до групи, але ви все ще зможете переглядати відхилені запити в «Запитах на членство».",
  "group_settings.delete_member_requests_list_decline_title": "Відхилити",
  "group_settings.delete_member_requests_list_description": "У списку запитів на приєднання до <b>{{ group }}</b> наразі є 1 користувач.",
  "group_settings.delete_member_requests_list_description_1": "У списку запитів на приєднання до <b>{{ group }}</b> наразі є {{ count }} користувачів.",
  "group_settings.delete_waiting_list": "Користувачі в листі очікування",
  "group_settings.delete_waiting_list_add_description": "Користувачі будуть додані до групи як гравці.",
  "group_settings.delete_waiting_list_add_title": "Додати до групи",
  "group_settings.delete_waiting_list_clear_description": "Користувачі не будуть додані до групи, а лист очікування буде очищено.",
  "group_settings.delete_waiting_list_clear_title": "Очистити лист очікування",
  "group_settings.delete_waiting_list_description": "У листі очікування <b>{{ group }}</b> наразі 1 користувач.",
  "group_settings.delete_waiting_list_description_1": "У листі очікування <b>{{ group }}</b> наразі {{ count }} користувачів.",
  "group_settings.description": "Опис",
  "group_settings.economy": "Економіка",
  "group_settings.external_group": "Зовнішня група",
  "group_settings.external_group_description": "Користувачі зовнішніх груп не вважаються членами вашої організації та не зобов’язані сплачувати членські внески. Вони також не будуть синхронізовані з NIF і не будуть зараховані до ваших активних учасників.",
  "group_settings.general": "Основне",
  "group_settings.go_to_group_settings": "Перейти в налаштування",
  "group_settings.group_info": "Інформація про групу",
  "group_settings.group_is_not_empty": "Група не пуста",
  "group_settings.group_is_not_empty_description": "Групу неможливо видалити, якщо вона містить учасників або підгрупи.",
  "group_settings.group_not_empty_conditions": "Ваша група все ще має",
  "group_settings.group_visibility": "Видимість групи",
  "group_settings.group_visibility_description": "Дозвольте гравцям і опікунам приєднуватися до цієї групи на сторінці реєстрації організації. Зауважте, що користувачі, які використовують пряме посилання для запрошення, все ще можуть приєднатися.",
  "group_settings.has_member": "учасників.",
  "group_settings.has_sub_group": "підгрупи.",
  "group_settings.hidden_from_onboarding": "Група прихована від усіх у",
  "group_settings.inactive_account_description": "Якщо обліковий запис встановлено як неактивний, ви все одно отримуватимете будь-які непогашені платежі, але не зможете вибрати їх під час створення нових платежів.",
  "group_settings.invitation_link": "Посилання на запрошення",
  "group_settings.joining": "Приєднання",
  "group_settings.joining_description_1": "Оберіть як нові гравці зможуть приєднатись до цієї групи.",
  "group_settings.joining_description_2": "Зауважте, що це не вплине на поточних учасників групи, а також на гравців, які були додані вручну.",
  "group_settings.limit_members": "Обмежити кількістьі учасників",
  "group_settings.limit_members_invalid": "Встановіть будь-яке число більше або рівне 0",
  "group_settings.logo": "Лого",
  "group_settings.logo_description": "Завантажте логотип для цієї групи. Він може відображатися в реєстрації, електронних листах, рахунках-фактурах, тощо.",
  "group_settings.main_details": "Основні деталі",
  "group_settings.main_details_description": "Зберігайте інформацію про групу точною та узгодженою з її метою.",
  "group_settings.make_this_group_visible": "Зробити цю групу видимою для всіх користувачів",
  "group_settings.make_this_group_visible_description": "Коли ця опція увімкнена, групу бачать усі користувачі на сторінці реєстрації організації.",
  "group_settings.mandatory_payment": "Обов'язковий платіж",
  "group_settings.mandatory_payment_description_1": "Встановіть обов'язковий платіж, щоб контролювати, хто приєднується до цієї групи.",
  "group_settings.mandatory_payment_description_2": "Нові гравці, які безпосередньо приєднуються до групи, отримають 15 хвилин для оплати. Ті, кого автоматично додано з листу очікування або чий запит на членство було схвалено, матимуть 24 години для оплати.",
  "group_settings.mandatory_payment_description_3": "Зауважте, що це не вплине на поточних учасників групи, а також на гравців, які були додані вручну.",
  "group_settings.mandatory_payment_description_4": "Якщо платіж не опубліковано, призупинено або його період закінчився, гравці не зможуть приєднатися.",
  "group_settings.mandatory_payment_description_5": "Якщо оплата не буде здійснена протягом зазначеного часу, такі гравці будуть видалені з групи.",
  "group_settings.mandatory_payment_hint": "Для цієї групи нема доступних платежів за тренування",
  "group_settings.mandatory_payment_warning": "Нові гравці не можуть приєднатися до цієї групи",
  "group_settings.mandatory_payment_warning_1": "Платіж не є опублікованим, тому ми не можемо надсилати платежі новим гравцям, які бажають приєднатися. Щоб вирішити цю проблему, виберіть інший платіж або опублікуйте цей платіж за тренування.",
  "group_settings.mandatory_payment_warning_2": "Період цього платежа закінчився, тому ми не можемо надсилати платежі новим гравцям, які бажають приєднатися. Щоб вирішити цю проблему, виберіть платіж з дійсним періодом.",
  "group_settings.manual_approval": "Ручне схвалення",
  "group_settings.manual_approval_description": "Нові гравці будуть розміщені в списку запитів на членство («Членство» > «Запити на членство»). Щоб користувач приєднався до групи, його запит має бути схвалений вручну.",
  "group_settings.mark_this_group_as_external": "Позначити цю групу як зовнішню",
  "group_settings.mark_this_group_as_external_description": "Коли ця опція увімкнена, учасники цієї групи та її підгрупи не вважатимуться членами вашої організації, їм не потрібно буде сплачувати членські внески та не синхронізуватимуться з NIF.",
  "group_settings.member_and_subgroup": "учасники та підгрупи.",
  "group_settings.members_limit": "Ліміт учасників",
  "group_settings.move_users_to_member_requests_list": "Користувачів буде необхідно схвалювати вручну",
  "group_settings.move_users_to_member_requests_list_description": "Застосувавши ці зміни, користувачів, які зараз перебувають у листі очікування <b>{{ group }}</b>, буде переміщено до розділу «Запити на членство», де вони чекатимуть схвалення для приєднання.",
  "group_settings.move_users_to_member_requests_list_description_1": "Застосувавши ці зміни, усі користувачі, які хочуть приєднатися до <b>{{ group }}</b>, будуть додані до розділу «Запити на членство», де вони чекатимуть схвалення для приєднання.",
  "group_settings.move_users_to_waiting_list": "Користувачі будуть перенесені до листу очікування",
  "group_settings.move_users_to_waiting_list_description": "Застосувавши ці зміни, {{ count }} користувачів, які надіслали запит на приєднання до <b>{{ group }}</b> як гравці, буде переміщено зі списку «Запити на членство» до «Листу очікування». Звідти, по мірі появи вільних місць, вони будуть автоматично додаватись до групи в тому ж порядку, в якому вони були подані запити.",
  "group_settings.move_users_to_waiting_list_x_success": "{{ count }} користувачів переміщується, це може зайняти деякий час.",
  "group_settings.name": "Ім'я",
  "group_settings.no_accounts_message": "Немає рахунків, пов’язаних із групою.",
  "group_settings.no_accounts_warning_message": "Лише менеджер може додати рахунок.",
  "group_settings.open": "Відкрито",
  "group_settings.open_description_1": "Нові гравці можуть приєднатись до групи одразу після реєстрації, доки не досягнуто ліміту гравців. В іншому випадку, ніхто не зможе приєднуватись.",
  "group_settings.organization_number": "Номер організації",
  "group_settings.payout_period": "Період виплати",
  "group_settings.payout_period_description": "Встановіть період перерахування коштів на банківські рахунки групи.",
  "group_settings.permission_to_approve_own_claims": "Дозвіл на схвалення власних заявок на відшкодування витрат",
  "group_settings.permission_to_approve_own_claims_description": "Дозволити адміністраторам цієї групи схвалювати власні заявки на відшкодування витрат подані в цю групу.",
  "group_settings.players_limit": "Ліміт гравців",
  "group_settings.players_limit_description": "Встановіть обмеження на кількість гравців, які можуть приєднатися до цієї групи.",
  "group_settings.players_limit_exceeded_warning_description": "Додавання цих користувачів перевищить максимальний ліміт гравців ({{ limit }} гравців).",
  "group_settings.players_limit_exceeded_warning_title": "Ліміт гравців буде перевищено",
  "group_settings.please_remove_members": "Будь ласка, видаліть усіх учасників групи та повторіть спробу.",
  "group_settings.please_remove_subgroups": "Видаліть підгрупи та повторіть спробу.",
  "group_settings.redirecting_to": "Переспрямування до",
  "group_settings.registration_page": "сторінка реєстрації",
  "group_settings.registration_page_note": "сторінка реєстрації організації",
  "group_settings.request_more_fields": "Запросити більше полів",
  "group_settings.send_payment_as_mandatory_description": "Коли ця опція увімкнена, додані користувачі мають сплатити цей платіж протягом 24 годин, щоб залишатися в групі. В іншому випадку, його буде надіслано як звичайний платіж.",
  "group_settings.send_payment_as_mandatory_title": "Надіслати «{{ payment }}» як обовʼязковий платіж",
  "group_settings.settings": "налаштування",
  "group_settings.sign_up": "Реєстрація",
  "group_settings.stripe": "Stripe",
  "group_settings.stripe_information": "Інформація про Stripe",
  "group_settings.this_group_type_does_not_support_signup": "Цей тип групи не підтримує реєстрацію.",
  "group_settings.update_stripe_information": "Оновити інформацію Stripe",
  "group_settings.visible_from_onboarding": "Групу бачать усі у",
  "group_settings.waiting_list": "Лист очікування",
  "group_settings.waiting_list_description": "Дозвольте гравцям приєднуватися до черги очікування, де вони можуть чекати вільного місця в цій групі.",
  "group_settings.waiting_list_description_1": "Після того як ліміт гравців буде досягнуто, нові гравці будуть потрапляти до листу очікування («Групи» > «Лист очікування»), звідки їх буде автоматично додано до групи в тому ж порядку, у якому вони приєднувались.",
  "group_settings.waiting_list_description_2": "Після того як ліміт гравців буде досягнуто, нові гравці будуть потрапляти в цей список, звідки їх буде автоматично додано до групи в тому ж порядку, у якому вони приєднувались.",
  "group_settings.waiting_list_enable_description": "Після досягнення ліміту гравців усі користувачі, які приєднаються до цієї групи, потраплять у список очікування",
  "group_settings.website": "Вебсайт",
  "group.type": "Тип групи",
  "group_type.associate_members": "Асоційовані учасники",
  "group_type.communication_group": "Група спілкування",
  "group_type.division": "Дівізія",
  "group_type.organization": "Організація",
  "group_type.other": "Інші",
  "group_type.team": "Команда",
  "img_alt.empty_mailbox": "Порожня поштова скринька",
  "img_alt.hoopit_logo": "Логотип Hoopit",
  "individual_payments.all_payments": "Всі",
  "individual_payments.ongoing_payments": "Не оплачено",
  "individual_payments.past_payments": "Оплачується або звільняється",
  "invite_user.add_users_to_payment": "Додати користувачів до платежа",
  "invite_user.create_event": "Створити подію",
  "invite_user.create_payment": "Створити платіж",
  "invite_user.empty": "Нема {{ tab_name }}",
  "invite_user.group_activity_invite": "додані до групи в майбутньому будуть запрошені до цієї активності.",
  "invite_user.invitation_description": "Кожному, кого ви виберете, буде надіслано запрошення та повідомлення про майбутні зміни.",
  "invite_user.invitation_description_2": "Вибрані користувачі отримають платіж у додатку.",
  "invite_user.invitations": "Запрошення",
  "invite_user.invitation_sent": "Запрошення надіслано",
  "invite_user.invitations_tab": "запрошення",
  "invite_user.invite_future": "Запросити майбутніх {{ tab_name }}",
  "invite_user.invite_to_activity": "Тут ви можете запросити до заходу інших учасників спортивної команди",
  "invite_user.number": "Кількість",
  "invite_user.search": "Пошук",
  "invite_user.search_by_name": "Пошук за ім’ям",
  "invite_user.search_result": "Знайдено {{ x }}",
  "invite_user.send_invitations": "Надіслати запрошення",
  "invite_user.send_payments": "Відправити платіж",
  "invite_user.users_invited_this_may_take_some_time": "Користувачі запрошені, це може зайняти деякий час",
  "languages.en": "Англійська",
  "languages.nb": "Норвезька",
  "languages.uk": "Українська",
  "locations.add_default_behavior_tag": "Додайте стандартний тег поведінки для",
  "locations.add_default_tag": "Додати тег за умовчанням",
  "locations.add_location": "Додайте локацію",
  "locations.add_locations_text": "Будь ласка, додайте локації на",
  "locations.add_locations_text_2": "для інтеграції з LuxSave.",
  "locations.add_more_tags": "Додайте більше тегів",
  "locations.add_tag": "Додати тег для",
  "locations.add_tag_description": "Додайте опис для цього тегу, щоб ті, хто свторили подію, зрозуміли, що він робить.",
  "locations.cannot_be_changed": "Не можна змінити згодом",
  "locations.confirm_default_tag": "Підтвердити тег за замовчуванням",
  "locations.connect_location": "Підключіть {{ locationName }} до LuxSave",
  "locations.connect_location_link": "Веб-сайт інтеграції LuxSave",
  "locations.connect_location_text": "Щоб підключити цю локацію до LuxSave, введіть цей ID у форму «Реєстрація нового календаря» за адресою",
  "locations.create_first_tag_text": "Будь ласка, створіть перший тег для типової поведінки LuxSave. Після цього ви зможете додавати звичайні теги.",
  "locations.custom_name": "Індивідуальна назва",
  "locations.default_behavior": "Поведінка за замовчуванням",
  "locations.default_behavior_keyword": "Типова поведінка не має ключового слова в LuxSave.",
  "locations.default_behavior_text": "Це типова поведінка",
  "locations.delete_location": "Видалити місцезнаходження",
  "locations.delete_location_confirmation": "Ви впевнені, що хочете видалити цю локацію? Події з цієї локації не будуть видалені.",
  "locations.delete_selected_tag": "Видалити тег {{ tagName }}",
  "locations.delete_tag": "Видалити тег",
  "locations.delete_tag_message": "Ви впевнені, що бажаєте видалити цей тег?",
  "locations.edit": "Редагувати {{ locationName }}",
  "locations.edit_default_tag": "Редагувати тег за замовчуванням для",
  "locations.edit_location": "Редагувати локацію",
  "locations.edit_tag": "Редагувати тег",
  "locations.edit_tag_for": "Редагувати тег для",
  "locations.field": "Поле",
  "locations.finish": "Закінчити",
  "locations.go_to_location_list": "Перейти до списку локацій",
  "locations.how_many_sections": "Скільки секцій (максимальна кількість ігрових полів) підтримує ця локація? Це <b>неможливо</b> змінити пізніше.",
  "locations.information_luxsave": "Інформація для підключення LuxSave",
  "locations.integrations": "Інтеграції",
  "locations.last_syncronized": "Востаннє синхронізовано",
  "locations.locaiton_list_text": "Список локацій, які були інтегровані з LuxSave.",
  "locations.locaiton_list_text_2": "Синхронізація оновлень може тривати до 30 хвилин.",
  "locations.location_list": "Список локацій",
  "locations.location_set_up": "Локація була успішно створена",
  "locations.location_type": "Тип локації",
  "locations.luxsave": "LuxSave",
  "locations.luxsave_keyword": "Ключове слово LuxSave",
  "locations.luxsave_keyword_text": "Введіть ключове слово LuxSave. Переконайтеся, що воно відповідає ключовому слову LuxSave. Інакше воно не працюватиме належним чином.",
  "locations.luxsave_tags": "Теги LuxSave",
  "locations.name": "Назва",
  "locations.never": "ніколи",
  "locations.no_locations": "Ще не було додано жодної локації",
  "locations.no_locations_added": "Ще не було додано локацій",
  "locations.no_locations_available": "Немає доступних локацій для інтеграції LuxSave",
  "locations.other": "Інше",
  "locations.owner": "Власник",
  "locations.page_access_text": "Лише <b>польові координатори</b> мають доступ до цієї сторінки",
  "locations.sections": "Сецкції",
  "locations.select_location": "Виберіть місце для підключення до LuxSave",
  "locations.set_tag_name": "Будь ласка, встановіть назву для тегу. Він буде показаний авторам події.",
  "locations.set_tags": "Встановити теги для",
  "locations.settings": "Налаштування {{ locationName }}",
  "locations.shared": "спільний доступ",
  "locations.sync_up_duration_text": "Синхронізація може тривати до 30 хвилин",
  "locations.tag_description_text": "Цей тег використовуватиметься для представлення типової поведінки (без ключового слова) у LuxSave. Це полегшить іншим зрозуміти, що робить поведінка за замовчуванням.",
  "locations.tags": "Теги",
  "member_list.action_add": "Додано сертифікат із датою видачі {{ date }}",
  "member_list.action_extend": "Сертифікат із датою видачі {{ issueDate }} продовжено на {{ extendedByYears }} років (до {{ endDate }})",
  "member_list.action_history": "Історія дій",
  "member_list.action_history_empty": "Немає історії сертифікатів",
  "member_list.action_history_for": "Історія дій із сертифікатом для {{ name }}",
  "member_list.action_remove": "Сертифікат із датою видачі {{ date }} видалено",
  "member_list.action_update": "Сертифікат оновлено: дата видачі {{ issueDate }}, дата завершення {{ endDate }}, дата перевірки {{ verificationDate }}",
  "member_list.add_certificate": "Додати сертифікат",
  "member_list.add_certificate_confirmation": "Я підтверджую, що я бачив сертифікат і підтверджую його дійсність",
  "member_list.add_certificate_description": "Додайте дати початку та закінчення сертифіката",
  "member_list.add_certificate_description_2": "Будь ласка, встановіть дату перевірки сертифіката",
  "member_list.add_certificate_for": "Додати сертифікат для {{ name }}",
  "member_list.add_certificate_success_toast": "Сертифікат успішно додано",
  "member_list.certificate": "Сертифікат",
  "member_list.certificate_details": "Деталі сертифікату",
  "member_list.certificate_expired": "Термін дії сертифіката закінчився",
  "member_list.certificate_info": "Інформація про сертифікат",
  "member_list.certificate_is_valid": "Сертифікат дійсний",
  "member_list.certificate_required": "Необхідний сертифікат",
  "member_list.certificates": "Сертифікати",
  "member_list.criminal_record_certificates": "Довідки про несудимість",
  "member_list.criminal_record_certificates_no_access": "Лише <strong>менеджери судимості</strong> мають доступ до цієї сторінки",
  "member_list.does_not_have_certificate": "Не має сертифіката",
  "member_list.does_not_require_certificate": "Сертифікат не вимагає",
  "member_list.empty_sub_group": " не має підгруп",
  "member_list.empty_sub_group_info": "Натисніть кнопку нижче, щоб створити підгрупу в",
  "member_list.end_date": "Дата закінчення",
  "member_list.end_date_validation_message": "Дата має бути в майбутньому та після дати випуску",
  "member_list.expires": "Термін дії закінчується",
  "member_list.expires_in_1_day": "Термін дії закінчується через 1 день",
  "member_list.expires_in_days": "Термін дії закінчується через {{ number  }} днів",
  "member_list.expires_soon": "Термін дії скоро закінчується",
  "member_list.expires_today": "Термін дії закінчується сьогодні",
  "member_list.extend_certificate": "Подовжити сертифікат",
  "member_list.extend_certificate_confirmation": "Я підтверджую, що хочу продовжити сертифікат.",
  "member_list.extend_certificate_description": "Ви впевнені, що бажаєте продовжити сертифікат {{ name }} на {{ number }} років?",
  "member_list.extend_certificate_description_2": "Кінцеву дату буде змінено з {{ endDateBefore }} на {{ endDateAfter }}.",
  "member_list.extend_certificate_success_toast": "Сертифікат успішно продовжено",
  "member_list.extended_certificate": "Розширений сертифікат",
  "member_list.extend_for_years": "Продовжити на {{ число }} років",
  "member_list.filter": "Фільтр",
  "member_list.has_been_extended": "Розширено",
  "member_list.has_certificate": "Має сертифікат",
  "member_list.has_valid_certificate": "Має дійсний сертифікат",
  "member_list.in": "в",
  "member_list.invite_more_people": "Ви можете запросити більше людей до {{ organization_name }} за посиланням у налаштуваннях групи",
  "member_list.issue_date": "Дата випуску",
  "member_list.issue_date_validation_message": "Дата має бути перед датою завершення, а не в майбутньому",
  "member_list.members": "Члени",
  "member_list.members_certificate_expires_soon": "Термін дії сертифіката члена {{ number }} незабаром закінчиться",
  "member_list.members_missing_certificate": "У {{ number }} учасників відсутні сертифікати",
  "member_list.members_require_certificate": "Учасники {{ number }} повинні мати сертифікат",
  "member_list.member_was_exempted": "Учасника було анульовано",
  "member_list.mf_not_published": "Членський внесок не опубліковано",
  "member_list.min_2_characters": "Мінімум 2 символи",
  "member_list.missing_certificate": "Відсутній сертифікат",
  "member_list.navigate_to_members": "Переглянути учасників",
  "member_list.no_certificate_required": "Сертифікат не потрібен",
  "member_list.no_members_found": "Ми не знайшли учасників",
  "member_list.nothing_found": "Ми не знайшли жодного",
  "member_list.overview": "Огляд",
  "member_list.remove_certificate": "Видалити сертифікат",
  "member_list.remove_certificate_confirmation": "Я підтверджую, що хочу безповоротно видалити сертифікат",
  "member_list.remove_certificate_description": "Ви впевнені, що хочете видалити сертифікат {{ name }}, дійсний до {{ date }}?",
  "member_list.remove_certificate_success_toast": "Сертифікат успішно видалено",
  "member_list.requests": "Запити",
  "member_list.requirement_settings": "Налаштування вимог",
  "member_list.requirement_settings_for": "Налаштування вимог для {{ name }}",
  "member_list.requires_certificate": "Потрібен сертифікат",
  "member_list.search": "Пошук",
  "member_list.search_by_name": "Пошук за ім’ям",
  "member_list.tf_not_published": "Плата за тренування не опублікована",
  "member_list.update_certificate": "Оновити сертифікат",
  "member_list.valid_from": "Діє з",
  "member_list.verification_date": "Дата перевірки",
  "member_list.verification_date_validation_message": "Дата має бути між датою випуску та кінцевою датою, а не в майбутньому",
  "member_list.verified": "Перевірено",
  "member_list.view_members_info": "Натисніть «учасники» в меню вище, щоб переглянути особисту інформацію.",
  "member_list.with_number": "з номером мобільного",
  "member_requests.decline_x_success": "{{ count }} запитів в процесі відхилення, це може зайняти деякий час.",
  "membership.add_note_for": "Додати примітку для",
  "membership.age": "Вік",
  "membership.age_gender_statistics": "Віково-статева статистика",
  "membership.age_gender_statistics_hint": "Цей віджет включає лише учасників, стать яких встановлена в Hoopit. Щоб переглянути повну статистику, переконайтеся, що всі {{ membersType }} вказали стать у своїх профілях.",
  "membership.all_time": "Весь час",
  "membership.any_ideas": "Будь-які ідеї чи пропозиції щодо цієї сторінки? Будь ласка, поділіться!",
  "membership.awaiting_confirmation": "В очікуванні підтвердження",
  "membership.back_to_overview": "Повернутися до огляду",
  "membership.by_joined_date": "За датою приєднання",
  "membership.by_last_nif_confirmation_reminder": "За останнім нагадуванням про підтвердження NIF",
  "membership.cancel_membership": "Скасувати членство",
  "membership.cancel_membership_bulk": "Скасувати членство для {{ usersCount }} учасників?",
  "membership.cancel_membership_description_1": "Ви збираєтеся скасувати членство <b>{{ firstName }} {{ lastName }}</b> у <b>{{ group }}</b>.",
  "membership.cancel_membership_description_2": "Їх буде видалено з таких груп у Hoopit:",
  "membership.cancel_membership_description_3": "Членство в наступних NIF буде скасовано:",
  "membership.cancel_membership_description_bulk": "Ви збираєтеся скасувати членство для {{ usersCount }} осіб у <b>{{ групі }}</b>:",
  "membership.cancel_membership_description_exempt": "Ви онулюєте обрані:",
  "membership.cancel_membership_description_unpaid_count": "<b>{{ firstName }} {{ lastName }}</b> має <b>{{ paymentsCount }}</b> несплачених платежів.",
  "membership.cancel_membership_success": "Членство {{ name }} скасовується, це може зайняти деякий час.",
  "membership.cancel_membership_success_2": "Членство {{ name }} скасовано",
  "membership.cancel_membership_success_bulk": "Членство {{ usersCount }} людей скасовується, це може зайняти деякий час.",
  "membership.cancel_membership_success_exempting": "{{ paymentsCount }} платежі обнуляються, це може зайняти деякий час",
  "membership.confirmation_in_progress": "Триває підтвердження",
  "membership.confirmation_sent": "Новий запит на підтвердження надіслано до {{ name }}.",
  "membership.confirmation_sent_bulk": "Новий запит на підтвердження надіслано {{ usersCount }} членам.",
  "membership.confirmed": "Підтверджено",
  "membership.confirmed_description": "Це список підтверджених учасників <b>{{ групи }}</b>.",
  "membership.confirm_membership": "Підтвердити членство",
  "membership.confirm_membership_success": "{{ name }} приєдналися до {{ group }} без облікового запису Hoopit і були переміщені до Confirmed.",
  "membership.confirm_without_hoopit": "Підтвердити без Hoopit",
  "membership.confirm_without_hoopit_description_1": "Ви збираєтеся підтвердити <b>{{ firstName }} {{ lastName }}</b> як члена клубу без облікового запису Hoopit. Вони з’являться в списку членів клубу, але не матимуть доступу до Hoopit.",
  "membership.confirm_without_hoopit_description_2": "Це слід використовувати лише для учасників, які не планують використовувати Hoopit у майбутньому, наприклад для ветеранів тощо.",
  "membership.details": "Подробиці",
  "membership.edit_note_for": "Редагувати примітку для",
  "membership.emailed_on": "Надіслано електронною поштою {{date}}",
  "membership.email_mismatch_warning_description_1": "NIF надіслав запит на підтвердження членства для {{user}} на адресу <b>{{email}}</b>, який не відповідає нашим записам і може бути застарілим або неправильним.",
  "membership.email_mismatch_warning_description_2": "Будь ласка, зв'яжіться з користувачем, щоб підтвердити правильність електронної пошти. При необхідності вони можуть оновити його в MinIdrett або звернутися по допомогу до служби підтримки NIF.",
  "membership.email_mismatch_warning_title": "Попередження: неспівпадіння електронних пошт",
  "membership.enroll_in_nif": "Зарахувати до NIF",
  "membership.enroll_in_nif_description_1": "NIF надішле електронний лист до <b>{{user}}</b> з проханням підтвердити членство.",
  "membership.enroll_in_nif_description_2": "Після підтвердження, їх статус у Hoopit буде автоматично оновлено.",
  "membership.expires_in": "дійсний ще",
  "membership.export_statistics": "Експортувати статистику",
  "membership.external_users": "Зовнішні користувачі",
  "membership.hoopit_account": "Обліковий запис Hoopit",
  "membership.imported": "Імпортовані",
  "membership.imported_description_1": "Це список учасників, імпортованих із NIF, яких немає в Hoopit.",
  "membership.imported_description_2": "Ці учасники ще не підтверджені, і ви можете зробити одне з наступного:",
  "membership.imported_description_2_list_item_1": "запросити в Hoopit (їх буде переміщено в «Очікування», коли вони підтвердять запрошення)",
  "membership.imported_description_2_list_item_2": "скасувати членство",
  "membership.imported_description_2_list_item_3": "підтвердити членство (учасник не матиме доступу до Hoopit, потрібна перевірка NIF)",
  "membership.imported_description_3": "Усі дані та членство надаються безпосередньо NIF.",
  "membership.imported_from_nif": "Імпортовано з NIF",
  "membership.invitation_sent": "Запрошення надіслано",
  "membership.invite_to_hoopit": "Запросити до Hoopit",
  "membership.invite_to_hoopit_bulk": "Запросіть {{ usersCount }} учасників до Hoopit",
  "membership.invite_to_hoopit_description_1": "Ви збираєтеся надіслати <b>{{ firstName }} {{ lastName }}</b> запрошення приєднатися до Hoopit і <b>{{ group }}</b>. Якщо вони приймуть запрошення, вони стануть учасниками.",
  "membership.invite_to_hoopit_description_2": "Якщо вони не приймуть запрошення, контактна інформація може бути застарілою або вони більше не бажають бути учасниками.",
  "membership.invite_to_hoopit_description_bulk": "Ви збираєтеся надіслати {{ usersCount }} людям запрошення приєднатися до Hoopit і <b>{{ group }}</b>. Якщо вони приймуть запрошення, вони стануть учасниками.",
  "membership.joined_date": "Дата приєднання",
  "membership.member_joined": "Учасник приєднався",
  "membership.member_left": "Учасник покинув",
  "membership.member_requests": "Запити на членство",
  "membership.member_requests_1_user_waiting": "1 користувач очікує на",
  "membership.member_requests_all": "Всі",
  "membership.member_requests_all_description": "{{ count }} запитів загалом",
  "membership.member_requests_approve": "Схвалити",
  "membership.member_requests_approved": "Схвалено",
  "membership.member_requests_approved_description": "{{ count }} схвалених запитів",
  "membership.member_requests_approve_single_description": "<b>{{ name }}</b> буде додано до <b>{{ group }}</b> як {{ role }}.",
  "membership.member_requests_approve_single_title": "Схвалити запит?",
  "membership.member_requests_approve_x_description": "{{ count }} запитів буде схвалено.",
  "membership.member_requests_approve_x_list_of_users": "Користувачі, яких буде схвалено для членства в <b>{{ group }}</b>:",
  "membership.member_requests_approve_x_title": "Схвалити {{ count }} запитів?",
  "membership.member_requests_decline": "Відхилити",
  "membership.member_requests_declined": "Відхилено",
  "membership.member_requests_declined_description": "{{ count }} відхилених запитів",
  "membership.member_requests_decline_single_description": "Запит <b>{{ name }}</b> про приєднання до <b>{{ group }}</b> як {{ role }} буде відхилено.",
  "membership.member_requests_decline_single_success": "Запит {{ name }} було відхилено.",
  "membership.member_requests_decline_single_title": "Відхилити запит?",
  "membership.member_requests_decline_x_description": "{{ count }} запитів буде відхилено.",
  "membership.member_requests_decline_x_list_of_users": "Користувачі, яким буде відхилено у членства в <b>{{ group }}</b>:",
  "membership.member_requests_decline_x_title": "Відхилити {{ count }} запитів?",
  "membership.member_requests_disabled_button": "Перейти до налаштувань",
  "membership.member_requests_disabled_description": "Якщо ви хочете вручну схвалювати кожного гравця, який приєднується до {{ group }}, виберіть «Ручне схвалення» в налаштуваннях групи.",
  "membership.member_requests_disabled_title": "Запити на членство не активовано",
  "membership.member_requests_message_to_user": "Повідомлення для користувача",
  "membership.member_requests_message_to_user_approve": "Ми дуже раді мати вас як частину нашої команди.",
  "membership.member_requests_message_to_user_decline": "На жаль, цього разу ваш запит було відхилено. Ми цінуємо ваше розуміння.",
  "membership.member_requests_note_add": "Додати примітку для {{ user }}",
  "membership.member_requests_note_edit": "Редагувати примітку для {{ user }}",
  "membership.member_requests_note_hint": "Видно лише адміністраторам",
  "membership.member_requests_pending": "В очікуванні",
  "membership.member_requests_pending_description": "{{ count }} запитів, що очікують на розгляд",
  "membership.member_requests_players_limit_exceeded_warning_description": "Додавання цих користувачів перевищить максимальний ліміт гравців у {{ group }}.",
  "membership.member_requests_players_limit_exceeded_warning_description_1": "Додавання цього користувача перевищить максимальний ліміт гравців у {{ group }}.",
  "membership.member_requests_send_payment_as_mandatory_description": "Коли ця опція увімкнена, користувачі додані до {{ group }} мають сплатити обовʼязковий платіж протягом 24 годин, щоб залишатися в групі. В іншому випадку, його буде надіслано як звичайний платіж.",
  "membership.member_requests_send_payment_as_mandatory_title": "Надіслати обов'язковий платіж",
  "membership.member_requests_x_users_waiting": "{{ count }} користувачів очікують на",
  "membership.members": "Учасники",
  "membership.members_active_and_supervised_filter": "Активний і Під наглядом",
  "membership.members_all_users": "Всі користувачі",
  "membership.members_description": "з членством у {{ group }}.",
  "membership.members_hoopit_status_filter": "Hoopit статус",
  "membership.members_joined": "Учасників приєдналося",
  "membership.members_joined_table_hint": "Ця таблиця містить лише учасників, які приєдналися до групи {{ group }} у вибраний проміжок часу та все ще є членами цієї групи або будь-якої з її підгруп",
  "membership.members_joined_tooltip": "Відображає дані з NIF, якщо вони доступні, інакше відображає дані з Hoopit",
  "membership.members_left": "Учасників покинуло",
  "membership.members_left_table_hint": "Ця таблиця містить лише учасників, які залишили {{ group }} (і всі її підгрупи, якщо такі є) за вибраний проміжок часу",
  "membership.members_missing_email_filter": "Відсутня електронна пошта (як для користувача, так і для опікунів)",
  "membership.members_movement": "Загальна зміна",
  "membership.members_not_published_mf_tooltip": "Членський внесок не опубліковано",
  "membership.members_paid_mf_external_tooltip": "З зовнішніх користувачі не стягають членські платежі",
  "membership.members_requires_attention": "Вимагає уваги",
  "membership.members_statistics": "Статистика учасників",
  "membership.members_users_counter": "{{count}} користувачів",
  "membership.missing_email": "Відсутня електронна пошта",
  "membership.missing_email_description": "Ми не зможемо відправити посилання на email, тому що {{name}} не надав його.",
  "membership.missing_email_description_bulk": "Ми не зможемо відправити посилання на email {{number}} учасникам, тому що вони не надали його.",
  "membership.missing_membership_fee": "Відсутній членський внесок",
  "membership.missing_payment": "Відсутній платіж",
  "membership.new_reminder_description": "Нагадування надсилається як електронний лист і push-сповіщення в програмі. Автоматизоване повідомлення містить необхідну платіжну інформацію. Нижче ви можете додати особисте повідомлення для <b>{{ fullName }}</b>.",
  "membership.new_reminder_description_for_x": "Нагадування надсилається як електронний лист і push-сповіщення в програмі. Автоматизоване повідомлення містить необхідну платіжну інформацію. Нижче ви можете додати особисте повідомлення для <b>{{ count }}</b> учасників .",
  "membership.new_reminder_for": "Нове нагадування для",
  "membership.new_reminder_for_x": "Нове нагадування для {{ count }} учасників",
  "membership.nif_email_expiration_warning_description": "Дія посилання закінчиться за 24 години, але ви можете надіслати його повторно в будь-який момент.",
  "membership.nif_email_expiration_warning_title": "Дія NIF листа закінчиться за 24 години",
  "membership.nif_membership": "Членство в NIF",
  "membership.nif_status": "Статус NIF",
  "membership.no_email_provided": "Електронна адреса не вказана",
  "membership.no_hoopit_account": "Немає облікового запису Hoopit",
  "membership.no_nif_membership": "Немає членства в NIF",
  "membership.no_notes_added": "Приміток не додано",
  "membership.not_connected": "Не підключено",
  "membership.not_connected_tooltip": "Користувач не підключений до NIF, але може мати існуючий обліковий запис. Він може зареєструватися або підключитися до NIF у нашому додатку або через електронну пошту.",
  "membership.not_enrolled": "Не зараховано",
  "membership.not_enrolled_cancelled": "скасовано",
  "membership.not_enrolled_cancelled_tooltip": "Користувач підключений до NIF, але його членство в клубі наразі скасовано в NIF. ",
  "membership.not_enrolled_tooltip": "Користувач підключений до NIF, але не має членства в клубі.",
  "membership.page_under_construction": "Ця сторінка знаходиться на стадії розробки",
  "membership.paid_mf_period": "",
  "membership.paid_mf_statistics_title": "",
  "membership.pending": "В очікуванні",
  "membership.pending_confirmation": "Очікується підтвердження",
  "membership.pending_confirmation_tooltip": "Користувач підключений до NIF, але його членство ще не підтверджено.\nNIF надіслав електронний лист із посиланням на форму підтвердження, яку потрібно заповнити до закінчення терміну дії.",
  "membership.pending_description_1": "Це список людей, які користуються Hoopit і знаходяться в процесі приєднання до <b>{{ group }}</b>.",
  "membership.pending_description_2": "Тут ви можете побачити, на якому етапі процесу вони перебувають, і вжити заходів, щоб підтримати їх у процесі.",
  "membership.pending_description_3": "Коли всі вимоги будуть виконані, вони стануть підтвердженими учасниками.",
  "membership.recently_joined": "Нещодавно приєдналися",
  "membership.reminder_history": "Історія нагадувань",
  "membership.reminder_history_description": "Нагадування надсилаються як електронні листи та push-сповіщення в програмі. Повідомлення базується на наявності або терміні дії сертифіката.",
  "membership.request": "запит",
  "membership.request_expired": "Дія запиту завершилась ({{date}})",
  "membership.request_nif_connection": "Заробити запит на NIF підключення",
  "membership.request_nif_connection_bulk_description_1": "Ми спробуємо надіслати електронний лист та push-повідомлення з посиланням для реєстрації або підключення до існуючого облікового запису NIF користува {{x}} користувачам та їхнім опікунам.",
  "membership.request_nif_connection_bulk_description_2": "{{count}} з {{totalCount}} вибраних вами користувачів отримає електронний лист та push-сповіщення з посиланням для реєстрації або підключення до існуючого облікового запису у NIF.",
  "membership.request_nif_connection_description": "Зауважте, що всі учасники також можуть підключитися до NIF безпосередньо через наш додаток у будь-який час.",
  "membership.request_nif_connection_single_description_1": "Ми спробуємо надіслати електронний лист та push-повідомлення з посиланням для реєстрації або підключення до існуючого аккаунту NIF <b>{{user}}</b> та його опікунам.",
  "membership.request_nif_connection_single_description_2": "Посилання буде надіслано на {{emails}}.",
  "membership.requests": "запити",
  "membership.requires_approval": "Потребує погодження",
  "membership.requires_confirmation": "Потрібне підтвердження",
  "membership.search_by_name_phone_email_or_relative": "Search by name, phone, email or relative",
  "membership.select_date_placeholder": "",
  "membership.send_certificate_reminders": "Надсилати нагадування про сертифікати",
  "membership.send_confirmation_reminder": "Надіслати нагадування про підтвердження",
  "membership.send_invitation": "Надіслати запрошення",
  "membership.send_invitation_succes_bulk": "Запрошення приєднатися до Hoopit надіслано {{ usersCount }} особам.",
  "membership.send_invitation_success": "Запрошення приєднатися до Hoopit надіслано {{ name }}.",
  "membership.send_message": "Відправити лист",
  "membership.send_message_success": "Ваше повідомлення було відправлене.",
  "membership.send_message_to": "Надіслати електронний лист до",
  "membership.send_new_reminder": "Надіслати нове нагадування",
  "membership.sent_to": "Надіслано",
  "membership.set_specific_date": "Встановити конкретну дату",
  "membership.still_members": "Все ще учасники",
  "membership.suggestion": "Пропозиція",
  "membership.suggestion_received": "Ми отримали вашу пропозицію, дякуємо!",
  "membership.this_month": "Цього місяця",
  "membership.this_week": "Цього тижня",
  "membership.this_year": "Цього року",
  "membership.total_joins": "Всього приєднань",
  "membership.total_members": "Учасники",
  "membership.total_paid_mf_statistic": "",
  "membership.unknown_age": "Невідомо",
  "membership.user": "Користувач",
  "membership.users": "Користувачів",
  "membership.verified_by_nif_description": "Інформацію про користувача було перевірено NIF на відповідність Норвезькому національному реєстру населення",
  "membership.who_to_invite": "Кого ти хочеш запросити?",
  "membership.who_to_invite_description": "Користувача буде запрошено до <b>{{ group }}</b>. Використовуйте селектор групи, щоб змінити групу.",
  "membership.your_message": "Твоє повідомлення",
  "miscellaneous.1_day_ago": "1 день тому",
  "miscellaneous.1_hour_ago": "1 годину тому",
  "miscellaneous.1_like": "1 лайк",
  "miscellaneous.1_minute_ago": "1 хвилину тому",
  "miscellaneous.1_week_ago": "1 тиждень тому",
  "miscellaneous.1_year": "1 рік",
  "miscellaneous.all": "Всі",
  "miscellaneous.and": "і",
  "miscellaneous.and_sub_groups": "та підгрупи",
  "miscellaneous.april": "Квітень",
  "miscellaneous.august": "Серпень",
  "miscellaneous.beta": "Beta",
  "miscellaneous.change_to": "Змінити на",
  "miscellaneous.d": "d",
  "miscellaneous.december": "Грудень",
  "miscellaneous.deselect_all": "Зняти виділення",
  "miscellaneous.division": "дівізія",
  "miscellaneous.divisions": "дівізії",
  "miscellaneous.dont_show_message_again": "Не показувати знову.",
  "miscellaneous.february": "Лютий",
  "miscellaneous.filter": "фільтр",
  "miscellaneous.filters": "Фільтри",
  "miscellaneous.full_description": "Повний опис",
  "miscellaneous.group": "група",
  "miscellaneous.groups": "групи",
  "miscellaneous.h": "ч",
  "miscellaneous.help_us_improve_page_description": "Чи є у вас ідеї, як можна покращити цю сторінку, або може чогось не вистачає? Зв’яжіться з нами!",
  "miscellaneous.help_us_improve_page_title": "Допоможіть нам покращити цю сторінку",
  "miscellaneous.in": "в",
  "miscellaneous.include_transaction_fee": "Включити комісію за транзакцію",
  "miscellaneous.in_total": "Усього",
  "miscellaneous.invalid_format": "Невірний формат",
  "miscellaneous.january": "Січень",
  "miscellaneous.july": "Липень",
  "miscellaneous.june": "Червень",
  "miscellaneous.just_now": "щойно",
  "miscellaneous.last_month": "Минулого місяця",
  "miscellaneous.last_week": "Минулого тижня",
  "miscellaneous.last_year": "Минулого року",
  "miscellaneous.learn_more": "Дізнатись більше",
  "miscellaneous.like": "Лайк",
  "miscellaneous.likes": "Лайки",
  "miscellaneous.m": "хв",
  "miscellaneous.march": "Березень",
  "miscellaneous.max_number_characters": "Максимум {{ number }} символів",
  "miscellaneous.may": "Травень",
  "miscellaneous.message": "Повідомлення",
  "miscellaneous.min": "мін",
  "miscellaneous.min_2_characters": "Мінімум 2 символи",
  "miscellaneous.minimum": "Мінімум",
  "miscellaneous.more": "Більше",
  "miscellaneous.n_days_ago": "{{ number }} днів тому",
  "miscellaneous.n_days_ago_short": "{{n}}д тому",
  "miscellaneous.never": "Ніколи",
  "miscellaneous.n_hours_ago": "{{n}} годину тому",
  "miscellaneous.n_hours_ago_short": "{{n}}г тому",
  "miscellaneous.n_likes": "{{ number }} лайки",
  "miscellaneous.n_minutes_ago": "{{n}} хвилин тому",
  "miscellaneous.n_minutes_ago_short": "{{n}}хв тому",
  "miscellaneous.none": "Жодного",
  "miscellaneous.no_sub_groups_found": "Підгрупи не знайдено",
  "miscellaneous.note": "Примітка",
  "miscellaneous.nothing_found": "Нічого не знайдено",
  "miscellaneous.november": "Листопад",
  "miscellaneous.n_weeks_ago": "{{n}} тижнів тому",
  "miscellaneous.n_weeks_ago_short": "{{n}}тиж тому",
  "miscellaneous.n_years": "{{ number }} років",
  "miscellaneous.october": "Жовтень",
  "miscellaneous.of": "з",
  "miscellaneous.oops_something_went_wrong": "Упс, щось пішло не так",
  "miscellaneous.or": "або",
  "miscellaneous.please_try_again": "Будь ласка, спробуйте ще раз.",
  "miscellaneous.please_try_another_name_or_phone_number": "Спробуйте інше ім’я або номер",
  "miscellaneous.receiver": "Отримувач",
  "miscellaneous.redirecting": "Перенаправляємо...",
  "miscellaneous.role": "Роль",
  "miscellaneous.search": "Пошук",
  "miscellaneous.search_by_name": "Пошук за назвою",
  "miscellaneous.select_all": "Вибрати все",
  "miscellaneous.selected_group": "Вибрана група",
  "miscellaneous.september": "Вересень",
  "miscellaneous.set_specific_period": "Встановити конкретний період",
  "miscellaneous.share_feedback": "Поділитися враженнями",
  "miscellaneous.share_your_suggestion": "Поділіться своєю пропозицією",
  "miscellaneous.show_all": "Показати все",
  "miscellaneous.status": "Статус",
  "miscellaneous.sub_groups": "Підгрупи",
  "miscellaneous.this_month": "Цього місяця",
  "miscellaneous.this_week": "Цього тижня",
  "miscellaneous.this_year": "Цього року",
  "miscellaneous.today": "Сьогодні",
  "miscellaneous.total": "Всього",
  "miscellaneous.total_net_amount": "Загальна сума нетто",
  "miscellaneous.w": "т",
  "miscellaneous.we_couldnt_find_anyone": "Ми не змогли нікого знайти",
  "miscellaneous.year": "рік",
  "miscellaneous.years": "років",
  "new_group.add": "Додати групу",
  "new_group.add_in_group": "Додайте нову групу нижче",
  "new_group.communication_group_info": "Вам не потрібно створювати власні групи для тренерів, керівників команд і батьків. Ми створюємо їх автоматично, коли комусь призначається одна з цих ролей.",
  "nif.active": "Активний",
  "nif.back_to_club_selection": "Назад до вибору клубу",
  "nif.cancel_club_membership": "Скасувати членство в клубі",
  "nif.cancel_club_membership_description": "{{ first_name }} {{ last_name }} також буде видалено з {{ group }} та всіх підгруп у Hoopit.",
  "nif.cancel_nif_club_membership": "Скасувати членство в клубі NIF",
  "nif.cancel_nif_sport_membership": "Скасувати спортивне членство NIF",
  "nif.cancel_sport_membership": "Скасувати спортивне членство",
  "nif.cancel_sport_membership_description": "{{ first_name }} {{ last_name }} також буде видалено з {{ group }} та всіх підгруп у Hoopit.",
  "nif.confirm_club_description": "Підтвердьте, до яких організацій і груп ви належите. Ви отримаєте рахунки на членські внески та плату за тренування для будь-яких груп, членом яких ви залишитеся.",
  "nif.confirm_club_membership": "Підтвердити членство в клубі",
  "nif.delete_from_nif": "Видалити з NIF",
  "nif.delete_from_nif_and_hoopit": "Видалити з NIF та Hoopit",
  "nif.delete_from_nif_only": "Видалити лише з NIF",
  "nif.delete_options": "Ви можете видалити {{ name }} з Hoopit в {{organization}} або лише видалити членство в NIF.",
  "nif.delete_user_from_nif": "Видалити {{ name }} з NIF",
  "nif.delete_user_from_nif_info": "Ви збираєтеся видалити членство NIF для",
  "nif.i_didnt_get_the_email": "Я не отримав листа",
  "nif.i_have_verified": "Я перевірив",
  "nif.initiate_description_one": "Завдяки синхронізації з Norges Idrettsforbund (NIF) ми гарантуємо, що інформація про членство актуальна. Це життєво важливо для державного фінансування клубу та зменшує стрес для всіх, хто наполегливо працює для клубу.",
  "nif.initiate_description_two": "Перевірка складається з двох кроків. Додайте тут необхідну інформацію, а потім підтвердьте перевірку за допомогою NIF. Якщо у вас є діти, вам доведеться повторити процес.",
  "nif.initiate_heading": "Ми синхронізуємося з Norges Idrettsforbund!",
  "nif.members": "Члени NIF",
  "nif.membership": "Членство",
  "nif.min_2_characters": "Мінімум 2 символи",
  "nif.mobile_number_error": "Введіть дійсний номер мобільного телефону",
  "nif.nationality": "Національність",
  "nif.next": "Далі",
  "nif.nif_gender_info": "NIF дозволяє використовувати лише біологічну стать у своїх реєстрах.",
  "nif.nif_ssn_info": "Ця інформація ніколи не зберігається у нас. NIF підтверджує персональний ідентифікаційний номер норвезького громадянства безпосередньо в Національному реєстрі громадян.",
  "nif.nif_verified": "Перевірено NIF",
  "nif.not_a_member": "Не учасник",
  "nif.not_verified": "Не підтверджено",
  "nif.ok_i_understand": "Добре, я зрозумів!",
  "nif.one_more_important_step": "Ще один важливий крок!",
  "nif.pending": "В очікуванні",
  "nif.phone": "Номер мобільного",
  "nif.post_code": "Поштовий індекс",
  "nif.processing_information": "Обробка інформації",
  "nif.search_by_name": "Пошук",
  "nif.select_who_would_be_verified": "Виберіть, кого буде перевірено",
  "nif.ssn_length_validation_message": "Номер соціального страхування має містити 11 символів",
  "nif.status_member": "Учасник",
  "nif.status_pending": "В очікуванні",
  "nif.status_unknown": "Невідомий",
  "nif.total_groups": "У {{ count }} групах",
  "nif.users": "Користувачі",
  "nif.verification_submission_description": "Ви отримаєте SMS або електронний лист від Норвезької спортивної асоціації з підтвердженням перевірки в Hoopit. Якщо ви не підтвердите це протягом 24 годин, вам доведеться почати весь процес зпочатку. Тим часом ви можете використовувати Hoopit як зазвичай!",
  "nif.verified": "Перевірено",
  "nif.verify": "Підтвердити",
  "nif.verify_form_description": "Ось інформація, яку ми маємо про профіль. Важливо переконатися, що інформація правильна, і вам доведеться ввести те, чого не вистачає.",
  "nif.verify_nif_membership": "Підтвердьте членство в NIF",
  "nif.verify_user": "Почніть перевірку",
  "no_content.back_to_homepage": "Повернутися на головну сторінку",
  "no_content.browser_not_supported": "Браузер не підтримується, будь ласка, використовуйте Microsoft Edge, Google Chrome, Mozilla Firefox або Safari",
  "no_content.no_claims_found": "Позовів не знайдено",
  "no_content.no_invoices_found": "Рахунки-фактури не знайдено",
  "no_content.no_invoices_found_group": "У {{group}} не знайдено рахунків-фактур",
  "no_content.no_not_paid_payments": "Нема неоплачених платежів",
  "no_content.no_orders_found": "Замовлень не знайдено",
  "no_content.no_orders_found_group": "У {{group}} замовлень не знайдено",
  "no_content.no_payments_found": "Платежі не знайдено",
  "no_content.no_payments_found_group": "У {{group}} платежів не знайдено",
  "no_content.no_payouts_found_group": "У {{group}} не знайдено виплат",
  "no_content.no_users_found": "Користувачів не знайдено",
  "no_content.no_users_found_group": "У {{group}} користувачів не знайдено",
  "no_content.ooops": "Ой!",
  "no_content.page_doesnt_exist": "Вибачте, ця сторінка не існує.",
  "no_content.page_doesnt_exist_description": "Схоже, що ви намагаєтеся отримати доступ до сторінки, яка або була видалена, або ніколи не існувала.",
  "onboarding.activate_child_user": "Попросіть одного з батьків активувати ваш обліковий запис у програмі в розділі <strong>Дитина</strong> в налаштуваннях.",
  "onboarding.add_another_guardian_to": "Додайте іншого родителя",
  "onboarding.add_child_in": "Додайте своїх дітей",
  "onboarding.add_child_in_other_group": "Якщо ви хочете додати інших дітей до іншої команди",
  "onboarding.add_child_to_join_group": "Для підключення до групи потрібно додати дитину.",
  "onboarding.add_guardian_hint_part_1": "Ви можете підключити до дитини іншого родителя за допомогою",
  "onboarding.add_guardian_hint_part_2": "або поділившись з ними посиланням для реєстрації.",
  "onboarding.add_new_child_in": "Додайте нову дитину",
  "onboarding.add_user": "Якщо  <strong>{{ name }}</strong> - цу ваша дитина, натисніть <strong>Додати</strong>, щоб додатися його батьком.",
  "onboarding.already_a_member": "Ви вже зареєстровані в цій групі.",
  "onboarding.already_registered": "Користувач з цим номером мобільного вже зареєстрований.\nБудь ласка, використовуйте наступне посилання для входу.",
  "onboarding.choose_existing_users": "Якщо ви не впевнені, якого користувача вибрати.",
  "onboarding.confirm_user_info": "Бажаєте додати <strong> {{ name }} </strong> як нового користувача? Ще раз перевірте правильність імені та дати народження 🤓",
  "onboarding.contact_us": "Зв'яжіться з нами в чаті праворуч внизу",
  "onboarding.create_new_user": "Створити нового користувача для {{ firstName }} {{ lastName }}",
  "onboarding.current_members_number": "Учасники {{ membersNumber }} з {{ allowedMembersNumber }}",
  "onboarding.enter_auth_phone_number": "Будь ласка, введіть свій номер мобільного телефону",
  "onboarding.enter_code": "Введіть код",
  "onboarding.enter_code_description": "Будь ласка, введіть код підтвердження, надісланий на ваш мобільний пристрій.",
  "onboarding.enter_full_name": "Будь ласка, введіть своє повне ім'я без скорочень",
  "onboarding.forgot_password": "Забули свій пароль? Натисніть тут.",
  "onboarding.have_user": "Вже маєте користувача?",
  "onboarding.join_group": "Приєднання до {{ groupName }}",
  "onboarding.max_members_number_text": "На жаль, ця група вже досягла максимальної кількості користувачів ({{ allowedMembersNumber }})",
  "onboarding.member": "Учасник",
  "onboarding.multiple_users_found": "Існує кілька користувачів для {{ name }}",
  "onboarding.no_children_in_group_in": "У вас немає зареєстрованих дітей",
  "onboarding.not_correct_user": "Чи не {{ name }} ви хочете додати?",
  "onboarding.one_user_found": "{{ name }} уже зареєстрований в Hoopit",
  "onboarding.our_instructions": "наші інструкції",
  "onboarding.parent": "Родитель",
  "onboarding.phone_number_exists": "Цей номер телефону вже зареєстровано в нашій системі. Спробуйте увійти або запитайте новий пароль.",
  "onboarding.read_on_support": "Детальніше про активацію користувача читайте тут",
  "onboarding.reason_for_this_info": "Якщо ви є батьком, нам потрібна ваша дата народження для ідентифікації. Дата народження не буде показана іншим, крім вас.",
  "onboarding.register_as_associate_member_description": "Асоційовані учасники важливі для клубу, і гроші йдуть на добрі цілі. Крім того, асоційовані члени враховуються у фінансовій підтримці, яку ми отримуємо від Норвезької спортивної асоціації.",
  "onboarding.register_as_associate_member_title": "Бажаєте зареєструватися як асоційований учасник?",
  "onboarding.register_as_member": "Зареєструватися як учасник",
  "onboarding.register_as_member_description": "Учасник бере участь у заходах, організованих цією групою.",
  "onboarding.register_as_member_title": "Ви хочете зареєструватися як учасник?",
  "onboarding.register_as_parent": "Зареєструватися як родитель",
  "onboarding.register_as_parent_info": "Якщо ви хочете зареєструвати свою дитину, вам спочатку потрібно зареєструвати себе як одного з батьків.",
  "onboarding.register_in_other_group": "Якщо ви хочете приєднатися до іншої команди",
  "onboarding.register_new_user": "Зареєструйте нового користувача",
  "onboarding.select_role": "Яка ваша роль у {{groupName}}?",
  "onboarding.skip_adding_child": "Ви вирішили зареєструватися як батьки.\nЯкщо ви не додасте дітей, ви не будете підключені до групи.",
  "onboarding.to_registration": "До реєстрації клубу",
  "onboarding.user_should_exists": "Якщо ви знаєте або вважаєте, що <strong> {{ name }} </strong> уже зареєстровано, ви можете зв’язатися з нами, керівником групи чи іншими батьками<strong>{{ name }}</strong>.",
  "onboarding.welcome_to": "Ласкаво просимо до",
  "onboarding.why_we_need_birthday": "Навіщо нам ця інформація?",
  "onboarding.your_children": "Ваші зареєстровані діти",
  "onboarding.your_children_in": "Ваші діти зареєструвалися",
  "onboarding.your_children_in_waiting_list": "Ваші діти у списку очікування",
  "orders.bought_out": "Викуплено",
  "orders.confirmed": "Підтверджено",
  "orders.edit_dugnad_order_description_1_1": "{{ name }} має замовити щонайменше {{ number }} товарів.",
  "orders.edit_dugnad_order_description_1_2": "Учасник може розподілити кількість товарів на свій розсуд до моменту проходження терміну підтвердження або до фактичного підтвердження замовлення.",
  "orders.edit_dugnad_order_description_2_1": "{{ name }} має замовити не більше {{ number }} товарів.",
  "orders.edit_dugnad_order_description_2_2": "Учасник може розподілити кількість товарів на свій розсуд до моменту проходження терміну підтвердження або до фактичного підтвердження замовлення.",
  "orders.edit_dugnad_order_description_3_1": "{{ name }} має придбати щонайменше {{ number }} товарів.",
  "orders.edit_dugnad_order_description_3_2": "Учасник може розподілити кількість товарів на свій розсуд",
  "orders.edit_dugnad_order_description_4_1": "{{ name }} має придбати не більше {{ number }} товарів.",
  "orders.edit_dugnad_order_description_4_2": "Учасник може розподілити кількість товарів на свій розсуд",
  "orders.exempted": "Виключено",
  "orders.items": "Одиниць",
  "orders.not_confirmed": "Не підтверджено",
  "orders.number_of_items_for": "Кількість",
  "organization.add_admin": "Додати адміністратора",
  "organization.add_guardian": "Додати родителя",
  "organization.add_members": "Додавання учасників",
  "organization.add_members_to_another_group": "Додати {{ number }} учасників до іншої групи?",
  "organization.add_member_to_another_group": "Додати {{name}} до іншої групи?",
  "organization.add_player": "Додати гравця",
  "organization.add_to_another_group": "Додати до іншої групи",
  "organization.admin_is_being_deleted": "{{ firstName }} {{ lastName }} видаляється як адміністратор, це займе деякий час",
  "organization.admins_are_being_deleted": "{{ adminsCount }} адміністраторів видаляються, це займе деякий час",
  "organization.admins_that_will_be_deleted": "Адміністратори, яких буде видалено:",
  "organization.all_posts": "Всі записи",
  "organization.attendance": "Відвідуваність",
  "organization.attendance_statistics": "Статистика відвідуваності",
  "organization.chat": "Чат",
  "organization.contact_info": "Контактна інформація",
  "organization.delete_admin": "Видалити адміністратора",
  "organization.delete_admins": "Видалити адміністраторів",
  "organization.delete_admins_description": "Ви впевнені, що хочете видалити <b>{{ adminsCount }}</b> адміністраторів із <b>{{ group }}</b>?",
  "organization.delete_from_current_group": "Видалити з поточної групи",
  "organization.delete_member": "Видалити учасника",
  "organization.delete_member_description_1": "Ви впевнені, що хочете видалити <b>{{ firstName }} {{ lastName }}</b> з <b>{{ group }}</b>?",
  "organization.delete_member_description_2": "Вони залишаться зареєстрованими в системі та можуть бути знову додані пізніше.",
  "organization.delete_player": "Видалити гравця",
  "organization.delete_players": "Видалити гравців",
  "organization.delete_players_description": "Ви впевнені, що хочете видалити <b>{{ playersCount }}</b> гравців із <b>{{ group }}</b>?",
  "organization.delete_post": "Видалити піблікацію?",
  "organization.delete_post_confirmation": "Якщо продовжити, публікація, а також усі включені коментарі буде видалено.",
  "organization.filter": "Фільтр",
  "organization.filters": "Фільтри",
  "organization.in_this_group": "в цій групі.",
  "organization.invite_members": "Запросити учасників",
  "organization.invite_members_to_another_group": "Запросити {{number}} учасників до іншої групи?",
  "organization.invite_member_to_another_group": "Запросити {{name}} до іншої групи?",
  "organization.invite_to_another_group": "Запросити в іншу групу",
  "organization.invite_to_relevant_groups": "Будь ласка, запросіть цих учасників до відповідних груп і видаліть їх із групи <b>{{ group }}</b>.",
  "organization.media_permission_hint": "«Дозволяє внутрішньо» — учасник дозволяє публікацію всередині Hoopit; «Дозволяє публічно» — учасник дозволяє публікувати повідомлення всередині та публічно (за межами Hoopit).",
  "organization.member_add_success": "{{ name }} додається у {{ group }}, на це може знадобитися деякий час.",
  "organization.member_invite_success": "{{name}} запрошується до {{group}}, це може зайняти деякий час.",
  "organization.member_move_success": "{{name}} переміщується в {{group}}, це може зайняти деякий час.",
  "organization.member_moving_success_message": "{{ userName }} переходить до {{ groupName }}, це може зайняти деякий час.",
  "organization.members_add_success": "{{ number }} учасників додаються у {{ group }}, на це може знадобитися деякий час.",
  "organization.members_deleting_success_message": "{{ membersCount }} учасників видаляються з {{ groupName }}, це може зайняти деякий час.",
  "organization.members_invite_success": "{{number}} учасників запрошуються до {{group}}, це може зайняти деякий час.",
  "organization.members_moved": "Учасники, яких буде переміщено:",
  "organization.members_move_success": "{{number}} учасників переміщуються до {{group}}, це може зайняти деякий час.",
  "organization.members_moving_success_message": "{{ membersCount }} учасників переходять до {{ groupName }}, це може зайняти деякий час.",
  "organization.members_to_add": "Учасники, які додаються",
  "organization.members_to_invite": "Запросити учасників",
  "organization.members_to_move": "Перемістити учасників",
  "organization.min_characters_message": "Мінімум {{ minCharRequirement }} символи",
  "organization.move_members": "Перемістити учасників",
  "organization.move_members_to_another_group": "Перемістити {{ number }} учасників до іншої групи?",
  "organization.move_member_to_another_group": "Перемістити {{ name }} до іншої групи?",
  "organization.move_to_another_group": "Перейти до іншої групи",
  "organization.no_group_selected": "Не вибрано жодної групи",
  "organization.no_permission": "Немає дозволу",
  "organization.not_set": "Не встановлено",
  "organization.no_users": "Користувачів не знайдено",
  "organization.oops_it_seems_that_you_have": "Ой! Здається, що ви маєте",
  "organization.permits_internally": "Дозволяє внутрішньо",
  "organization.permits_publicly": "Дозволяє публічно",
  "organization.player_is_being_deleted": "{{ firstName }} {{ lastName }} видаляється як гравець, це займе деякий час",
  "organization.players_are_being_deleted": "{{ playersCount }} гравців видаляються, це займе деякий час",
  "organization.players_that_will_be_deleted": "Гравці, яких буде видалено:",
  "organization.posts": "Публікації",
  "organization_registration.add_club_account": "Додати обліковий запис клубу",
  "organization_registration.add_club_account_description": "Будь ласка, введіть номер свого рахунку для обробки платежів.",
  "organization_registration.bank_account_added": "Банківський рахунок додано!",
  "organization_registration.begin_stripe_integration": "Інтеграція Stripe Connect",
  "organization_registration.begin_stripe_integration_description": "Hoopit використовує Stripe для безпечної обробки платежів. Наступним кроком буде додавання банківського рахунку, щоб ви могли здійснювати платежі у своїй організації.",
  "organization_registration.check": "Перевірити",
  "organization_registration.club_registration": "Реєстрація команди",
  "organization_registration.confirm_personal_information": "Підтвердити особисту інформацію",
  "organization_registration.go_to_stripe": "Перейдіть до Реєстрації Stripe",
  "organization_registration.invalid_account_number": "Наданий номер рахунку недійсний",
  "organization_registration.log_in": "логін",
  "organization_registration.next": "Далі",
  "organization_registration.payments_enabled": "Платежі включені!",
  "organization_registration.payments_enabled_message_one": "Підтвердження Stripe Connect для вашого рахунку з номером <strong> **** ** *{{account_numbers_last_four}} </strong> завершено",
  "organization_registration.payments_enabled_message_two": "Наступний крок – створення спеціальних видів спорту та запрошення лідерів. Потім їм буде покладено відповідальність за створення відповідних відділів.",
  "organization_registration.personal_information_description_one": "Щоб створити спортивну команду, необхідно, щоб надана вами особиста інформація була вашою.",
  "organization_registration.personal_information_description_two": "Ви будете зареєстровані як власник облікового запису в нашому платіжному провайдері",
  "organization_registration.privacy_policy": "Політика конфіденційності",
  "organization_registration.proceed_to_stripe_to_verify_account": "Stripe вимагає певної інформації, щоб дозволити обробку платежу",
  "organization_registration.register_description_part_one": "Щоб створити спортивну команду Hoopit, ви повинні спочатку зареєструватися як член правління.",
  "organization_registration.register_description_part_two": "Якщо у вас уже є користувач, ви можете увійти та створити спортивну команду з ним.",
  "organization_registration.register_personal_information": "Зареєструвати особисту інформацію",
  "organization_registration.register_user_heading": "Заповніть інформацію про користувача як голова.",
  "organization_registration.register_user_information": "Реєстрація інформації про користувача",
  "organization_registration.set_up_divisions": "Створити дівізії",
  "organization_registration.sign_in_with_existing_user": "Увійдіть під своїм існуючим користувачем.",
  "organization_registration.skip_divisions_descriptions": "Коли запрошення буде розіслано, ви більше не зможете додавати більше підрозділів за цим посиланням. Ви можете зробити це пізніше на своїй сторінці Hoopit.",
  "organization_registration.skip_invitations": "Розіслати запрошення?",
  "organization_registration.step_count": "Крок {{number}} із {{total}}",
  "organization_registration.stripe_error_description": "Якщо ви бачите цей екран, то виникла одна з таких проблем:\n - Не було надано необхідну інформацію для успішного підключення Stripe Connect\n - Посилання застаріле\n - Сталася технічна помилка.\n Будь ласка спробуйте ще раз",
  "organization_registration.terms_of_use": "Умови використання",
  "organization_registration.verification_incomplete": "Перевірку майже завершено",
  "organization_registration.verification_incomplete_decription": "Натисніть кнопку «Далі», щоб завершити реєстрацію, завантажити свій ідентифікатор і підтвердити обліковий запис.",
  "organization_registration.verification_not_complete": "Перевірка не завершена",
  "organization.search_by_name": "Пошук за назвою",
  "organization.select_group": "Виберіть групу в меню ліворуч.",
  "organization.select_group_for_addition": "Будь ласка, оберіть групу, до якої будуть додані учасники.",
  "organization.select_group_for_invitation": "Будь ласка, виберіть групу, до якої будуть запрошені учасникі.",
  "organization.select_group_for_move": "Будь ласка, виберіть групу, до якої будуть переміщені учасникі.",
  "organization.select_group_warning": "Зверніть увагу, що у новій групі вони не матимуть номер на футболці.",
  "organization.select_member_group": "Будь ласка, виберіть групу, учасника якої буде переміщено.<br/>Зверніть увагу, що переміщений гравець не матиме номера футболки в новій групі.",
  "organization.select_members_group": "Будь ласка, виберіть групу, до якої буде переміщено учасників.<br/>Зауважте, що переміщені гравці не матимуть номерів на футболках у новій групі.",
  "organization_selector.companies": "Компанії",
  "organization_selector.select_organization": "Виберіть організацію",
  "organization.settings": "Налаштування",
  "organization.this_group_has_x_players": "Ця група має {{ playersCount }} / {{ allowedPlayersCount }} гравців",
  "organization.title": "Назва",
  "organization.total_admin": "адміністратор у {{ group_name }}",
  "organization.total_admins": "адміністратори в {{ group_name }}",
  "organization.total_guardian": "родитель у {{ group_name }}",
  "organization.total_guardians": "батьки в {{ group_name }}",
  "organization.total_player": "гравець у {{ group_name }}",
  "organization.total_players": "гравці в {{ group_name }}",
  "organization.user_deleted_from_group": "{{ userName }} видалено з {{ groupName }}.",
  "organization.yes_move": "Так, перемістити",
  "organization.your_posts": "Ваші записи",
  "password.expired": "Термін дії посилання закінчився, надішліть новий запит на скидання пароля.",
  "password.forgot": "Забули пароль",
  "password.link_sent": "Запит на скидання пароля надіслано на вашу електронну адресу.",
  "password.new": "Перейдіть у додаток, щоб увійти за допомогою номера мобільного телефону та нового пароля.",
  "password.remembered": "Запам'ятав це",
  "password.reset": "Скинути пароль",
  "password.reset_request_info": "Введіть номер свого мобільного телефону нижче та отримайте посилання для скидання на вашу зареєстровану електронну адресу.",
  "password.reset_request_sent": "Невдовзі вам буде надіслано електронний лист, де ви зможете змінити свій пароль.",
  "password.reset_request_to_spam": "NB! Якщо ви не отримаєте посилання протягом кількох хвилин, перевірте папку зі спамом.",
  "password.update": "Оновити пароль",
  "password.update_success": "Ваш пароль оновлено!",
  "payment.": "Оплата",
  "payment.account": "Рахунок",
  "payment.all_payments": "Всі платежі",
  "payment.automatic_reminder": "Автоматичне",
  "payment.automatic_reminder_info": "Автоматичні нагадування – це ті, які надсилає Hoopit. Вони надсилаються через 2, 5 і 7 днів після закінчення кінцевого терміну оплати. Ми також надсилаємо нове нагадування щотижня після закінчення кінцевого терміну оплати.",
  "payment.back_to_payment_details": "Повернутися до реквізитів платежу",
  "payment.count": "Кількість",
  "payment.custom_price": "Індивідуальна ціна",
  "payment.decision_confirmation_description": "Ця дія буде впливати на всі групові платежі цієї плати за тренування.",
  "payment.decision_confirmation_title": "Застосовувати зміни до всієї плати за тренування?",
  "payment.declined": "Відхилено",
  "payment.delete_modal_description": "{{ paymentName }} та всі пов'язані з ним платежі користувачів будуть видалені. Цю дію не можна скасувати.",
  "payment.delete_modal_description_2": "Зауважте, що цей платіж є обов’язковим для вступу і якщо його видалити, нові гравці зможуть приєднатися до цих груп без сплачення цього платежу",
  "payment.delete_modal_title": "Видалити платіж?",
  "payment_details.comment_not_added": "Не додано",
  "payment_details.delay_for_new_members": "Відстрочити для нових членів",
  "payment_details.no_invoices_description": "Ви можете створити рахунок-фактуру, щоб дозволити користувачеві здійснити оплату за межами Hoopit.",
  "payment_details.no_invoices_title": "Історія рахунків-фактур відсутня",
  "payment_details.no_refunds_description": "За необхідності ви можете повернути повну суму, або лише її частину.",
  "payment_details.no_refunds_title": "Історія відшкодувань відсутня",
  "payment_details.no_reminders_description": "Ви можете нагадати цьому користувачеві здійснити оплату, надіславши нагадування.",
  "payment_details.no_reminders_title": "Історія нагадувань відсутня",
  "payment_details.ongoing_payment": "Активний платіж",
  "payment_details.publish_date_error": "Не може бути раніше дати публікації платежу",
  "payment_details.publish_date_hint": "Платіж буде надіслано користувачеві в цю дату",
  "payment.due_date": "Термін виконання",
  "payment.edit_user_payment_for": "Редагувати платіж для",
  "payment.exempted": "Анульовано",
  "payment.exempted_payments": "Онульовані платежі",
  "payment.here": "тут",
  "payment.last_reminder": "Останнє нагадування",
  "payment.last_reminder_date": "Дата останнього нагадування",
  "payment.left_user": "Користувачи, які покинули",
  "payment.manage_recipients": "Керувати одержувачами",
  "payment.manual_reminder": "Ручне",
  "payment.name": "Ім'я",
  "payment.new_economy": "нова економіка",
  "payment.new_page_hint": "Ви можете змінити суму в",
  "payment.new_reminder": "Нове нагадування",
  "payment.new_reminder_info": "Оригінал платіжного повідомлення все одно включено. Нагадування надсилається як у додатку, так і на пошту.",
  "payment.new_reminder_into_to_all": "Ви можете додати повідомлення тим, хто не заплатив.",
  "payment.new_reminder_into_to_one": "Ви можете додати повідомлення до",
  "payment.new_reminder_to": "Нове нагадування для",
  "payment.no_payments_sent": "Платежі ще не надіслано",
  "payment.not_charged": "Не стягується",
  "payment.not_paid": "Не оплачено",
  "payment.not_published": "Не опубліковано",
  "payment.ongoing_payments": "Поточні платежі",
  "payment.on_hold": "На утриманні",
  "payment.optional": "Додатково",
  "payment.overdue": "Прострочено",
  "payment.paid": "Оплачено",
  "payment.paid_manually": "Оплачено вручну",
  "payment.past_payments": "Минулі платежі",
  "payment.payment_created": "Платіж створено",
  "payment.payment_due_date": "Термін оплати",
  "payment.payment_publish_date": "Дата публікації платежів",
  "payment.payment_updated": "Платіж оновлено",
  "payment.payment_update_success": "Платіж для {{ name }} оновлено",
  "payment.payment_was_successfully_created": "Платіж успішно створено",
  "payment.payment_was_successfully_deleted": "{{ paymentName }} видаляється. Це може зайняти деякий час.",
  "payment.payment_was_successfully_updated": "Платіж був успішно оновлений.",
  "payment.project": "Проект",
  "payment.publish": "Опублікувати",
  "payment.publish_date": "Дата публікації",
  "payment.publish_payment_modal_description": "Встановити нову дату публікації для {{PaymentName}}.",
  "payment.publish_payment_modal_description_2": "Зауважте, що цей платіж є обов’язковим для вступу і після його повторної публікації новим гравцям буде надано 15 хвилин для оплати, щоб приєднатися до цих груп",
  "payment.publish_payment_modal_title": "Опублікувати платіж?",
  "payment.put_on_hold": "Поставте на утримання",
  "payment.put_on_hold_modal_description": "Статус {{PaymentName}} буде змінено на «Утримується», і користувачі не зможуть оплатити цей платіж у додатку. Ви можете повторно опублікувати цей платіж у будь-який час.",
  "payment.put_on_hold_modal_description_2": "Зауважте, що цей платіж є обов’язковим для вступу і якщо його призупинити, нові гравці не зможуть приєднатися до цих груп",
  "payment.put_on_hold_modal_title": "Поставити платіж на утримання?",
  "payment.quantity": "Кількість",
  "payment.read_more_about_payments": "Докладніше про платежі в Hoopit",
  "payment.reminder_history": "Історія нагадувань",
  "payment.reminder_history_description": "Hoopit надсилає автоматичні нагадування через 2, 5 та 7 днів після закінчення терміну оплати, а потім раз на тиждень. Нагадування надсилаються як електронні листи та push-сповіщення в програмі. Ви також можете надіслати нове нагадування вручну.",
  "payment.reminder_history_for": "Історія нагадувань для",
  "payment.reminder_notification_info": "Нагадування надсилаються електронною поштою та push-повідомленнями всім, хто має програму.",
  "payment_report_list.no_payment_reports": "Немає звітів про оплату",
  "payment_report_list.payment_report": "Звіт про оплату",
  "payment_report_list.payout": "Виплата",
  "payment_reports.account_number": "Номер рахунку",
  "payment_reports.account_number_tokenized": "\nНомер рахунку: **** ** *",
  "payment_reports.arrival_date": "Очікується на рахунку",
  "payment_reports.bank_account_payments_description": "Платежі на ваш банківський рахунок позначені «SAXO PAYMENTS A/S». Ця компанія здійснює всі платежі в системі.",
  "payment_reports.date": "Дата",
  "payment_reports.error_occured": "У системі сталася помилка, неможливо отримати вкладення за період",
  "payment_reports.group": "Група",
  "payment_reports.hide_all_transactions": "Приховати всі транзакції",
  "payment_reports.name": "Ім'я",
  "payment_reports.net_to_account": "Нетто на рахунок",
  "payment_reports.no_arrival_date": "Ще не оплачено",
  "payment_reports.no_payment": "Без оплати за період",
  "payment_reports.outstanding": "Без оплати",
  "payment_reports.paid": "Оплачений",
  "payment_reports.payment": "Платіж",
  "payment_reports.payment_description": "Платежі на ваш банківський рахунок позначені «SAXO PAYMENTS A/S».\nЦя компанія здійснює всі платежі в системі.",
  "payment_reports.payment_report": "Звіт про оплату",
  "payment_reports.pays": "Платить",
  "payment_reports.period": "Крапка",
  "payment_reports.sum": "Сума",
  "payment_reports.sum_for": "Сума для",
  "payment_reports.total_amount": "Підсумкова сума",
  "payment_reports.total_fee": "Підсумкова комісія за транзакцію",
  "payment_reports.total_paid": "Виплачена сума",
  "payment_reports.transaction_fee": "Комісія за транзакцію",
  "payment_reports.transactions_for": "Транзакції для",
  "payment_reports.transferred_to": "Переведено на {{ account_name }}",
  "payment_reports.transferred_to_main_account": "Переведено на основний рахунок",
  "payment_reports.view_all_transactions": "Переглянути всі транзакції",
  "payment.send_reminder": "Надіслати нове нагадування",
  "payment.send_reminders": "Надіслати нагадування",
  "payment.set_custom_price": "Встановити індивідуальну ціну",
  "payment.sum": "Сума",
  "payment_table.outstanding": "Без оплати",
  "payment_table.paid": "Оплачений",
  "payment.tf_is_being_created": "Платіж створюється. Це може зайняти деякий час.",
  "payment.to_see_transaction_fee": "щоб побачити точну комісію за транзакцію та контролювати, чи включена вона в ціну.",
  "payment.total": "Всього",
  "payment.total_payments": "Платежі відправлені",
  "payment.total_sum": "Загальна сума",
  "payment.type": "Тип",
  "payment.upcoming_payments": "Найближчі платежі",
  "payment.use": "Використовувати",
  "payment.user_payment_price_categories_description": "Це доступна цінова категорія для {{ firstName }} {{ lastName }}. Натомість ви також можете встановити спеціальну суму.",
  "payment.user_payment_price_categories_description_2": "Це доступні цінові категорії для {{ firstName }} {{ lastName }}. Натомість ви також можете встановити спеціальну суму.",
  "payouts.arrival_date": "Дата прибуття",
  "payouts.by_arrival_date": "За датою прибуття",
  "payouts.download": "Завантажити звіт (PDF)",
  "payouts.failed": "Не вдалося",
  "payouts.in_progress": "В процесі",
  "payouts.insufficient_funds": "Недостатньо коштів",
  "payouts.insufficient_funds_information": "Ми почнемо процес виплати щойно Нетто на рахунок досягне 20 kr.",
  "payouts.in_transfer": "Переказується",
  "payouts.in_transfer_information": "Stripe вже обробляє виплату",
  "payouts.open": "Відкритий",
  "payouts.paid_out": "Виплачено",
  "payouts.payouts_label_hint": "Кожна виплата містить усі платежі та комісії за вибраний період часу. Ви можете завантажити звіти про виплачені виплати.",
  "payouts.ready_for_transfer": "Готово до переказу",
  "payouts.ready_for_transfer_information": "Stripe скоро почне обробку виплати",
  "payouts.status": "Статус",
  "payouts.total_net_to_account": "Всього нетто на рахунок: {{ netAmount }}",
  "post_create.add_attachment": "Додайте вкладення",
  "post_create.added_attachments": "Додані вкладення",
  "post_create.added_photos": "Додані фото",
  "post_create.add_images": "Додайте свої зображення сюди",
  "post_create.add_photos": "Додайте фотографію",
  "post_create.add_post": "Напишіть свій запис тут",
  "post_create.add_post_title": "Напишіть назву для запису",
  "post_create.attachments": "Додайте вкладення",
  "post_create.change": "Змінити",
  "post_create.choose_different_group": "Вибрати іншу групу?",
  "post_create.delete_photo": "Видалити фото?",
  "post_create.disable_comments": "Вимкнути та приховати коментарі",
  "post_create.edit": "Редагувати",
  "post_create.everyone": "Всі",
  "post_create.file_size_validation_message": "Розмір файлу не має перевищувати {{ maxFileSize }} Мб",
  "post_create.file_type_validation_message": "Файли типу {{ type }} заборонені. Дозволені типи: {{ allowedTypes }}",
  "post_create.for": "для",
  "post_create.format_not_supported": "цей формат файлу не підтримується",
  "post_create.in": "в",
  "post_create.leaders_guardians": "Лідери та батьки",
  "post_create.loading": "Завантаження...",
  "post_create.max_number_validation_message": "Максимальна кількість додатків для публікації становить {{ maxNumber }}",
  "post_create.new": "Новий",
  "post_create.no_one_will_be_able_to_comment_on_this_post.": "Ніхто не зможе коментувати цю публікацію.",
  "post_create.not_enough_credits_hint": "Недостатньо кредитів. Щоб скористатися цією функцією, зверніться до адміністраторів організації.",
  "post_create.notification_methods_description": "За замовчуванням, користувачі отримують сповіщення в додатку, але тут ви можете вибрати додаткові способи сповіщень, щоб переконатися, що всі побачать цю публікацію.",
  "post_create.notification_methods_title": "Додаткові способи сповіщення учасників",
  "post_create.notify_members_via_sms": "Сповістити за допомогою SMS",
  "post_create.notify_members_via_sms_description": "Ми надішлемо SMS повідомлення всім користувачам, які мають переглянути публікацію.",
  "post_create.notify_via_email": "Сповістити електронним листом",
  "post_create.notify_via_email_description": "Ми надішлемо електронний лист усім користувачам, які мають переглянути публікацію. Зауважте, що навіть якщо ви не виберете цю опцію, користувачі, які не отримали сповіщення в додатку, усе одно отримають електронний лист.",
  "post_create.photo": "Фотографія",
  "post_create.post": "публікація",
  "post_create.post_confirmation": "Хто може бачити цю публікацію?",
  "post_create.post_images_limit": "Для кожного запису існує обмеження до 8 зображень.",
  "post_create.post_publish_question": "В яких групах опублікувати цю публікацію?",
  "post_create.post_save_confirmation_description": "Ви бажаєте повідомити учасників цієї групи про зміни в цій публікації?",
  "post_create.post_save_confirmation_title": "Зберегти та повідомити учасників про зміни?",
  "post_create.select_specific": "Хочете вибрати щось конкретне?",
  "post_create.send_message_question": "Куди ви хочете надіслати повідомлення?",
  "post_create.share_message": "Поділіться повідомленням з групою, відділом або клубом.",
  "post_create.this_group": "Лише ця група",
  "post_create.this_group_subgroups": "Ця група і всі підгрупи",
  "post_create.title": "Назва",
  "post_create.upload_failed_message": "Не вдалося завантажити файл",
  "post_create.visible_to": "Видно для",
  "post_detail.comment": "Коментар",
  "post_detail.comments": "Коментарі",
  "post_detail.comments_are_disabled": "Коментарі автора вимкнено для цієї публікації.",
  "post_detail.delete_comment": "Видалити коментар?",
  "post_detail.delete_post": "Видалити",
  "post_detail.edit_post": "Редагувати",
  "post_detail.everyone_seen_message": "Цю публікацію бачили всі!",
  "post_detail.no_one_seen_message": "Цю публікацію ще ніхто не бачив!",
  "post_detail.not_seen": "Не бачили",
  "post_detail.post_image": "Фотограція у публікації",
  "post_detail.post_views_not_seen_message": "Публікацію ще не бачили {{knownCount}} особи з ваших груп, а також {{unknownCount}} з інших.",
  "post_detail.post_views_seen_message": "Публікацію переглянуло {{knownCount}} осіб з ваших груп, а також {{unknownCount}} особи з інших.",
  "post_detail.seen": "Бачили",
  "post_detail.seen_by_hint": "Лише люди, які використовують додаток Hoopit (додаток, веб-додаток або панель адміністратора)",
  "post_detail.seen_status": "Перегляди поста",
  "post_detail.subgroups": "Підгрупи",
  "post_detail.visible_to_leaders": "Видно менеджерам",
  "post_detail.visible_to_leaders_guardians": "Видно лідерам і батькам",
  "post_detail.write_comments": "Напишіть свої коментарі тут",
  "post_file.attachment_name": "Назва додатка",
  "post_file.delete_attachment": "Видалити цей вкладений файл?",
  "post_file.file_with_similar_name": "Файл зі схожим ім’ям",
  "post_file.file_with_similar_name_description": "Ви намагаєтесь додати файл з ім’ям, яке вже існує, ви впевнені? Ви потім зможете змінити ім’я файла.",
  "post_file.name_max_length_validation_message": "Довжина назви додатка не повинна перевищувати 251 символ",
  "post_file.rename_attachment": "Перейменувати додатка",
  "posts.by_groups": "По групах",
  "posts.create_post_text": "Будь першим! Створіть публікацію за допомогою кнопки «Нова публікація».",
  "posts.delete_post": "Видалити публікацію?",
  "posts.delete_post_confirmation": "Якщо продовжити, публікація, а також усі включені коментарі буде видалено.",
  "posts.filter": "Фільтр",
  "posts.filters": "Фільтри",
  "posts.new_post": "Нова публікація",
  "posts.no_groups": "Групи не знайдено",
  "posts.no_posts": "Немає дописів",
  "posts.no_results": "Нічого не знайдено",
  "posts.notified_by_sms": "Сповіщено через SMS",
  "posts.other_filters_text": "Спробуйте інші фільтри",
  "posts.search_by_post_title": "Пошук за назвою",
  "posts.title_min_length_validation_message": "Назва повинна містити не менше 2 символів",
  "profile_picture.delete": "Видалити зображення",
  "profile_picture.delete_profile_picture": "Видалити зображення профілю",
  "profile_picture.delete_profile_picture_description": "Ви впевнені, що хочете видалити зображення профілю цього користувача?",
  "profile_picture.edit": "Редагувати",
  "profile_picture.profile_picture_title": "Зображення профілю",
  "profile_picture.upload": "Завантажити",
  "registered_members_list.club": "клуб-",
  "registered_members_list.connected_account": "Підключений рахунок",
  "registered_members_list.division": "Дівізія",
  "registered_members_list.export_excel": "Експорт Excel для всього клубу",
  "registered_members_list.group": "Група",
  "registered_members_list.member_list": "список учасників-",
  "registered_members_list.members_registered": "Зареєстровані учасники",
  "registered_members_list.parents_registered": "Батьки зареєстровані",
  "registered_members_list.register": "Зареєструвати",
  "registered_members_list.registered_invited": "Зареєстровано/запрошено",
  "registered_members_list.team_leader": "Керівник групи",
  "registered_members_list.teams_overview_message": "Тут ви можете побачити огляд усіх команд спортивної команди, кількість запрошених і зареєстрованих.",
  "registration_links.about_hoopit": "Про Hoopit",
  "registration_links.after_signup_message": "Завершивши реєстрацію, просто завантажте додаток Hoopit з App Store або Google Play і ввійдіть. Там ви знайдете всі свої дії 😃",
  "registration_links.contact_hoopit": "Якщо у вас є запитання або потрібна допомога, ви також можете зв’язатися з Hoopit у чаті внизу праворуч.",
  "registration_links.get_started_hoopit": "Ось як почати роботу з Hoopit",
  "registration_links.join_groups_welcome_message": "Ось усі групи",
  "registration_links.join_groups_welcome_message_2": "ви можете приєднатися! Щоб приєднатися до кількох груп, необхідно повернутися на цю сторінку.",
  "registration_links.log_in": "Увійти",
  "registration_links.log_out": "Вийти",
  "registration_links.no_groups_to_register": "Зараз немає груп, які можна зареєструвати!",
  "registration_links.registration_links_message": "Тут ви знайдете посилання для реєстрації на всі групи зі спеціальних видів спорту",
  "registration_links.registration_links_message_2": "тож ви як батько чи лікар можете зареєструватися. Групи з'являються тут безперервно в міру їх створення керівництвом.",
  "registration_links.support": "Підтримка",
  "registration_links.teams_groups_registration_description": "Нижче ви можете побачити всі групи клубу. Натисніть на посилання вашої групи, і ви потрапите на сторінку, де ви реєструєте себе та своїх дітей. Якщо у вас діти у різних групах, поверніться на цю сторінку та натисніть на посилання на правильну команду та зареєструйте свою дитину. Якщо ви хочете зареєструвати більше дітей у різні групи, ви можете увійти безпосередньо в ці групи після першої реєстрації.",
  "sms.cost": "Вартість",
  "sms.credit": "кредит",
  "sms.credits": "кредити",
  "sms.current_balance": "Поточний баланс",
  "sms.economy_manager_role_is_required": "Необхідно мати роль «Менеджер економіки»",
  "sms.not_enough_credits": "Недостатньо кредитів",
  "sms.not_enough_credits_description": "Cхоже, останні кредити були щойно використані. Для поповнення балансу зверніться до адміністраторів організації.",
  "sms.not_enough_credits_hint": "Недостатньо кредитів. Щоб скористатися цією функцією, зверніться до адміністраторів організації.",
  "sms.notify_via_sms": "Нагадати за допомогою SMS",
  "sms.notify_via_sms_description": "Ми надішлемо SMS повідомлення, щоб нагадати про цей платіж.",
  "sms.price_description": "Стандартне SMS коштує 1 кредит.",
  "sms.publish_without_sms_notification": "Опублікувати без SMS повідомлення",
  "sms.request_more": "Запросити більше",
  "sms.sender_name": "Ім’я відправника",
  "sms.sender_name_description": "Одержувачі будуть бачити це ім’я.",
  "sms.sender_name_description_2": "Зверніть увагу, що перший символ має бути буквою. Решта можуть бути буквами, цифрами або пробілами.",
  "sms.sender_name_hint": "2-11 букв та цифр, перша з яких має бути буквою",
  "sms.sender_name_warning": "Оберіть найкраще ім’я відправника",
  "sms.sender_name_warning_description": "Ви можете використовувати назву вашої організації. Таким чином учасники отримуватимуть SMS повідомлення від імені вашого клубу.",
  "sms.sms_configuration_description": "Адміністратори клубів і дивізій мають можливість підвищити ефективність спілкування, надсилаючи SMS разом із push повідомленнями та електронними листами. Ми постійно розширюємо функції SMS сповіщень, тому зветрайтесь до служби підтримки, щоб поділитися своїми відгуками та ідеями.",
  "sms.sms_package_purchases": "SMS package purchases",
  "sms.sms_package_purchases_description": "Purchase SMS package here to enable quick and direct communication with your members.",
  "sms.subscription_active": "Subscription active",
  "sms.subscription_unavailable": "Subscription unavailable",
  "stripe_onboarding.additional_info_required": "Stripe вимагає додаткової інформації",
  "stripe_onboarding.additional_update_required": "Stripe вимагає додаткової інформації, тому це важливо, щоб вона була оновлена.",
  "stripe_onboarding.contact_organization_message": "Stripe вимагає додаткової інформації. Зв’яжіться з представником організації {{ organization_name }}, щоб надати необхідну інформацію",
  "stripe_onboarding.information_required": "Необхідна інформація",
  "stripe_onboarding.update_information": "Оновити інформацію",
  "stripe_onboarding.update_stripe_information": "Оновити інформацію Stripe",
  "stripe_onboarding.verification_pending": "Перевірка Stripe триває",
  "table.account": "Рахунок",
  "table.action": "Дія",
  "table.age": "Вік",
  "table.age_group": "Вікова група",
  "table.arrival_date": "Дата прибуття",
  "table.birth_date": "Дата народження",
  "table.claim_name": "Назва позову",
  "table.claim_status": "Статус позову",
  "table.comments": "Коментарі",
  "table.confirmation_due_date": "Термін підтвердження",
  "table.confirmation_status": "Статус підтвердження",
  "table.created_by": "Створив",
  "table.created_for": "Створено для",
  "table.current_balance": "Поточний баланс",
  "table.customer_supplier": "Замовник/Постачальник",
  "table.date": "Дата",
  "table.date_of_birth": "Дата народження",
  "table.description": "Опис",
  "table.division": "Дівізія",
  "table.due_date": "Термін виконання",
  "table.dugnad_name": "Назва Дугнаду",
  "table.email": "Електронна пошта",
  "table.end": "Кінець",
  "table.end_date": "Дата закінчення",
  "table.event_name": "Назва події",
  "table.expected_net_amount": "Очікувана чиста сума",
  "table.gender": "Стать",
  "table.generation_date": "Дата створення",
  "table.gross_amount": "Сума брутто",
  "table.group": "Група",
  "table.groups": "Групи",
  "table.group_type": "Тип групи",
  "table.has_certificate": "Має сертифікат",
  "table.hoopit_account": "Обліковий запис Hoopit",
  "table.information": "Інформація",
  "table.invoice_number": "Номер накладної",
  "table.jersey_number": "Номер футболки",
  "table.joined": "Учасникі, які приєднались",
  "table.joined_at": "Учасникі, які приєднались",
  "table.last_confirmation": "Остання спроба підтвердження",
  "table.last_edited_by": "Востаннє редаговано",
  "table.last_invitation": "Останнє запрошення надіслано",
  "table.last_invoice": "Останній рахунок",
  "table.last_nif_confirmation_reminder": "Останнє нагадування про підтвердження NIF",
  "table.last_reminder": "Останнє нагадування",
  "table.left": "Покинули",
  "table.location": "Місцезнаходження",
  "table.luxsave_keyword": "Ключове слово LuxSave",
  "table.media_permissions": "Фото та відео дозволи",
  "table.member": "Учасник",
  "table.name": "Ім'я",
  "table.nationality": "Національність",
  "table.net_amount": "Чиста сума",
  "table.net_to_account": "Нетто на рахунок",
  "table.nif_confirmation_status": "Статус підтвердження NIF",
  "table.nif_id": "Ідентифікатор особи NIF",
  "table.nif_membership": "Членство в NIF",
  "table.nif_status": "Статус NIF",
  "table.nif_status_details": "Деталі статусу NIF",
  "table.no_dugnads_found": "Не знайдено дугнадів",
  "table.no_dugnads_found_group": "У {{group}} не знайдено дугнадів",
  "table.no_members_yet": "Ще немає учасників",
  "table.no_results": "Нічого не знайдено",
  "table.no_results_group": "Не знайдено результатів в {{group}}",
  "table.notes": "Примітки",
  "table.not_paid": "Не оплачено",
  "table.number_of_items": "# елементів",
  "table.optional": "Додатково",
  "table.optional_to_pay": "Опціональний платіж",
  "table.order_progress": "Прогрес замовлення",
  "table.order_status": "Статус замовлення",
  "table.outstanding": "Несплачено",
  "table.outstanding_payment": "Несплачений платіж",
  "table.paid": "Оплачено",
  "table.paid_membership_fee": "Оплачений членськийий внесок",
  "table.paid_status": "Оплачено",
  "table.paid_training_fee": "Оплачені тренування",
  "table.pay_from": "Оплата з",
  "table.payment_date": "Дата оплати",
  "table.payment_due_date": "Термін оплати",
  "table.payment_name": "Назва платежу",
  "table.payment_progress": "Хід платежу",
  "table.payment_status": "Статус платежу",
  "table.payout_status": "Статус виплати",
  "table.pay_to": "Оплата до",
  "table.period": "Період",
  "table.period_end": "Кінець періоду",
  "table.period_start": "Початок періоду",
  "table.person_id": "Ідентифікатор особи",
  "table.phone_number": "Номер мобільного",
  "table.price": "Ціна",
  "table.price_category_name": "Назва цінової категорії",
  "table.processed_by": "Оброблено",
  "table.processed_date": "Дата обробки",
  "table.progress": "Прогрес",
  "table.publish_date": "Дата публікації",
  "table.push_notification": "Push повідомлення",
  "table.quantity": "Кількість",
  "table.receiver": "Отримувач",
  "table.refund_amount": "Сума відшкодування",
  "table.refunded_by": "Відшкодовано",
  "table.refunds": "Відшкодовано",
  "table.reminder": "Нагадування",
  "table.reminder_type": "Тип нагадування",
  "table.request_date": "Дата запиту",
  "table.request_status": "Статус запиту",
  "table.requires_certificate": "Потрібен сертифікат",
  "table.role": "Роль",
  "table.sms": "SMS",
  "table.something_went_wrong": "Щось пішло не так",
  "table.start": "Початок",
  "table.status": "Статус",
  "table.sub_accounts": "Субрахунки",
  "table.sub_group": "Підгрупа",
  "table.sum": "Сума",
  "table.survey_progress": "Хід опитування",
  "table.tag_name": "Назва тега",
  "table.tasks": "Завдання",
  "table.title": "Назва",
  "table.total_sum": "Загальна сума",
  "table.total_to_reimburse": "Загалом відшкодувати",
  "table.transaction_fee": "Комісія за транзакцію",
  "table.type": "Тип",
  "table.updated_by": "Оновлено",
  "table.venue_section": "Секція локації",
  "table.verified": "Перевірено",
  "table.verified_by": "Перевірено",
  "table.verified_by_nif": "Перевірено NIF",
  "table.voucher": "Ваучер",
  "table.voucher_number": "Номер ваучера",
  "table.year": "Рік",
  "team_account.allocated_sum": "Розподілено на субрахунки",
  "team_account.available_sum": "Доступно для {{group}}",
  "team_account.balance_at_end": "Залишок на кінець періоду",
  "team_account.balance_at_start": "Баланс на початок періоду",
  "team_account.change": "Зміна",
  "team_account.change_hint": "Зміна розраховується шляхом віднімання вхідних транзакцій від вихідних за вибраний період.",
  "team_account.current_balance": "Поточний баланс",
  "team_account.group_economy": "Групова економіка",
  "team_account.incoming": "Вхідні",
  "team_account.invalid_group_type_description": "Щоб переглянути баланс рахунку, оберіть групу, яка інтегрована з <b>Catacloud</b>.",
  "team_account.no_permission_description": "Лише <b>менеджери фінансів</b> поточної групи, або її батьківських груп мають доступ до цієї сторінки.",
  "team_account.no_transactions_found": "Транзакцій не знайдено",
  "team_account.outgoing": "Вихідні",
  "team_account.page_title": "Командні рахунки",
  "team_account.preview_description": "Це попередній перегляд функціоналу «Командні рахунки». Ми імпортуємо облікові дані з Catacloud і показуємо вам доступні кошти та транзакції команди безпосередньо в Hoopit!",
  "team_account.search_by_anything": "Шукати за будь-чим",
  "team_account.search_by_group_name": "Пошук за назвою групи",
  "team_account.set_specific_period": "Встановити конкретний період",
  "terms.accept": "Прийняти",
  "terms.accept_to_continue": "Щоб продовжити, ви повинні прийняти наші",
  "terms.and": "і",
  "terms.data_policy": "Політика конфіденційності",
  "terms.privacy_policy_validation_message": "Ви повинні прийняти нашу Політику конфіденційності",
  "terms.terms_of_service": "Умови використання",
  "terms.terms_validation_message": "Ви повинні прийняти наші Умови використання",
  "toaster.404": "Ми не змогли знайти те, що ви шукаєте :(",
  "toaster.account_set": "Обліковий запис створено",
  "toaster.activation_email_sent": "Користувачу надіслано електронний лист для активації",
  "toaster.active": "активний",
  "toaster.admin_added": "До групи додано адміністратора",
  "toaster.already_registered": "вже зареєстрований як родитель для",
  "toaster.branch_membership_cancelled": "Спортивне членство для {{ name }} було скасовано, а користувача видалено з усіх пов’язаних груп у Hoopit.",
  "toaster.cannot_add_fix_id": "не можна додати fiksId",
  "toaster.cannot_refund_payment": "Неможливо відшкодувати через брак коштів на рахунку.",
  "toaster.cannot_remove_title": "Ви не маєте дозволу на видалення заголовка в цій групі",
  "toaster.changes_were_saved": "Зміни збережено!",
  "toaster.club_membership_cancelled": "Членство в клубі для {{ name }} скасовано, а користувача вилучено з усіх пов’язаних груп у Hoopit.",
  "toaster.compress_failed": "Не вдалося стиснути зображення",
  "toaster.copied": "Скопійовано!",
  "toaster.emails_dont_match": "«{{email}}» не відповідає нашим записам і може бути застарілим або неправильним",
  "toaster.event_reopened": "“{{event}}” було відновлено",
  "toaster.fetch_invited_failed": "Не вдалося отримати запрошених користувачів",
  "toaster.fix_id_saved": "FiksId збережено",
  "toaster.format_not_supported": "Формат файлу не підтримується",
  "toaster.group_updated": "Інформація про групу оновлена",
  "toaster.has_title": "вже має посаду",
  "toaster.inactive": "неактивний",
  "toaster.invalid_password": "Недійсний пароль",
  "toaster.is_provided": "зараз доданий для",
  "toaster.location_added": "Локацію успішно додано",
  "toaster.location_deleted": "Локацію видалено",
  "toaster.location_updated": "Локацію успішно оновлено",
  "toaster.marked_as_refunded": "Платіж було позначено як відшкодований.",
  "toaster.new_activation_email_sent": "Новий електронний лист для активації надіслано на адресу",
  "toaster.no_access_to_perform_action": "Ви не маєте доступу для виконання цієї дії.",
  "toaster.no_longer_admin": "{{ user }} більше не є адміном у {{ group }}",
  "toaster.no_longer_member": "{{ user }} більше не є членом {{ group }}",
  "toaster.no_longer_player": "{{ user }} більше не є гравцем у {{ group }}",
  "toaster.not_valid_fix_id": "FiksId недійсний",
  "toaster.player_added": "Гравець доданий до групи",
  "toaster.post_deleted": "Пост видалено",
  "toaster.post_made": "Створено нову публікацію",
  "toaster.post_saved": "Пост збережено",
  "toaster.post_updated": "Публікацію успішно оновлено.",
  "toaster.post_updated_notified": "Публікацію успішно оновлено.\n Повідомлення надіслано.",
  "toaster.remind_unanswered_success_bulk": "Нагадування надсилається користувачам {{x}} та їхнім опікунам",
  "toaster.remind_unanswered_success_single": "Нагадування надсилається {{user}} та опікунам",
  "toaster.requested_nif_connection_bulk": "Запит на підключення до NIF було надіслано {{x}} користувачам",
  "toaster.requested_nif_connection_single": "Запит на підключення до NIF було надіслано {{user}}",
  "toaster.requested_refund": "Було ініційовано запит на відшкодування для {{user}}.",
  "toaster.select_admin_title": "Виберіть посаду адміністратора",
  "toaster.sent_nif_confirmation_request": "NIF надіслав запит на підтвердження на адресу «{{email}}»",
  "toaster.something_went_wrong": "Щось пішло не так",
  "toaster.something_wrong": "Щось пішло не так",
  "toaster.something_wrong_compress_failed": "Щось пішло не так, не вдалося стиснути зображення",
  "toaster.unable_to_send_nif_request": "Ми не змогли надіслати запит {{x}} користувачам через відсутність електронної пошти",
  "toaster.user_saved": "Користувач збережено",
  "toaster.venues_retrieve_failure": "Не вдалося знайти локації",
  "toast.title_added_success": "{{ name }} тепер {{ title }} у {{ group }}",
  "toast.title_removed_success": "{{ name }} більше не {{ title }} у {{ group }}",
  "user_activation_status": "Статус користувача",
  "user_activation_status.active": "Активний",
  "user_activation_status.awaits_membership": "Очікує на членство",
  "user_activation_status.child_user": "Дитячий користувач",
  "user_activation_status.no_receives_email_updates": "Не отримує оновлень. Укажіть адресу електронної пошти.",
  "user_activation_status.not_accepted_group_invitation": "Запрошення до групи не прийнято",
  "user_activation_status.not_completed_registration": "Реєстрацію не завершено",
  "user_activation_status.parents_notification": "Батьки отримують оновлення та стежать за діями дитини.",
  "user_activation_status.receives_app_updates": "Отримує оновлення у додатку",
  "user_activation_status.receives_email_updates": "Отримує оновлення електронною поштою",
  "user_activation_status.supervised": "Під наглядом батьків",
  "user_attributes.address": "Адреса",
  "user_attributes.age": "років",
  "user_attributes.birth_date": "Дата народження",
  "user_attributes.birth_day": "День",
  "user_attributes.birth_month": "Місяць",
  "user_attributes.birth_year": "Рік",
  "user_attributes.birth_year_label": "Рік народження",
  "user_attributes.city": "Місцезнаходження",
  "user_attributes.email": "Електронна пошта",
  "user_attributes.first_name": "Ім'я",
  "user_attributes.gender": "Стать",
  "user_attributes.jersey_number": "Номер футболки",
  "user_attributes.last_name": "Прізвище",
  "user_attributes.last_online": "Востаннє онлайн",
  "user_attributes.name": "Ім'я",
  "user_attributes.nationality": "Національність",
  "user_attributes.no_permission": "Немає дозволу",
  "user_attributes.no_permission_label": "Не дозволяє розміщувати фото та відео",
  "user_attributes.number": "Номер",
  "user_attributes.paid_membership_fee": "Сплачений членський внесок",
  "user_attributes.paid_training_fee": "Оплачене тренування",
  "user_attributes.password": "Пароль",
  "user_attributes.permission_internal_label": "Дозволяє розміщувати фотографії та відео всередині Hoopit",
  "user_attributes.permission_internal_text": "Я дозволяю публікувати всередині (всередині Hoopit)",
  "user_attributes.permission_public_label": "Дозволяє публікувати фотографії та відео",
  "user_attributes.permission_public_text": "Я дозволяю публікувати всередині та публічно (за межами Hoopit)",
  "user_attributes.permissions_description": "Зміни вплинуть лише на майбутні публікації, на вже опубліковані публікації вони не вплинуть.",
  "user_attributes.permissions_text": "Будь ласка, надайте дозволи на публікацію фотографій і відео з вами.",
  "user_attributes.permissions_text_2": "Будь ласка, надайте дозволи на публікацію фотографій і відео з {{ name }}.",
  "user_attributes.phone_code": "Код",
  "user_attributes.phone_number": "Номер мобільного",
  "user_attributes.photo_permission": "Дозвіл на фото",
  "user_attributes.postal_code": "Поштовий індекс",
  "user_attributes.receives_notifications_via_email": "Отримує оновлення електронною поштою",
  "user_attributes.social_security": "Ідентифікаційний номер",
  "user_attributes.street_address": "Адреса вулиці",
  "user_form_validation.duplicate_user": "Схоже, ви вже зареєстровані!",
  "user_form_validation.email": "Введіть дійсну адресу електронної пошти",
  "user_form_validation.error": "Помилка!",
  "user_form_validation.name": "Ім'я - це всі імена, крім прізвища",
  "user_form_validation.password": "Пароль повинен містити не менше 6 символів",
  "user_form_validation.phone_number": "Введіть дійсний номер мобільного телефону",
  "user_profile.activate": "Вктивувати",
  "user_profile.activation_message": "Після того як ви активуєте користувача, ми надішлемо йому електронний лист для створення пароля. <br/> Вони входять звідси за номером мобільного.",
  "user_profile.add": "Додати",
  "user_profile.add_existing_guardian": "Додати наявного родителя",
  "user_profile.add_existing_user": "Додати існуючого користувача",
  "user_profile.add_guardian": "Додати родителя",
  "user_profile.add_guardian_label": "Додайте родителя по імені",
  "user_profile.additional_information": "Додаткова інформація",
  "user_profile.address_not_provided": "Адреса не вказана",
  "user_profile.authorization_code_label": "Встановити родителя з кодом авторизації",
  "user_profile.by_email": "електронною поштою",
  "user_profile.children": "Діти",
  "user_profile.click_here": "натисніть тут",
  "user_profile.dob_not_provided": "Дата народження не вказана",
  "user_profile.edit_jersey_number": "Редагувати номер футболки",
  "user_profile.edit_profile": "Редагувати профіль",
  "user_profile.email_not_provided": "Електронна адреса не вказана",
  "user_profile.enter_password_message": "Будь ласка, введіть пароль підтвердження",
  "user_profile.fetching_code": "Отримання коду...",
  "user_profile.gender_not_provided": "Стать не вказано",
  "user_profile.groups": "Групи",
  "user_profile.guardian_in": "Родитель в",
  "user_profile.guardian_of": "як родитель",
  "user_profile.guardians_code_message": "Батьки можуть ввести цей код у налаштування програми. <br/> Тоді вони будуть призначені батьками {{ first_name }}.",
  "user_profile.in": "в",
  "user_profile.info": "Інформація",
  "user_profile.inquire_add_message": "Хочете додати",
  "user_profile.invitation": "Запрошення",
  "user_profile.invitation_sent": "Запрошення надіслано",
  "user_profile.main_information": "Головна інформація",
  "user_profile.manage_roles_description": "Виберіть, які ролі має мати <b>{{ user }}</b> у <b>{{ group }}</b>.",
  "user_profile.memberships": "Членства",
  "user_profile.memberships_admin_description": "Учасник має права адміністратора в цій групі та всіх її підгрупах.",
  "user_profile.memberships_empty_state_description": "Схоже, {{userName}} не є членом жодної з груп, до яких ви маєте доступ.",
  "user_profile.memberships_empty_state_title": "Немає членства",
  "user_profile.memberships_manage_roles": "Редагувати ролі",
  "user_profile.memberships_player_description": "Учасник бере активну участь у діяльності групи та сплачує внески.",
  "user_profile.memberships_roles_article_button": "цій статті",
  "user_profile.memberships_roles_counter": "+{{ x }}",
  "user_profile.memberships_roles_description": "Ролі дозволяють адміністраторам ефективніше керувати групами. Наприклад “Økonomiansvarlig” дозволяє користувачеві отримати доступ до платіжок групи. Ви можете дізнатися більше про ролі Hoopit в",
  "user_profile.mobile_number_not_provided": "Номер мобільного телефону не вказано",
  "user_profile.no_guardians": "Без батьків",
  "user_profile.no_guardians_description": "Схоже, ще ніхто не додався до {{ name }} у якості батьків",
  "user_profile.no_results": "Немає результатів для",
  "user_profile.no_results_found": "Нічого не знайдено",
  "user_profile.no_user_by_email": "Нам не вдалося знайти користувачів із цією електронною адресою",
  "user_profile.no_user_by_name": "Нам не вдалося знайти користувачів із таким іменем",
  "user_profile.number_in_use_error": "Користувач із цим номером телефону вже існує. Будь ласка, спробуйте інший номер або видаліть цього користувача та запросіть заново у меню Групи > Користувачі.",
  "user_profile.or": "або",
  "user_profile.payments_tab_description": "Ось список усіх неоплачених платежів цього користувача. Щоб побачити всі платежі",
  "user_profile.payments_tab_description_1": "Ось список усіх неоплачених платежів цього користувача.",
  "user_profile.payments_tab_description_2": "Щоб побачити всі платежі",
  "user_profile.permissions": "Permissions",
  "user_profile.phone_number_usage": "Використання телефонного номеру",
  "user_profile.phone_number_usage_description": "Зауважте, що для того, щоб {{ name }} успішно завершив реєстрацію та прийняв запрошення, користувач має використовувати вказаний номер телефону.",
  "user_profile.practicing_in": "Практика в",
  "user_profile.profile": "Профіль",
  "user_profile.relations": "Родичі",
  "user_profile.remind_via_email": "Нагадати електронним листом",
  "user_profile.remove": "Видалити",
  "user_profile.remove_as_admin": "Видалити як адміна",
  "user_profile.remove_as_player": "Видалити як гравця",
  "user_profile.remove_from_group_description": "Ви хочете видалити <b>{{ user }}</b> з <b>{{ group }}</b>?",
  "user_profile.remove_from_group_title": "Видалити з групи?",
  "user_profile.remove_from_group.website_access_lost_description": "Це ваша єдина роль адміністратора в {{ organization }}. Її видалення призведе до втрати доступу до цього веб-сайту.",
  "user_profile.remove_from_group.website_access_lost_title": "Ви втратите доступ до цього веб-сайту",
  "user_profile.remove_title": "Видалити назву",
  "user_profile.remove_title_description": "Ви впевнені, що хочете видалити назву {{ title }} у {{ groupName }} для {{ userName }}?",
  "user_profile.resend_invitation_button": "Повторно запросити",
  "user_profile.resend_invitation_by_sms_description": "{{ name }} отримає запрошення через SMS на <b>{{ number }}</b>, надіслане {{ sender }}. Якщо цей неправильний, будь ласка видаліть цього користувача в «Групи» > «Користувачі» та запросіть заново за коректним номером. ",
  "user_profile.resend_invitation_by_sms_title": "Запрошення через SMS ",
  "user_profile.resend_invitation_description": "Надішліть запрошення для {{ name }}, нагадавши, що для того щоб приєднатися до групи та стати активним учасником {{ organization }}, необхідно зареєструватись.",
  "user_profile.resend_invitation_description_2": "Якщо ви надасте електронну адресу {{ name }}, ми також надішлемо запрошення туди для легкого доступу.",
  "user_profile.resend_invitation_email": "Повторно надіслати електронний лист із запрошенням",
  "user_profile.resend_invitation_email_description": "Ви можете повторно надіслати електронний лист із запрошенням користувачеві, який ще не зареєстрований у Hoopit.",
  "user_profile.resend_invitation_reminder": "Останнє запрошення було надіслано {{ datetime }}.",
  "user_profile.roles": "Ролі",
  "user_profile.search_by_code_or_country": "Пошук за кодом або країною",
  "user_profile.search_by_email": "Пошук по електронній пошті",
  "user_profile.search_by_name": "Пошук за назвою",
  "user_profile.send_new_activation_email": "Надіслати новий електронний лист для активації",
  "user_profile.title": "Посада",
  "user_profile.titles": "Посади",
  "user_profile.try_another_email": "Спробуйте іншу електронну адресу або здійсніть пошук за назвою",
  "user_profile.try_another_name": "Спробуйте інше ім’я або здійсніть пошук електронною поштою",
  "user_profile.unpaid_payments": "Unpaid payments",
  "user_search_form.all_names": "Всі імена, крім прізвища",
  "user_search_form.back": "Назад",
  "user_search_form.born": "Народився",
  "user_search_form.choose_title": "Виберіть посаду",
  "user_search_form.next": "Далі",
  "user_search_form.phone_number_validation_message": "Введіть дійсний номер телефону",
  "user_types.active_members": "Активні учасники",
  "user_types.admin": "Адміністратор",
  "user_types.admins": "Адміністратори",
  "user_types.associate_member": "Асоційований учасник",
  "user_types.associate_members": "Асоційовані учасники",
  "user_types.external_user": "Зовнішній користувач",
  "user_types.guardian": "Родитель",
  "user_types.guardians": "Батьки",
  "user_types.member": "Учасник",
  "user_types.members": "Учасники",
  "user_types.other": "Інший",
  "user_types.others": "Інші",
  "user_types.player": "Гравець",
  "user_types.player_and_admin": "Гравець та Адмін",
  "user_types.players": "Гравці",
  "waiting_list.1_user_in": "1 користувач у",
  "waiting_list.add_single_description": "<b>{{ name }}</b> буде додано до <b>{{ group }}</b> як гравця. Додавання цього користувача перевищить максимальний ліміт гравців ({{ limit }} гравців).",
  "waiting_list.add_single_success": "{{ name }} додається у {{ group }}, на це може знадобитися деякий час.",
  "waiting_list.add_single_title": "Додати до групи?",
  "waiting_list.add_to_group": "Додати до групи",
  "waiting_list.add_warning_title": "Ліміт гравців буде перевищено",
  "waiting_list.add_x_description": "{{ count }} користувачів буде додано до <b>{{ group }}</b> як гравців. Додавання цих користувачів перевищить максимальний ліміт гравців ({{ limit }} гравців).",
  "waiting_list.add_x_success": "{{ count }} користувачів додаються у {{ group }}, на це може знадобитися деякий час.",
  "waiting_list.add_x_title": "Додати {{ count }} користувачів до групи?",
  "waiting_list.add_x_warning_description": "Активація цієї опції вимкне «Запити на членство» та перемістить {{ count }} користувачів, запити яких очікують на схвалення, до цього листу очікування.",
  "waiting_list.create": "Створити список очікування",
  "waiting_list.create_description": "Щоб активувати лист очікування для <b>{{ group }}</b>, встановіть ліміт гравців і ввімкніть опцію листа очікування.",
  "waiting_list.create_warning_description": "Користувачі, запити яких очікують на схвалення, буде переміщено сюди",
  "waiting_list.delete_description": "{{ count }} користувачів буде видалено з черги очікування групи <b>{{ group }}</b>. Вони все одно будуть зареєстровані в системі та зможуть знову приєднатися до списку очікування.",
  "waiting_list.delete_from_waiting_list": "Видалити зі списку очікування",
  "waiting_list.delete_single_description": "<b>{{ name }}</b> буде видалено зі списку очікування <b>{{ group }}</b>. Користувач все одно залишиться зареєстрованим у системі та зможе знову приєднатися до списку очікування.",
  "waiting_list.delete_single_success": "{{ userName }} видаляєтсья зі списку очікування, це може зайняти деякий час.",
  "waiting_list.delete_single_title": "Видалити зі списку очікування?",
  "waiting_list.delete_x_success": "{{count}} користувачів видаляються зі списку очікування, це може зайняти деякий час.",
  "waiting_list.delete_x_title": "Видалити {{count}} користувачів зі списку очікування?",
  "waiting_list.disabled": "Без списку очікування",
  "waiting_list.enable_description": "Після досягнення ліміту гравців усі користувачі, які приєднаються до цієї групи, потраплять у список очікування",
  "waiting_list.hint": "Активуйте лист очікування, якщо хочете, щоб нові гравці ставилися в чергу та були автоматично додані до групи в тому ж порядку, у якому вони приєднувались.",
  "waiting_list.limit_members_description": "Це не вплине на поточних учасників",
  "waiting_list.n_users_in": "{{ number }} користувачів у",
  "waiting_list.send_mandatory_payment_title": "Надіслати обов'язковий платіж",
  "welcome_message.get_started": "Почніть із завантаження нашого додатку з App Store або Google Play.",
  "welcome_message.go_to_dashboard": "Перейдіть до інформаційної панелі",
  "welcome_message.hoopit": "Ласкаво просимо до Hoopit!",
  "welcome_message.hoopit_main_adress": "www.hoopit.io",
  "welcome_message.instruction_for_mobile": "Ви запрошені як лідер і можете керувати як з мобільного додатка, так і з Інтернету. \\n Веб найкраще підходить на ПК, ви можете увійти з цієї адреси",
  "welcome_message.leader": "Якщо ви лідер, ви можете керувати своєю командою на веб-панелі.",
  "welcome_message.stay_updated_and_logg_in": "Будьте в курсі свого клубу та команди. Завантажте програму та увійдіть, вказавши номер телефону та пароль.",
  "welcome_message.use_webapp": "Ви також можете використовувати веб-додаток, якщо не хочете користуватися мобільним додатком.",
  "welcome_message.you_are_in_waiting_line": "Зараз ви в черзі, щоб приєднатися до {{groupName}}. Ми повідомлятимемо вас про будь-які оновлення.",
  "miscellaneous.cannot_be_in_the_past": "Не може бути у минулому",
  "miscellaneous.hours": "Годин",
  "miscellaneous.hour": "Година",
  "miscellaneous.days": "Днів",
  "miscellaneous.day": "День",
  "miscellaneous.weeks": "Тижнів",
  "miscellaneous.week": "Тиждень"
}